import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { DataGridPDFRow } from './dataGridPDFrow'
import { Style } from '@react-pdf/types'

export interface IDataGridPDFProps<T> {
    rows?: T[]
    columns?: IDataGridPDFColumn<T>[]
    style?: Style
    verticalBorders?: boolean
    showHeadings?: boolean
}

export interface IDataGridPDFColumn<T> {
    name?: keyof T;
    title?: string;
    type?: 'string' | 'number' | 'enum' | 'boolean'
    values?: { id: number | string, value: string }[];
    width?: string | number
    align?: "left" | "right" | "center" | "justify" | undefined
    renderCell?: (props: ICellPDF<T>) => React.ReactNode | string | number;
}

export interface ICellPDF<T> {
    row: T;
    column: IDataGridPDFColumn<T>;
    value: any;
}

export interface IDataGridPDFState {
    width?: number
    height?: number
}

export class DataGridPDF<T> extends React.Component<IDataGridPDFProps<T>> {
    state: IDataGridPDFState = {
        width: undefined,
        height: undefined
    }

    componentDidMount = async () => {
    }


    render() {

        if (!this.props.columns) return null;

        return <View style={this.props.style}>


            <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                borderBottom: '1px solid black',
            }}>
                {/* // HEADINGS */}
                {this.props.showHeadings !== false && this.props.columns?.map(c => <Text style={{
                    fontSize: 8,
                    fontFamily: "RobotoBold",
                    width: c.width,
                    textAlign: c.align,
                    padding: 3,
                }} wrap={true}>
                    {c.title}
                </Text>)}
            </View>
            {/* // ROWS */}
            {this.props.rows?.map((row, key) => {
                if (!this.props.columns) return null;
                return <DataGridPDFRow
                    verticalBorders={this.props.verticalBorders}
                    key={key}
                    columns={this.props.columns}
                    row={row} />
            })}

        </View>
    }
}