import React from 'react'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import { BatchIngredientsList, BatchStateIcon, CertificateOfAnalysisDisplay } from '..'
import { boumaticApi, boumaticApiSignalR } from '../../App'
import { Await, DataGrid, TextArea } from 'components'
import {
    Batch,
    BatchProcessingStatus,
    BatchState,
    BatchStateStepNumber,
    CloudServiceActionResult,
    Container,
    Product,
    ProductQuantity
} from '../../api/interfaces'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import LinearProgress from '@mui/material/LinearProgress'
import Alert from '@mui/material/Alert'
import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Toolbar
} from '@mui/material'
import { Link } from 'react-router-dom'
import { Assignment, CheckTwoTone, CompareArrows, RedoTwoTone } from '@mui/icons-material'
import { clone } from 'lodash'

interface BatchViewSingleProps {
    batchId?: string
    tankId?: string
}

interface BatchViewSingleState {
    requeueStarted: boolean;
    batch?: Await<ReturnType<typeof boumaticApi.api.Batches.GetBatch>>
    tank?: Await<ReturnType<typeof boumaticApi.api.Batches.GetBatchByMixingTankId>>
    formulaR?: Await<ReturnType<typeof boumaticApi.api.Formulas.GetFormula>>
    showRequeueModal: boolean
    showSetQCModal: boolean
    showCannotEditSnackbar: boolean
    revokeBatchResult?: Await<ReturnType<typeof boumaticApi.api.Batches.RevokeBatch>>
    setQCresult?: CloudServiceActionResult<Batch>

    /** used to display success/error messages when updating batch state */
    upsertBatchResult?: Await<ReturnType<typeof boumaticApi.api.Batches.UpsertBatch>>

    productQuantities?: Await<ReturnType<typeof boumaticApi.api.ProductQuantity.GetProductQuantityByBatchId>>
    containers?: Await<ReturnType<typeof boumaticApi.api.Containers.GetContainers>>
    products?: Await<ReturnType<typeof boumaticApi.api.Products.GetProducts>>
    batchButtonBusy?: boolean
}

/** combine product quantity, product and container information for one table */
interface QuantityRows extends ProductQuantity, Product, Container { }

export class BatchViewSingle extends React.Component<BatchViewSingleProps, BatchViewSingleState> {
    state: BatchViewSingleState = {
        showRequeueModal: false,
        showSetQCModal: false,
        showCannotEditSnackbar: false,
        requeueStarted: false,
        batchButtonBusy: false
    };

    lastBatchId?: string;

    componentDidMount = () => {
        this.getData();

        boumaticApiSignalR.events.on("batchState", this.handleBatchStateUpdates);
        boumaticApiSignalR.events.on("onBatchStepUpdate", this.handleBatchStateUpdates);
    }

    handleBatchStateUpdates = async (status: BatchProcessingStatus) => {
        console.log('BATCH VIEW SIGNAL R STATE UPDATE', status);

        if (this.state.batch?.data?.id === status.batchId) {
            if (!this.props.batchId) return;
            // quick update
            console.log('BATCH VIEW SIGNAL R STATE UPDATING');
            const batch = await boumaticApi.api.Batches.GetBatch({ id: this.props.batchId });
            this.setState({ batch });
        }
    }

    componentDidUpdate = () => {

        if (this.props.batchId !== this.lastBatchId) {
            this.getData();
        }
    }

    componentWillUnmount = () => {
        boumaticApiSignalR.events.removeListener("batchState", this.handleBatchStateUpdates);
    }

    getData = async () => {

        if (!this.state.containers) {
            let containers = await boumaticApi.api.Containers.GetContainers();
            this.setState({ containers });
        }

        if (!this.state.products) {
            let products = await boumaticApi.api.Products.GetProducts();
            this.setState({ products });
        }

        console.log('batchViewSingle.tsx getData')
        if (!this.props.batchId) return;
        if (this.props.batchId !== this.lastBatchId) {
            this.lastBatchId = this.props.batchId;
            this.setState({ batch: undefined, upsertBatchResult: undefined, productQuantities: undefined });
            const batch = await boumaticApi.api.Batches.GetBatch({ id: this.props.batchId });
            this.setState({ batch });

            if (batch?.data) {
                let openSnackbar = parseInt(BatchStateStepNumber[batch.data?.batchState]) >= BatchState.Mixing && parseInt(BatchStateStepNumber[batch.data?.batchState]) <= BatchState.Adjusting
                this.setState({ showCannotEditSnackbar: openSnackbar });

                const formulaR = await boumaticApi.api.Formulas.GetFormula({ id: batch.data.formulaId as string })
                this.setState({ formulaR });
            }

            // product quantities
            let productQuantities = await boumaticApi.api.ProductQuantity.GetProductQuantityByBatchId({ batchId: this.props.batchId });
            this.setState({ productQuantities });
        }
        if (this.props.tankId) {
            const tank = await boumaticApi.api.Batches.GetBatchByMixingTankId({ mixingTankId: this.props.tankId });
            this.setState({ tank });
        }
    }

    getProduct = (id: string): Product => {
        let p = this.state.products?.data?.filter(c => c.id === id);

        if (!p || p.length < 1) return {
            active: false,
            queuePlace: 0,
            skuNumber: 0,
        }
        let product = p[0];
        return product;
    }

    getContainer = (id: string): Container => {
        // get this container data
        let a = this.state.containers?.data?.filter(c => c.id === id);

        if (!a || a.length < 1) return {
            active: false,
            capacityVolume: 0,
            id,
            name: `error not found ${id}`,
            skuNumber: 0
        };

        let container = a[0];
        return container
    }

    checkIfCanMix(batch: Batch): boolean {
        if (!this.state.tank?.data) return false;
        return true; // always proceed;


        // switch (parseInt(BatchStateStepNumber[batch.batchState])) {
        //     case BatchStateStepNumber.Started: return true
        //     case BatchStateStepNumber.Mixing: return false
        //     case BatchStateStepNumber.Mixing2: return false
        //     case BatchStateStepNumber.Completed: return false
        // }

        //  //A tank can have only one Batch in Mixing, Adjusting or Evaluated state
        // if (this.state.batch?.data && this.state.tank?.data) {
        //     if (!this.state.tank?.data) return false; 
        //     const activeBatches = this.state.tank.data.filter((b) =>
        //         parseInt(BatchStateStepNumber[b.batchState]) === BatchState.Mixing
        //         || parseInt(BatchStateStepNumber[b.batchState]) === BatchState.Adjusting
        //         || parseInt(BatchStateStepNumber[b.batchState]) === BatchState.Evaluated
        //         || parseInt(BatchStateStepNumber[b.batchState]) === BatchState.Completed);

        //     console.log(activeBatches);
        //     return activeBatches.length === 0;
        // }

        // switch (parseInt(BatchStateStepNumber[batch.batchState])) {
        //     case BatchStateStepNumber.Started: return true
        //     case BatchStateStepNumber.Queued: return true
        //     case BatchStateStepNumber.Mixing: return false
        //     case BatchStateStepNumber.Mixing2: return false
        //     case BatchStateStepNumber.AwaitingQc: return true
        //     case BatchStateStepNumber.Evaluated: return true
        //     case BatchStateStepNumber.Adjusting: return true
        //     case BatchStateStepNumber.Completed: return false
        // }

        // return false;
    }

    // ------------------------------------------------------------------------------------------------
    // SET QC

    renderSetQCModal(batch: Batch) {
        return <>
            <Dialog
                open={this.state.showSetQCModal}
                onClose={() => { this.setState({ showSetQCModal: false }); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    In Mixing/Adjusting
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to manually set this to Awaiting QC? Press OK to proceed, CANCEL to cancel.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button color="inherit" onClick={async () => {
                        this.setState({ showSetQCModal: false }); // hide modal on OK

                        let batch = this.state.batch?.data;
                        if (!batch || !batch.id) { throw new Error('missing batch id'); }

                        let setQCresult = await this.updateBatchStatus(batch, BatchState.AwaitingQc);
                        this.setState({ setQCresult }); // this will then show the snackbar
                    }} autoFocus>
                        OK
                    </Button>
                    <Button color="inherit" onClick={() => { this.setState({ showSetQCModal: false }); }}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/* REQUEUE REVOKE BATCH CONFIRMATION */}
            <Snackbar
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!this.state.setQCresult}
                onClose={() => { this.setState({ setQCresult: undefined }) }}
            >
                <Alert severity="info">{this.state.setQCresult?.message}</Alert>
            </Snackbar>
        </>
    }

    updateBatchStatus = async (batch: Batch, batchState: BatchState) => {
        let updatedBatch = clone(batch);

        // @ts-ignore
        delete updatedBatch._datagridrowId;
        delete updatedBatch.cacheInsertedDate;
        delete updatedBatch.cacheLastUpdatedDate;
        delete updatedBatch.cacheItemState;
        updatedBatch.batchState = batchState;

        // let test: Batch = {
        //     "timeStamp": "2022-03-29T06:36:52.220Z",
        //     "lotNumber": "string",
        //     "mixingTankId": "string",
        //     "formulaId": "string",
        //     "formulaMultiplier": 0,
        //     "weight": 0,
        //     "batchVolume": 0,
        //     "batchState": 1,
        //     "queuePlace": 0,
        //     "nextPlcStep": 0,
        //     "fillInstructs": "string",
        //     "comments": "string"
        //   }

        this.setState({ batchButtonBusy: true })
        let upsertBatchResult = await boumaticApi.api.Batches.UpsertBatch(updatedBatch);
        this.setState({ upsertBatchResult, batchButtonBusy: false, batch: upsertBatchResult });


        return upsertBatchResult

        // return boumaticApi.api.Batches.UpsertBatch({
        //     id: batch.id,
        //     weight: batch.weight,
        //     formulaId: batch.formulaId,
        //     // productPartNumber: batch.formulaId,
        //     lotNumber: batch.lotNumber,
        //     batchVolume: batch.batchVolume,
        //     batchState: status,
        //     // cacheDeletedDateUtc: null,
        //     isEditing: false,
        //     queuePlace: batch.queuePlace,
        //     nextPlcStep: batch.nextPlcStep,
        //     formulaName: batch.formulaName,
        //     mixingTankId: batch.mixingTankId,
        //     fillInstructs: batch.fillInstructs,
        //     //   mixingTankName: batch.mixingTankName,
        //     formulaMultiplier: batch.formulaMultiplier,
        //     comments: batch.comments,
        //     timeStamp: batch.timeStamp,
        //     batchStateId: 0,
        //     // mixingTankNo: 0
        // })
    }

    // ------------------------------------------------------------------------------------------------
    // REQUEUE

    // this is enabled only if the Batch is currently in the Mixing state
    // Current logic is to let the Batch be re-queued as long it is still in Step 1
    // Step 1 is usually addition of water so can be reused for any other Batch also
    // const tank = this.tanks.find(
    //   (t) => t.id === this.selectedBatch.mixingTankId
    // );

    renderRequeueModal() {
        return <>
            <Dialog
                open={this.state.showRequeueModal}
                onClose={() => { this.setState({ showRequeueModal: false }); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Already Mixing
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to send a Re-queue command to this Batch that is already Mixing? Press OK to proceed, CANCEL to cancel.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button color="inherit" onClick={async () => {
                        this.setState({ showRequeueModal: false }); // hide modal on OK

                        let batch = this.state.batch?.data;
                        if (!batch || !batch.id) { throw new Error('missing batch id'); }

                        let revokeBatchResult = await boumaticApi.api.Batches.RevokeBatch({ id: batch.id });
                        this.setState({ revokeBatchResult });
                        this.setState({ requeueStarted: true })// this will then show the snackbar
                    }} autoFocus>
                        OK
                    </Button>
                    <Button color="inherit" onClick={() => { this.setState({ showRequeueModal: false }); }}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/* REQUEUE REVOKE BATCH CONFIRMATION */}
            <Snackbar
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!this.state.revokeBatchResult}
                onClose={() => { this.setState({ revokeBatchResult: undefined }) }}
            >
                <Alert severity="info">{this.state.revokeBatchResult?.message}</Alert>
            </Snackbar>

            {/* <Snackbar
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.showCannotEditSnackbar}
                onClose={() => { this.setState({ revokeBatchResult: undefined }) }}
            >
                <Alert severity="warning">A Batch that is in Mixing, Adjusting, Awaiting QC or Evaluated state cannot be modified.</Alert>
            </Snackbar> */}

            <Snackbar
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.requeueStarted}
                onClose={() => { this.setState({ revokeBatchResult: undefined }) }}
            >
                <Alert severity="info">Request to re-queue Batch "{this.state.batch?.data?.lotNumber}" has been sent!</Alert>
            </Snackbar>
        </>
    }

    renderBatchUpsertResult = () => {
        if (!this.state.upsertBatchResult) return;
        return <Alert severity={this.state.upsertBatchResult.isSuccessful ? 'success' : 'error'}>
            {this.state.upsertBatchResult.message} {this.state.upsertBatchResult.exceptionMessage}
        </Alert>
    }

    // ------------------------------------------------------------------------------------------------

    render() {

        let rows: Batch[] | undefined = undefined;
        let batch: Batch | undefined = undefined;

        if (!!this.state.batch?.data) batch = this.state.batch.data;
        if (!!this.state.batch?.data) rows = [this.state.batch.data];

        let steps = Object.keys(BatchStateStepNumber).filter(i => isNaN(Number(i)));

        if (!batch || !this.state.formulaR?.data) {
            return <Paper sx={{ ml: 2 }} elevation={10}>
                <Paper elevation={1} sx={{ p: 0.1 }}>
                    <Paper sx={{ m: 2, p: 2 }} elevation={0}>
                        <LinearProgress />
                    </Paper>
                </Paper>
            </Paper>
        }

        if (this.state.batch?.exceptionMessage) {
            return <Paper sx={{ ml: 2 }} elevation={10}>
                <Paper elevation={1} sx={{ p: 0.1 }}>
                    <Paper sx={{ m: 2, p: 0 }} elevation={0}>
                        <Alert severity="info">{this.state.batch.exceptionMessage}</Alert>
                    </Paper>
                </Paper>
            </Paper>
        }

        if (this.state.formulaR?.exceptionMessage) {
            return <Paper sx={{ ml: 2 }} elevation={10}>
                <Paper elevation={1} sx={{ p: 0.1 }}>
                    <Paper sx={{ m: 2, p: 0 }} elevation={0}>
                        <Alert severity="info">{this.state.formulaR.exceptionMessage}</Alert>
                    </Paper>
                </Paper>
            </Paper>
        }

        let formula = this.state.formulaR.data;

        // begin prepare quantityRows for grid below
        let quantityRows: QuantityRows[] = [];
        if (this.state.products?.data && this.state.containers?.data && this.state.productQuantities?.data) {
            this.state.productQuantities.data.forEach(pq => {

                if (!pq.productId) return;

                let product = this.getProduct(pq.productId);
                if (!product || !product.containerId) return 'error product not found';
                let container = this.getContainer(product.containerId);

                let newquantityRow: QuantityRows = {
                    active: product.active,
                    capacityVolume: container.capacityVolume,
                    name: container.name,
                    quantity: pq.quantity,
                    queuePlace: product.queuePlace,
                    skuNumber: product.skuNumber,
                    batchId: pq.batchId,
                    containerId: product.containerId,
                    formulaId: product.formulaId,
                    id: pq.id,
                    productId: pq.productId
                }

                if (pq.quantity === 0) return; // only show if more than 0
                quantityRows.push(newquantityRow);
            })
        }
        /// end prepare quantityRows for grid below

        let activeStep = -1; // default
        if (batch.batchState !== undefined) {
            activeStep = parseInt(BatchStateStepNumber[batch.batchState]) - 1;
            // if completed then we advance past so it shows completed as done and not in progress
            if (parseInt(BatchStateStepNumber[batch.batchState]) === BatchStateStepNumber.Completed) activeStep += 1;
        }

        return <Paper sx={{ ml: 2 }} elevation={10}>
            <Paper elevation={1} sx={{ pt: 0.1 }}>

                {this.renderRequeueModal()}
                {this.renderSetQCModal(batch)}

                <AppBar position="static" color="primary" enableColorOnDark>
                    <Toolbar variant="dense" color={"primary"}>
                        <Typography variant="h5" component="div" sx={{ flex: 0, whiteSpace: 'nowrap' }}>
                            TANK {batch.mixingTankId}
                        </Typography>

                        <Typography variant="h5" component="div" sx={{ flex: 0, whiteSpace: 'nowrap', fontWeight: 'bold', ml: 1, mr: 1 }}>
                            {formula.name}
                        </Typography>

                        <Typography id="batchstate" className={`batchstate_${`${batch.batchState}`.toLowerCase()}`} variant="h5" component="div" sx={{ flex: 0, whiteSpace: 'nowrap', fontWeight: 200, textTransform: 'uppercase' }}>
                            {batch.batchState}
                        </Typography>

                        <Box sx={{ flex: 1 }} />

                        {/* <Button
                            disabled={!(parseInt(BatchStateStepNumber[batch.batchState]) === BatchStateStepNumber.Mixing)}
                            size="small" variant="contained" color="secondary"
                            startIcon={<RedoTwoTone />}
                            onClick={() => {
                                this.setState({ showEvaluate: true }); // shows the SetQC modal
                            }}
                            sx={{ ml: 2 }}>Evaluate batch</Button> */}

                        <Button
                            disabled={!((parseInt(BatchStateStepNumber[batch.batchState]) === BatchStateStepNumber.Mixing)
                                || (parseInt(BatchStateStepNumber[batch.batchState]) === BatchStateStepNumber.Adjusting))}
                            size="small" variant="contained" color="secondary"
                            startIcon={<RedoTwoTone />}
                            onClick={() => {
                                this.setState({ showSetQCModal: true }); // shows the SetQC modal
                            }}
                            sx={{ ml: 2 }}>Set QC</Button>

                        <Button
                            disabled={parseInt(BatchStateStepNumber[batch.batchState]) !== BatchStateStepNumber.Mixing}
                            size="small" variant="contained" color="secondary"
                            startIcon={<RedoTwoTone />}
                            onClick={() => {
                                this.setState({ showRequeueModal: true }); // shows the Requeue modal
                            }}
                            sx={{ ml: 2 }}>Re-Queue</Button>


                        {(parseInt(BatchStateStepNumber[batch.batchState]) !== BatchStateStepNumber.Completed) && <Button
                            id="batch_state_next"
                            disabled={this.state.batchButtonBusy || !this.checkIfCanMix(batch)}
                            size="small" variant="contained" color="secondary"
                            startIcon={this.state.batchButtonBusy
                                ? <CircularProgress color="inherit" size="13px" />
                                : <CompareArrows />}
                            onClick={async () => {
                                let batch = this.state.batch?.data;
                                if (!batch || !batch.id) { throw new Error('missing batch id'); }

                                // let setQCresult = 
                                await this.updateBatchStatus(batch, this.batchStateNext(batch));

                                // this.setState({ showRequeueModal: true });
                                // shows the Requeue modal
                            }}
                            sx={{ ml: 2 }}>
                            {this.batchStateButtonText(batch)}
                        </Button>}

                    </Toolbar>
                </AppBar>

                {this.renderBatchUpsertResult()}

                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', pt: 2, pb: 2 }}>
                    <Box sx={{ flex: 1 }} />
                    <Button
                        size="small" variant="contained" color="primary"
                        component={Link}
                        startIcon={<Assignment />}
                        to={`/batch-instructions/${batch.id}`}
                        sx={{ mr: 2 }}>Batch Instructions</Button>
                    <Button
                        size="small" variant="contained" color="primary"
                        component={Link}
                        startIcon={<Assignment />}
                        to={`/batch-ticket-report/${batch.id}`}
                        sx={{ mr: 2 }}>Batch Ticket</Button>
                    <Button
                        size="small" variant="contained" color="primary"
                        component={Link}
                        startIcon={<Assignment />}
                        to={`/filling-ticket-report/${batch.id}`}
                        sx={{ mr: 2 }}>Fill Ticket</Button>
                </Paper>

                <Paper sx={{ p: 2, m: 2 }} elevation={0}>
                    {(batch.batchState) ? <Stepper activeStep={activeStep}
                        alternativeLabel >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={(props) => <BatchStateIcon {...props} />}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper> : <LinearProgress />}
                </Paper>

                <DataGrid<Batch>
                    columns={[{ name: 'mixingTankId', title: 'TANK' },
                    { title: "STATE", name: 'batchState' }, {
                        title: "VOLUME", name: "batchVolume"
                    }, {
                        title: "LOT", name: "lotNumber"

                    }, {
                        title: "NEXT PLC STEP", name: "nextPlcStep"
                    }, {
                        title: "QUEUE PLACE", name: "queuePlace"
                    }, {
                        title: "TIMESTAMP", name: "timeStamp"
                    }]}
                    rows={rows}
                />



                {/* ONLY SHOW FROM AWAITINGQC STEP */}
                {parseInt(BatchStateStepNumber[batch.batchState]) >= BatchStateStepNumber.AwaitingQc &&
                    <CertificateOfAnalysisDisplay
                        batch={batch}
                        formulaId={batch?.formulaId}
                        lotNumber={batch?.lotNumber}
                        isEditable={parseInt(BatchStateStepNumber[batch.batchState]) === BatchStateStepNumber.AwaitingQc}
                    />
                }

                {this.state.batch?.data && <BatchIngredientsList key={this.props.batchId}
                    batch={this.state.batch.data}
                    batchId={this.props.batchId} />}

                {/* PRODUCT QUANTITIES */}
                {!this.state.products && <LinearProgress />}
                {!this.state.containers && <LinearProgress />}
                {this.state.products && this.state.containers &&
                    <Paper elevation={0} sx={{ mt: 2 }}>
                        <DataGrid<QuantityRows>
                            title="Product Quantities"
                            columns={[
                                { name: 'name', title: 'CONTAINER', },
                                { name: 'skuNumber', title: 'SKU', },
                                { name: 'capacityVolume', title: 'UNIT VOLUME' },
                                { name: 'quantity', title: 'QTY' }]}
                            rows={quantityRows}
                        /></Paper>}

                <Paper elevation={0} sx={{ mt: 2 }}>
                    <TextArea
                        editMode={false}
                        title="Comments"
                        value={this.state.batch?.data?.comments || ""}
                    />
                </Paper>

            </Paper>
        </Paper>
    }

    // depending on what state the batch is in the next step batchState is:
    batchStateNext(batch: Batch): BatchState {
        switch (parseInt(BatchStateStepNumber[batch.batchState])) {
            case BatchStateStepNumber.Started: return BatchState.Queued;
            case BatchStateStepNumber.Queued: return BatchState.Mixing;
            case BatchStateStepNumber.Mixing: return BatchState.AwaitingQc;
            // case BatchStateStepNumber.Mixing2: return BatchState.AwaitingQc;
            case BatchStateStepNumber.AwaitingQc: return BatchState.Evaluated;
            case BatchStateStepNumber.Evaluated: return BatchState.Adjusting;
            case BatchStateStepNumber.Adjusting: return BatchState.Completed;
            case BatchStateStepNumber.Completed: return BatchState.Completed;
        }
        return BatchState.Completed;
    }

    // depending on what state the batch is in the next step text is different
    batchStateButtonText(batch: Batch): string {
        switch (parseInt(BatchStateStepNumber[batch.batchState])) {
            case BatchStateStepNumber.Started: return 'Queue Batch'
            case BatchStateStepNumber.Queued: return 'Mix Batch'
            case BatchStateStepNumber.Mixing: return 'Mixing'
            // case BatchStateStepNumber.Mixing2: return 'Mixing2'
            case BatchStateStepNumber.AwaitingQc: return 'Complete QC'
            case BatchStateStepNumber.Evaluated: return 'Complete Evaluation'
            case BatchStateStepNumber.Adjusting: return 'Complete Adjustment'
            case BatchStateStepNumber.Completed: return 'Batch Completed'
        }
        return 'UNKNOWN'
    }

}