import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IMainProps, IMainState } from "./interfaces";
import { Link, Outlet } from "react-router-dom";

import {
  createTheme, ThemeOptions,
  ThemeProvider,
} from "@mui/material";

import { isJson } from "../utils";
import Button from "@mui/material/Button";
import { AccountCircleTwoTone as ProfileIcon } from "@mui/icons-material";
import { OAuth, TokenParse } from "../utils";

const drawerWidth = 220;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const themeMUILight = createTheme({
  palette: {
    mode: "light",
    // primary: {
    //     main: colors.protoGreen,
    // },
    // secondary: {
    //     main: colors.protoGray,
    // },
  },
});

const themeMUIDark = createTheme({
  palette: {
    mode: "dark",
    // primary: {
    //     main: colors.protoGreen,
    // },
    // secondary: {
    //     main: colors.protoGray,
    // },
  },
});





export class Main extends React.Component<IMainProps, IMainState> {
  state: IMainState = {
    height: window.innerHeight,
    darkMode: true
  };

  localStateKey = "mainStateStorage";

  componentDidMount = async () => {

    const loadMainState = await localStorage.getItem(this.localStateKey);
    if (loadMainState && isJson(loadMainState)) {
      let lastState = JSON.parse(loadMainState) as IMainState;
      const newState: Partial<IMainState> = {};
      if (lastState.open !== undefined) newState.open = lastState.open;
      if (lastState.darkMode !== undefined)
        newState.darkMode = lastState.darkMode;

      this.setState({ darkMode: newState.darkMode });
    } else {
      this.setState({ open: true }); //default
    }

    window.addEventListener("resize", this.handleResizeChanges);
  };

  componentWillUnmount = async () => {
    window.removeEventListener("resize", this.handleResizeChanges);
  };

  toggle = async () => {
    const newState = { open: !this.state.open };
    this.setState(newState);
    await localStorage.setItem(this.localStateKey, JSON.stringify(newState));
  };

  handleDarkModeChanges = (e: MediaQueryListEvent) => {
    this.setState({ darkMode: e.matches });
  };

  handleResizeChanges = () => {
    this.setState({ height: window.innerHeight });
  };


  render() {
    let theme = this.state.darkMode ? themeMUIDark : themeMUILight;

    // override darkmode
    if (this.props.useDarkMode) {
      theme = themeMUIDark;
    }

    // override theme
    if (this.props.theme) { theme = createTheme(this.props.theme as ThemeOptions) }

    return (
      <ThemeProvider theme={theme}>

        <OAuth appsettings={this.props.appsettings} onAuth={(auth) => {
          this.props.onAuth(auth);
        }}>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={this.state.open}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.toggle}
                  edge="start"
                  sx={{
                    marginRight: "36px",
                    ...(this.state.open && { display: "none" }),
                    ml: -1,
                  }}
                >
                  <MenuIcon />
                </IconButton>

                <Button
                  sx={{
                    display: { xs: "none", sm: "block" },
                  }}
                  variant="text"
                  color="inherit"
                  component={Link}
                  to="/"
                >
                  {this.props.title}
                </Button>
                {/* 
                <Button component={({ innerRef, ...props }) => <Link {...props} to={"/"} />}>
                  {this.props.title}
                </Button> */}

                <Box sx={{ flexGrow: 1 }} />

                <Box>
                  {this.props.navbarContent}
                </Box>

                <Button
                  id="navbar_account_link_button"
                  variant="text"
                  color="inherit"
                  component={Link}
                  to="/account"
                  endIcon={<ProfileIcon />}>
                  <TokenParse property={"preferred_username"} />
                </Button>
                {/* 
                  <IconButton
                    component={Link}
                    to="/account"
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                  >
                    <ProfileIcon />
                  </IconButton> */}
              </Toolbar>
            </AppBar>

            <Drawer variant="permanent" open={this.state.open}>
              <DrawerHeader>
                <IconButton onClick={this.toggle}>
                  <ChevronLeftIcon />
                </IconButton>
              </DrawerHeader>
              <Divider />

              {this.props.menu}
            </Drawer>

            <Box
              component="main"
              sx={{
                flexGrow: 1,
                height: window.innerHeight
              }}
            >
              <DrawerHeader />

              {this.props.alert}
              {/* <Box style={{ display:'flex', flexDirection:'row'}}> */}

              <Outlet />
            </Box>
          </Box>
        </OAuth>
      </ThemeProvider>
    );
  }
}
