import React from "react";
import Paper from "@mui/material/Paper";

import { boumaticApi } from "../../App";
import { Chemical } from "../../api/interfaces";
import { Await, DataGrid } from 'components';

interface State {
    chemicalsResponse?: Await<ReturnType<typeof boumaticApi.api.Chemicals.GetChemicals>>
}

interface Props {}

export class ChemicalList extends React.Component<Props, State> {
    state: State = {}

    componentDidMount = () => {
        this.getChemicals();
    }

    getChemicals = async () => {
        const chemicalsResponse = await boumaticApi.api.Chemicals.GetChemicals()
        this.setState({chemicalsResponse});
    }

    render() {
        return <Paper elevation={3}>
            {/* <AppBar position="static" color="primary" enableColorOnDark>
                <Toolbar variant="dense" color={"primary"}>
                    <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                        Chemicals
                    </Typography>
                </Toolbar>
            </AppBar> */}

            {/* START DATA TABLE */}
            <DataGrid<Chemical>
                title="Chemicals"
                columns={[
                    {
                        name: "skuNumber",
                        title: "SKU NO.",
                        width: 50,
                    },
                    {
                        name: "name",
                        title: "NAME",
                        align: "left",
                    },
                    {
                        name: "alternativeName",
                        title: "OTHER NAME",
                        align: "left",
                        width: 50,
                    },
                    {
                        name: "plcId",
                        title: "PLC",
                        align: "left",
                        width: 50,
                    },
                    {
                        name: "active",
                        title: "IS ACTIVE",
                        align: "left",
                        type: "boolean",
                        width: 50,
                    },
                ]}
                rows={this.state.chemicalsResponse?.data}
                editMode
                allowEdit
                addNewButtonText="ADD CHEMICAL"
                showSaveAllButton
                allowAddRow
                allowDelete
                allowSearch
                onChange={ async (props) => {
                    return await boumaticApi.api.Chemicals.UpsertChemical(props.row);
                }}
                onDelete={ async (props) => {
                    if (!props.row.id) { return; }
                    return await boumaticApi.api.Chemicals.DeleteChemical({ id : props.row.id });
                }}
                height={1000}
            />
        </Paper>
    }
}