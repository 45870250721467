import { Box, Paper, Snackbar, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Formula, FormulaState } from "../../api";
import { boumaticApi } from "../../App";


import { FormulaInstructions, FormulaStatus, FormulaStepsList, ProductsList, QualityControls, } from "../";


import { Await, DataGrid, IExtendedRow, TextArea } from 'components';

import AppBar from "@mui/material/AppBar";

import Button from "@mui/material/Button";

import {
  AssignmentTwoTone as ReportIcon,
  EditTwoTone,
  RefreshTwoTone as RefreshIcon,
  SaveTwoTone
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Switch from "@mui/material/Switch";
import { clone } from "lodash";


interface Props {
  formulaId?: string;
  onChange: (formula: Formula) => void;
}

interface State {
  // success: boolean; // DEPRECATED. 
  hasDuplicate: boolean,
  hasIncorrectPercentage: boolean,
  isActive: boolean;
  actionText: string;
  formulaResponse?: Await<ReturnType<typeof boumaticApi.api.Formulas.GetFormula>>
  formulaUpsertResponse?: Await<ReturnType<typeof boumaticApi.api.Formulas.UpsertFormula>>
  formulasListResponse?: Await<ReturnType<typeof boumaticApi.api.Formulas.GetFormulas>>;
  hasDuplicateSKU: boolean,
  hasDuplicateSKUName: boolean,
  busy: boolean,
  maintenance: boolean
  specialInstruction?: string
  editMode?: boolean
}


export class FormulaEdit extends React.Component<Props, State> {
  state: State = {
    hasIncorrectPercentage: false,
    // success: false,
    hasDuplicate: false,
    isActive: false,
    hasDuplicateSKU: false,
    hasDuplicateSKUName: false,
    actionText: '',
    busy: true,
    maintenance: false
  };

  lastId?: string;

  // References to datagrid components used for save all
  refFormulaDetails?: DataGrid<Formula>
  refFormulaStepsList?: FormulaStepsList;
  refProductsList?: ProductsList;
  refQualityControls?: QualityControls;
  refFormulaInstructions?: FormulaInstructions;
  refSpecialInstructions?: TextArea;

  componentDidMount = async () => {
    this.getData();
    console.log(this.refFormulaDetails);
    console.log(this.state.formulaResponse)
  }

  componentDidUpdate = () => {
    this.getData();
    // if (!this.state.formulaResponse) { this.getData(); return; }
    // if (this.state.formulaResponse?.data?.id !== this.props.formulaId) { this.getData(); }
  }

  skuDuplicateCheck = (row: IExtendedRow<Formula>) => {
    console.log(row);
    this.setState({ hasDuplicate: true });
    if (this.state.formulasListResponse?.data) {
      for (let i of this.state.formulasListResponse?.data) {
        if (i.skuNumber === row.skuNumber && i.id !== row.id) {
          this.setState({ hasDuplicateSKU: true });
          return;
        } else {
          this.setState({ hasDuplicateSKU: false });
        }
        if (i.name === row.name && i.id !== row.id) {
          this.setState({ hasDuplicateSKUName: true });
          return;
        } else {
          this.setState({ hasDuplicateSKUName: false });
        }
      }
    }
    this.setState({ hasDuplicate: false });



  }


  showFormulaSKUAlerts = () => {
    return <>
      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={this.state.hasDuplicateSKU || this.state.hasDuplicateSKUName}
      >
        <Alert severity="error">{this.state.hasDuplicateSKUName ? `Formula with name ${this.state.formulaResponse?.data?.name} already exists` : `Formula with Sku Number ${this.state.formulaResponse?.data?.skuNumber} already exists`}</Alert>
      </Snackbar>
    </>

  }
  showSaveSuccessAlert = () => {
    return <>
      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={this.state.formulaUpsertResponse !== undefined}
        onClose={() => this.setState({ formulaUpsertResponse: undefined })}
      >
        <Alert severity={this.state.formulaUpsertResponse?.isSuccessful ? "success" : "error"}>
          {this.state.formulaUpsertResponse?.message}
          {this.state.formulaUpsertResponse?.exceptionMessage}
        </Alert>
      </Snackbar>
    </>

  }

  saveAll = async (state?: FormulaState, active?: boolean) => {
    console.log('test2')
    // if (!this.refFormulaDetails) throw Error('Missing reference to refFormulaDetails')
    // if (!this.refFormulaStepsList?.dataGrid) throw Error('Missing reference to refFormulaStepsList')
    // if (!this.refProductsList?.dataGrid) throw Error('Missing reference to refProductsList')
    // if (!this.refQualityControls?.dataGrid) throw Error('Missing reference to refQualityControls')
    // if (!this.refFormulaInstructions?.dataGrid) throw Error('Missing reference to refFormulaInstructions')
    // if (!this.refSpecialInstructions) throw Error('Missing reference to refSpecialInstructions')
    if (!this.state.formulaResponse?.data) throw new Error('formula not loaded')

    let tasks = [];

    if (this.refFormulaDetails) tasks.push(this.refFormulaDetails.saveAll());
    if (this.refFormulaStepsList?.dataGrid) tasks.push(this.refFormulaStepsList.dataGrid.saveAll());
    if (this.refProductsList?.dataGrid) tasks.push(this.refProductsList.dataGrid.saveAll());
    if (this.refQualityControls?.dataGrid) tasks.push(this.refQualityControls.dataGrid.saveAll());
    if (this.refFormulaInstructions?.dataGrid) tasks.push(this.refFormulaInstructions.dataGrid.saveAll());

    const result = await Promise.all(tasks);
    console.log(result);

    this.setState({editMode: false});

    // if (this.state.formulaResponse.data.active !== this.state.isActive) {
    //   let formula = this.state.formulaResponse.data;
    //   formula.active = this.state.isActive;
    //   await boumaticApi.api.Formulas.UpsertFormula(formula)
    // }


    // let formula = this.state.formulaResponse.data;
    // if (state) formula.state = state;
    // let formulaUpsertResponse = await boumaticApi.api.Formulas.UpsertFormula(formula)
    // this.setState({ formulaUpsertResponse, editMode: !formulaUpsertResponse.isSuccessful })   

    // if (formulaUpsertResponse.isSuccessful) {
    //   if (formulaUpsertResponse.data?.id) this.props.onChange(formulaUpsertResponse.data);
    // }

    // did instructions change?
    // if (this.state.specialInstruction) {
    //   let formula = this.state.formulaResponse.data;
    //   formula.specialInstruction = this.state.specialInstruction;
    //   const specialInstuction = await boumaticApi.api.Formulas.UpsertFormula(formula)
    //   result.push([specialInstuction]);
    // }
    // this.setState({ success: true });


  }


  generateFormulaSkuNo = async () => {
    const result = await boumaticApi.api.Formulas.GetLastFormulaBasedOnSkuNumber();
    if (result.data) {
      return result.data.skuNumber + 1;
    }
    return -1;

  }

  getData = async () => {

    ////
    let id = this.props.formulaId;
    if (id === this.lastId) return;

    // if its a new formula we create a blank formula in state to edit.
    if (id === 'new') {
      const skuNumber = await this.generateFormulaSkuNo();
      this.setState({ formulaResponse: undefined, editMode: true });
      let newFormula: Formula = {
        active: false,
        id: '',
        name: 'New Formula',
        skuNumber: skuNumber,
        plcId: 0,
        compatabilityGroup: 1, // there is no 0 option
        maxVolumePct: 0,
        specificGravity: 0,
        defaultVolume: 0,
        shelfLife: 0,
        canAction: false,
        state: FormulaState.Created,
      }

      /**
       * DataGrid uses these two row entries to keep track if a row is being edited. We set them so a new formula defaults to editMode.
       */
      // @ts-ignore
      newFormula._datagridrowBefore = _.clone(newFormula);
      // @ts-ignore
      newFormula._datagridrowEditMode = true;

      /// End DataGrid row overrides

      // like a blank new formula from the server...
      this.lastId = id;
      this.setState({
        formulaResponse: {
          isSuccessful: true,
          count: 1,
          data: newFormula,
          exceptionMessage: '',
          message: 'created a new blank formula'
        }
      });
      return;
    }



    if (id) {
      this.lastId = id;
      this.setState({ formulaResponse: undefined });
      let formulaResponse = await boumaticApi.api.Formulas.GetFormula({ id });
      this.setState({ formulaResponse });
    }
    this.state.formulaResponse?.data?.active ? this.setState({ isActive: true }) : this.setState({ isActive: false });

    const formulasListResponse = await boumaticApi.api.Formulas.GetFormulas({
      includeInactiveFormulas: true,
    });

    this.setState({ formulasListResponse });

  }

  renderFormulaData = (formulaId?: string) => {

    if (!formulaId || formulaId === 'new') return null;

    return <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', pt: 0.5 }}>
        <FormulaStepsList
          ref={(el) => { if (el) this.refFormulaStepsList = el; }}
          editMode={this.state.editMode}
          key={`steps_${formulaId}`}
          formulaId={formulaId} sx={{ flex: 2 }} />
        <ProductsList
          ref={(el) => { if (el) this.refProductsList = el; }}
          editMode={this.state.editMode}
          key={`products_${formulaId}`}
          formulaId={formulaId}
          sx={{ flex: 1, ml: 1 }} />
      </Box>

      <QualityControls
        ref={(el) => { if (el) this.refQualityControls = el; }}
        editMode={this.state.editMode}
        key={`qc_${formulaId}`}
        formulaId={formulaId}
        sx={{ pt: 1 }}
      />

      <FormulaInstructions
        ref={(el) => { if (el) this.refFormulaInstructions = el; }}
        editMode={this.state.editMode}
        key={`instructions_${formulaId}`}
        formulaId={formulaId}
        sx={{ pt: 1 }} />

      <TextArea
        id="formula_special_instructions"
        ref={(el) => { if (el) this.refSpecialInstructions = el; }}
        editMode={this.state.editMode}
        title="Special Instructions"
        value={this.state.specialInstruction || this.state.formulaResponse?.data?.specialInstruction || ""}
        onChange={(val) => {
          this.setState({ specialInstruction: val })
        }}
      />

    </Box>
  }

  render() {
    // if (this.state.formulaResponse.data) return <Alert severity="error">{this.state.formulaResponse.exceptionMessage}</Alert>
    let formulaId = this.props.formulaId;

    return (

      <Paper id="formula_edit" sx={{ ml: 2, p: 1, pt: 0 }} elevation={10}>
        <Paper sx={{ mb: 1 }} elevation={1}>
          {this.showFormulaSKUAlerts()}

          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.formulaUpsertResponse !== undefined}
            onClose={() => this.setState({ formulaUpsertResponse: undefined })}
          >
            <Alert severity={this.state.formulaUpsertResponse?.isSuccessful ? "success" : "error"}>
              {this.state.formulaUpsertResponse?.message}
              {this.state.formulaUpsertResponse?.exceptionMessage}
            </Alert>
          </Snackbar>


          <AppBar position="static" color="primary" enableColorOnDark>
            <Toolbar variant="dense" color={"primary"}>
              <Typography id="formula_edit_name_heading" variant="h5" component="div" sx={{ flexGrow: 1 }}>
                {this.state.formulaResponse?.data?.name}{/* Formula recipe, QA Checks &amp; other details. */}
              </Typography>

              <Button
                id={this.state.editMode ? "formula_edit_save" : "formula_edit_editmode"}
                color="secondary"
                disabled={this.state.hasDuplicate}
                variant="contained"
                size="small"
                startIcon={this.state.editMode ? <SaveTwoTone /> : <EditTwoTone />}
                sx={{ mr: 2, width: 120 }}
                onClick={async () => {
                  if (this.state.editMode) {
                    await this.saveAll();
                  } else {
                    this.setState({ editMode: true });
                  }
                }}
              >
                {this.state.editMode ? 'SAVE ALL' : 'EDIT MODE'}
              </Button>

              <Button
                color="secondary"
                variant="contained"
                size="small"
                startIcon={<ReportIcon />}
                sx={{ mr: 2 }}
                component={Link}
                to={`/admin/formulas/reports/${this.props.formulaId}`}
              >
                Report
              </Button>

              <Button
                color="secondary"
                variant="contained"
                size="small"
                startIcon={<RefreshIcon />}
                sx={{ mr: 2 }}
              >
                Refresh
              </Button>



            </Toolbar>
          </AppBar>

          <DataGrid<Formula>
            id="datagrid_formula_edit"
            ref={(el) => {
              if (el) this.refFormulaDetails = el;
            }}
            // allowEdit
            allowDelete={false}
            editMode={this.state.editMode}
            columns={[
              {
                name: "state",
                title: "",
                renderCell: (props) => <FormulaStatus status={props.value} sx={{ ml: 1, mt: 0.65, mr: 1 }} />,
                editable: false,
                width: 50,
              },
              // {
              //   name: "state",
              //   title: "STATUS",
              //   align: "left",
              //   type: "enum",
              //   editable: false,
              //   width: 180,
              //   values: [
              //     { id: 0, value: "Created" },
              //     { id: 1, value: "Rejected" },
              //     { id: 2, value: "Approved" },
              //     { id: 3, value: "Awaiting Approval" }
              //   ],
              //   sx: { whiteSpace: 'nowrap'}
              // },
              {
                name: "name",
                title: "NAME",
                align: 'left',
                onRowChange: async (props) => { this.skuDuplicateCheck(props.row) }
              },
              {
                name: "skuNumber",
                title: "SKU NO.",
                align: 'center',
                width: 50,
                onRowChange: async (props) => { this.skuDuplicateCheck(props.row) }
              },
              {
                name: "compatabilityGroup",
                title: "COMPATIBILITY",
                type: "enum",
                align: 'center',
                values: [
                  { id: 1, value: 'Iodine' },
                  { id: 2, value: 'Iodine solution' },
                  { id: 3, value: 'Acid/Detergent' },
                  { id: 4, value: 'Chlor-Alk' },
                ],
                placeholder: 'Please select type',
                width: 100,
              },
              { name: "maxVolumePct", type: 'number', title: "MAX VOLUME %", align: 'center', width: 75 },
              { name: "specificGravity", type: 'number', title: "SPECIFIC GRAVITY", align: 'center', width: 75 },
              { name: "defaultVolume", type: 'number', title: "SIZE", align: 'center', width: 75 },
              { name: "shelfLife", type: 'number', title: "SHELF LIFE", align: 'center', width: 75 },
              {
                name: "revision",
                title: "REV",
                align: 'center',
                width: 50,
                sx: { whiteSpace: 'nowrap' },
                editable: false /*this.state.formulaResponse?.data?.state <= FormulaState.Created*/
              },
              {
                name: "state",
                title: `${this.state.formulaResponse?.data?.state === FormulaState.AwaitingApproval ? 'APPROVE' : ''}`,
                align: 'center',
                renderCell: (props) => <div>
                  {this.state.formulaResponse?.data?.state === FormulaState.AwaitingApproval && <Button
                    onClick={() => this.saveAll(FormulaState.Approved)}>
                    <FormulaStatus status={FormulaState.Approved} sx={{ ml: 1, mt: 0.65, mr: 1 }} /></Button>}</div>,
                editable: false,
                width: 0,
              }, {
                name: "state",
                title: `${this.state.formulaResponse?.data?.state === FormulaState.AwaitingApproval ? 'REJECT' : ''}`,
                align: 'center',
                renderCell: (props) => <div>
                  {this.state.formulaResponse?.data?.state === FormulaState.AwaitingApproval && <Button
                    onClick={() => this.saveAll(FormulaState.Rejected)}>
                    <FormulaStatus status={FormulaState.Rejected} sx={{ ml: 1, mt: 0.65, mr: 1 }} /></Button>}</div>,
                editable: false,
                width: 0,
              }, {
                name: "active",
                title: `${this.state.editMode && this.state.formulaResponse?.data?.state === FormulaState.Approved ? 'ACTIVE' : ''}`,
                align: 'center',
                renderCell: (props) => <div>
                  {this.state.editMode && this.state.formulaResponse?.data?.state === FormulaState.Approved && <Switch
                    checked={this.state.isActive}
                    value={this.state.isActive}
                    color="secondary"
                    onChange={() => { this.setState({ isActive: !this.state.isActive }); }}
                  />}</div>,
                editable: false,
                width: 0,
              },
            ]}
            rows={!!this.state.formulaResponse?.data ? [this.state.formulaResponse?.data] : undefined}
            pageSize={1}
            onChange={async (props) => {
              props.row.skuNumber = parseInt(`${props.row.skuNumber}`);
              const formulaClean = clone(props.row);
              delete formulaClean._datagridResult;
              delete formulaClean._datagridrowId;
              const formulaResponse = await boumaticApi.api.Formulas.UpsertFormula(formulaClean);
              this.setState({ formulaUpsertResponse: formulaResponse }); // to show errors and so on
              if (formulaResponse.isSuccessful && formulaResponse.data) {
                // lets the parent know a formula has been changed
                if (formulaResponse.data.id !== this.lastId) {
                  this.props.onChange(formulaResponse.data);
                } else {
                  this.setState({ formulaResponse });
                }
              }
              return formulaResponse;
            }}
          />
        </Paper>

        {this.renderFormulaData(formulaId)}

      </Paper>
    );
  }

}
