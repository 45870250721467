import React, { Suspense } from 'react'
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import {boumaticApi, boumaticTheme} from '../../App';
import { LinearProgress, Typography } from '@mui/material';
import { Await, Report, DataGridPDF} from 'components';
import { View, Text } from '@react-pdf/renderer';
import { Instruction } from '../../api/interfaces';

// ROUTE
export function BatchInstructionsPDFRoute(props: any) {
    let params = useParams<"id">();

    if (!params.id) {
        return <Alert severity="error">Missing formula Id</Alert>
    }

    return <BatchInstructionsPDF id={params.id} />
}

// COMPONENT


interface BatchInstructionsPDFState {
    batchR?: Await<ReturnType<typeof boumaticApi.api.Batches.GetBatch>>
    formulaR?: Await<ReturnType<typeof boumaticApi.api.Formulas.GetFormula>>
    instructionsR?: Await<ReturnType<typeof boumaticApi.api.Instructions.GetInstructionByFormulaId>>
}
export class BatchInstructionsPDF extends React.Component<{ id: string }, BatchInstructionsPDFState>{
    state: BatchInstructionsPDFState = {}

    componentDidMount = async () => {
        const batchR = await boumaticApi.api.Batches.GetBatch({ id: this.props.id });
        this.setState({ batchR });
        if (!batchR?.data) return;
        const formulaR = await boumaticApi.api.Formulas.GetFormula({ id: batchR.data.formulaId as string });
        this.setState({ formulaR });
        if (!formulaR?.data) return;
        const instructionsR = await boumaticApi.api.Instructions.GetInstructionByFormulaId({ formulaId: formulaR.data.id as string });
        this.setState({ instructionsR });
    }

    render() {
        if (!this.state.batchR || !this.state.formulaR || !this.state.instructionsR) { return <LinearProgress /> }

        if (!this.state.batchR.data || this.state.batchR.exceptionMessage) {
            return <Alert severity="error">BATCH {this.state.batchR.exceptionMessage}</Alert>
        }

        if (!this.state.formulaR.data || this.state.formulaR.exceptionMessage) {
            return <Alert severity="error">FORMULA {this.state.formulaR.exceptionMessage}</Alert>
        }

        if (!this.state.instructionsR.data || this.state.instructionsR.exceptionMessage) {
            return <Alert severity="error">INSTRUCTIONS {this.state.instructionsR.exceptionMessage}</Alert>
        }




        return <Suspense fallback={<Typography style={{ textDecoration: 'none' }}>loading pdf..</Typography>}>
            <Report
                logoUrl="/logo.png"
                title={`BATCH INSTRUCTIONS`} // : ${this.props.formulaId}`}
                filename={`batchInstructions.pdf`}
                theme={boumaticTheme}
            >
                <View style={{ paddingTop: 10, paddingBottom: 30 }}>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <Text style={{ fontFamily: 'RobotoBold', fontSize: 25 }}>{this.state.formulaR.data.name}</Text>
                        <Text style={{ fontFamily: 'Roboto', fontSize: 15, paddingTop: 6, paddingLeft: 5 }}>REV: {this.state.formulaR.data.revision}</Text>
                    </View>
                    <Text style={{ fontFamily: 'Roboto', fontSize: 15 }}>Formula # {this.state.formulaR.data.skuNumber}</Text>
                    <Text style={{ fontFamily: 'Roboto', fontSize: 15 }}>Lot Number: {this.state.batchR.data.lotNumber}</Text>
                </View>

                <DataGridPDF<Instruction>
                    rows={this.state.instructionsR?.data.map(i => { 
                        if (i.procedure) i.procedure = i.procedure.replace(/[^\w\s]/gi, ''); 
                        return i })}
                    columns={[{
                        name: 'stepNumber',
                        title: 'SR NO.',
                        width: '5%',
                        align: 'center'
                    }, {
                        name: 'procedure',
                        title: 'PROCEDURE',
                        width: '65%'
                    }, {
                        title: 'CHECKED BY',
                        width: '20%',
                        align: 'center'
                    }, {
                        title: 'DATE',
                        width: '20%',
                        align: 'center'
                    }]}
                />


            </Report>
        </Suspense>
    }
}