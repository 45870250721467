
import React from "react";
import { FormulaReport } from '../chemist'


import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";

export function AdminFormulasReportsRoute() {
    let params = useParams<"id">();

    if (!params.id) {
        return <Alert severity="error">Missing formula Id</Alert>
    }

    return <FormulaReport formulaId={params.id} />

}

