import React from "react";

import { FormulaState } from "../../api/interfaces";

import {
  CheckTwoTone as FormulaActiveIcon,
  AssignmentTurnedIn as FormulaApprovalIcon,
  AddTwoTone as FormulaCreatedIcon,
  CloseTwoTone as FormulaRejectedIcon
} from "@mui/icons-material";

import Tooltip from "@mui/material/Tooltip";

export const FormulaStatus = (props: { status: FormulaState, sx?: any }) => {

  let value = props.status;

  if (value === FormulaState.Rejected)
    return (
      <><Tooltip placement="left" title="Formula rejected.">
        <FormulaRejectedIcon color="error" sx={props.sx}
          aria-label="Rejected" />

      </Tooltip>
        {/* Hidden tag for frontend tests */}
        <span style={{ display: 'none' }}>Rejected</span>
      </>
    );
  if (value === FormulaState.Created)
    return (
      <><Tooltip placement="left" title="Formula created.">
        <FormulaCreatedIcon color="primary" sx={props.sx}
          aria-label="Created" />

      </Tooltip>
        {/* Hidden tag for frontend tests */}
        <span style={{ display: 'none' }}>Created</span>
      </>
    );
  if (value === FormulaState.AwaitingApproval)
    return (
      <><Tooltip placement="left" title="Formula awaiting approval.">
        <FormulaApprovalIcon color="warning" sx={props.sx}
          aria-label="AwaitingApproval" />

      </Tooltip>
        {/* Hidden tag for frontend tests */}
        <span style={{ display: 'none' }}>AwaitingApproval</span>
      </>
    );

  if (value === FormulaState.Approved)
    return (
      <><Tooltip placement="left" title="Formula is active.">
        <FormulaActiveIcon color="success" sx={props.sx}
          aria-label="Active" />

      </Tooltip>
        {/* Hidden tag for frontend tests */}
        <span style={{ display: 'none' }}>Active</span>
      </>
    );

  return null;
}