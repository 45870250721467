import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";


export function exportToXLSX(json: any[], excelFileName: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
        Sheets: {'Production Log': worksheet},
        SheetNames: ['Production Log'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });
    const EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(
        data,
        excelFileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
}

