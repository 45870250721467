import React from "react";
import Typography from "@mui/material/Typography";
import { boumaticApi } from "../../App";
import { Method } from "../../api/interfaces";
import Skeleton from "@mui/material/Skeleton";

interface Props {
    id?: string
    formulaId?: string
    component: (props:Method) => React.ReactNode
}

interface State {
    result?: Method;
}

export class GetMethod extends React.Component<Props, State> {
    state: State = {}

    componentDidMount = async () => {

        if (this.props.id) {
            const result = await boumaticApi.api.Methods.GetMethod({id: this.props.id}).then(r => r.data);
            if (result) this.setState({result});
        }

        // if (this.props.formulaId) {
        //     const instruction = await boumaticApi.api.Instructions.GetInstruction({ id: this.props.id })

        //     const instruction = await boumaticApi.api.Instructions
        //     .GetInstructionByFormulaId({formulaId: this.props.formulaId}).then(r => r.data);
        //     if (instruction) this.setState({instruction});
        // }        
    }

    render() {
        if (!this.state.result) return <Skeleton />

        if (this.props.component) {
            return this.props.component(this.state.result)
        }

        return <Typography>{this.state.result.name}</Typography>
    }
}