import React from "react";

// NAVIGATION and template MAIN app.
import {
  IAppSettings,
  Main,
  MenuTitle,
  MenuLink,
} from "./components";
import { Placeholder } from './components';

import {
  Account,
  AdminFormulasReportsRoute,
  AdminFormulasRoute,
  AuditTrailRoute,
  BalReport,
  BatchViewRoute,
  BatchViewSingleRoute,
  CoaReport,
  ConfigRoutes
} from "./routes";

// MENU
import Divider from "@mui/material/Divider";

import {
  ScienceTwoTone as FormulaIcon,
  HistoryTwoTone as AuditTrailIcon,
  ListAltTwoTone as ConfigTablesIcon,
  HourglassFullTwoTone as BatchProcessIcon,
  LogoutTwoTone as ExternalLinkIcon,
} from "@mui/icons-material";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Alert } from "@mui/material";

// import api for the project
import { APIBouMaticChemistApp, AppMode } from "./api";
import { APIBouMaticChemistAppSignalR } from "./api/api.boumatic.signalr";
import {
  FormulaAuditTrails,
  ChemicalList,
  ContainersList,
  MethodsList,
  ProductionLogList,
  BatchAuditLogList,
  BatchInstructionsPDFRoute,
  FillingReportPDFRoute,
  BatchTicketPDFRoute,
  Maintenance,
} from "./chemist";
import { checkAdmin } from "components/utils/oauth/checkAdmin";

// REST API
export const boumaticApi = new APIBouMaticChemistApp();
// SIGNALR API
export const boumaticApiSignalR = new APIBouMaticChemistAppSignalR();
interface AppState {
  appsettings?: IAppSettings;
  title: string;
  mode?: AppMode;
}

export const boumaticTheme: any = {
  palette: {
    mode: "dark",
    primary: {
      main: "#008dcb",
    },
    secondary: {
      main: "#f78419",
      contrastText: "rgba(255,255,255,0.87)",
    },
    background: {
      default: "#272b34",
      paper: "#272b34",
    },
    text: {
      primary: "#eeeeee",
    },
  },
};

export default class App extends React.Component<{}, AppState> {
  state: AppState = {
    title: "BouMatic Chemist App",
  };

  componentDidMount = async () => {
    document.title = this.state.title;
    const appsettingsResponse = await boumaticApi.api.AppSettings.Get();
    this.setState({ appsettings: appsettingsResponse.data });

    boumaticApiSignalR.hubConnection?.on(
      "setSystemMode",
      this.handleSystemModeChanges
    );
  };

  handleSystemModeChanges = (mode?: AppMode) => {
    console.log("SIGNALR AppMode", mode);
    this.setState({ mode });
  };

  componentWillUnmount = () => {
    boumaticApiSignalR.hubConnection?.off("setSystemMode");
  };

  // MAIN NAVIGATION MENU
  menu = () => {
    let admin = checkAdmin();
    return <>
      {admin && <>
        <MenuTitle title="ADMIN" />
        <MenuLink icon={<FormulaIcon />} title="Formulas" to="/admin/formulas" />
        <MenuLink
          icon={<AuditTrailIcon />}
          title="Audit Trail"
          to="/admin/audittrail"
        />
        <MenuLink
          exactPath={false}
          icon={<ConfigTablesIcon />}
          title="Config"
          to="/config/chemicals" // default to chemicals tab
        />
        <Divider />
      </>}
      <MenuTitle title="USER" />
      <MenuLink
        icon={<BatchProcessIcon />}
        title="Batch Process"
        exactPath={false}
        to="/batchprocess"
      />
      <MenuLink
        icon={<AuditTrailIcon />}
        title="Batch Audit Log"
        to="/batchauditlog"
      />
      <MenuLink
        icon={<ConfigTablesIcon />}
        title="Production Log"
        to="/productionlog"
      />
      <Divider />
      <MenuLink
        icon={<ExternalLinkIcon />}
        title="JD Edwards"
        href="https://jde.boumatic.com/ext/Login"
      />
    </>
  }


  render() {
    return (

      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Main
              theme={boumaticTheme}
              title={this.state.title}
              menu={this.menu()}
              alert={(this.state.mode === AppMode.Maintainance) && (
                <Alert severity={"error"} style={{ width: "100%" }}>
                  Warning!!! Application is currently in maintanence mode.
                </Alert>
              )}
              appsettings={this.state.appsettings}
              navbarContent={<Maintenance />}
              onAuth={async (auth) => {
                boumaticApi.events.emit("auth");
                boumaticApi.authorization = auth.authorization;
                const mode = await boumaticApi.api.AppSettings.GetSystemMode().then(
                  (r) => r.data
                );
                if (mode !== undefined) this.setState({ mode });
              }}
            />}
          >
            <Route
              index
              element={<Navigate to="/batchprocess" replace={true} />}
            />

            <Route
              path="/batchprocess"
              element={<BatchViewRoute />}
            >
              <Route path=":batchId/:tankId" element={<BatchViewSingleRoute />} />
            </Route>

            <Route path="account" element={<Account />} />

            <Route
              path="admin/formulas"
              element={<AdminFormulasRoute />}
            />

            <Route
              path="admin/formulas/:id"
              element={<AdminFormulasRoute />}
            />

            <Route path="admin/formulas/reports/:id"
              element={<AdminFormulasReportsRoute />}
            />

            <Route
              path="admin/audittrail"
              element={<FormulaAuditTrails />}
            />

            <Route
              path="admin/audittrailloglist/:id"
              element={<AuditTrailRoute />}
            />

            <Route
              path="config"
              element={<ConfigRoutes />}
            >
              <Route path="chemicals" element={<ChemicalList />} />
              <Route path="containers" element={<ContainersList />} />
              <Route path="methods" element={<MethodsList />} />
              <Route index element={<Placeholder title="config" />} />
            </Route>

            <Route
              path="/productionlog"
              element={<ProductionLogList />}
            />

            <Route
              path="/batchauditlog"
              element={<BatchAuditLogList />}
            />

            <Route
              path="/coa-report/:id"
              element={<CoaReport />}
            />

            <Route
              path="/batch-audit-log-report/:startDate/:endDate/:referenceId"
              element={<BalReport />}
            />

            <Route
              path="/batch-instructions/:id"
              element={<BatchInstructionsPDFRoute />}
            />

            <Route
              path="/batch-ticket-report/:id"
              element={<BatchTicketPDFRoute />}
            />

            <Route
              path="/filling-ticket-report/:formulaId"
              element={<FillingReportPDFRoute />}
            />


            {/* ABOVE THIS LINE SHOULD BE DONE */}


            {/*

            <Route
              path="/batch-ticket-report/:id"
              element={<Placeholder title="Batch Ticket Report" />}
            />

            <Route
              path="/coa-report/:id"
              element={<Placeholder title="Coa Report" />}
            />

            

            <Route
              path="/formula-report/:id"
              element={<Placeholder title="Formula Report" />}
            />



            <Route
              path="/batch-audit-log-report"
              element={<Placeholder title="Batch Auti Log Report" />}
            /> */}



            <Route path="*" element={<Alert severity="error" >404 Not found</Alert>} />

          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}
