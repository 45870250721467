export interface CloudServiceActionResult<T> {
  isSuccessful: boolean;
  message?: string;
  data?: T;
  exceptionMessage?: string;
  count: number;
}

export enum AppMode {
  Live = 0,
  Maintainance = 1,
}

// POST /api/AuditTrails/GetBatchAuditLog
export interface AuditTrailSearchArgs {
  formulaId?: string | null;
  chemicalId?: string | null;
  endDate?: string;
  mixingTankId?: string | null;
  startDate?: string;
  userId?: string | null;
  tableName?: string;
  pageNumber: number;
  pageSize: number;
}

export interface BatchAuditLog {
  weight: number;
  lotNumber?: string;
  batchId?: string;
  batchState?: string;
  formulaName?: string;
  mixingTankNo?: number;
  createdDate?: string;
  createdBy?: string;
}

export interface BatchAuditLogListPagedResponse {
  data?: BatchAuditLog[];
  isSuccessful: boolean;
  message?: string;
  exceptionMessage?: string;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export interface BatchAuditReport {
  batchAuditLog: BatchAuditLog;
  auditTrails: AuditTrail[];
}

export interface AuditTrailResponse {
  actionedBy?: string;
  actionDate?: string;
  action?: string;
  referenceId?: string;
  description?: string;
  propertyName?: string;
  oldValue?: string;
  newValue?: string;
}

export interface AuditTrailResponseListPagedResponse {
  data?: AuditTrailResponse[];
  isSuccessful: boolean;
  message?: string;
  exceptionMessage?: string;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export type CacheItemState = 0 | 1 | 2 | 3;

// TODO Not sure of this enum.
// used by Audit.action
export enum AuditAction {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  Update = "Update"
} //= 'Update' | string // 1 | 2 | 3;

export interface AuditTrail {
  actionedBy?: string;
  actionDate?: string;
  userId?: string;
  action: AuditAction;
  referenceId?: string;
  description?: string;
  propertyName?: string;
  oldValue?: string;
  newValue?: string;
  tableName?: string;
  lotNumber?: string;
}

export interface FormulaAuditTrail {
  createdBy?: string;
  createdDate?: string;
  action: AuditAction;
  formulaId?: string;
  formulaName?: string;
  description?: string;
  propertyName?: string;
  oldValue?: string;
  newValue?: string;
  tableName?: string;
}

export interface FormulaAuditTrailListPagedResponse {
  data?: FormulaAuditTrail[];
  isSuccessful: boolean;
  message?: string;
  exceptionMessage?: string;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export type BatchStateString = "Started" | "Queued" | "Mixing" | "Mixing2" | "AwaitingQc" | "Evaluated" | "Adjusting" | "Finished" | "Completed";

export enum BatchState {
  Started = 1,
  Queued = 2,
  Mixing = 3,
  Mixing2 = 4,
  AwaitingQc = 5,
  Evaluated = 6,
  Adjusting = 7,
  Finished = 8,
  Completed = 9,
}

export enum BatchStateStepNumber {
  Started = 1,
  Queued = 2,
  Mixing = 3,
  // Mixing2 = 4,
  AwaitingQc = 4,
  Evaluated = 5,
  Adjusting = 6,
  // Finished = 7,
  Completed = 7,
}

// // export type BatchState = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
// export type BatchState = "Started"
//   | "Queued"
//   | "Mixing"
//   | "Mixing 2"
//   | "Awaiting QC"
//   | "Evaluated"
//   | "Adjusting"
//   | "Finished"
//   | "Completed";

// export const BatchStates: BatchState[] = [
//   "Started",
//   "Queued",
//   "Mixing",
//   "Mixing 2",
//   "Awaiting QC",
//   "Evaluated",
//   "Adjusting",
//   "Finished",
//   "Completed",
// ];

export interface Batch {
  batchStateId: number
  isEditing: boolean
  formulaName: string
  mixingTankNo?: number
  timeStamp: string;
  lotNumber?: string;
  mixingTankId?: string;
  formulaId?: string;
  formulaMultiplier: number;
  weight: number;
  batchVolume: number;
  batchState: BatchState;
  queuePlace: number;
  nextPlcStep: number;
  fillInstructs?: string | null;
  comments?: string | null;
  cacheInsertedDate?: string;
  cacheLastUpdatedDate?: string;
  cacheItemState?: string;
  /** added by backend */
  id: string;
}


export type StepStatus = 0 | 1 | 2;

export interface BatchIngredient {
  id: string
  batchId?: string;
  batchState?: BatchState
  chemical?: string;
  chemicalSku?: number;
  chemicalId?: string;
  stepNumber: number;
  lotNumber?: string | null;
  weight: number;
  adjustmentWeight: number;
  weightAdded: number;
  agitatorRunTime: number;
  agitatorFreq: number;
  stepStatus: StepStatus;
}


export interface TestDescription {
  formulaId?: string;
  methodId?: string;
  minimum: number;
  maximum: number;
  units?: string;
  active: boolean;
  measuredValue: number;
  method?: string;
  isOk: boolean;
  isOverridden?: boolean;
  overriddenBy?: string | null;
  id: string
}

export interface CertificateOfAnalysis {
  lotNumber?: string;
  mixingTankId?: string;
  formulaId?: string;
  formulaName?: string;
  id: string;
  userId?: string;
  firstName?: string;
  lastSurname?: string;
  testingDate: string;
  manufactureDate: string;
  testDescriptions?: TestDescription[];
  comments?: string | null;
}

export interface CertificateOfAnalysisRequestByFormulaId { formulaId: string, lotNumber: string }

export interface Chemical {
  id: string
  skuNumber: number;
  name: string;
  alternativeName?: string;
  plcId?: string;
  active: boolean;
  product?: string;
}

export interface ICurrentConfigurationResponse {
  configuration?: any;
}

export interface Container {
  id?: string;
  skuNumber: number;
  name: string;
  active: boolean;
  capacityVolume: number;
}

// export type FormulaState = 0 | 1 | 2 | 3;

export enum FormulaState {
  Created = 0,
  Rejected = 1,
  Approved = 2,
  AwaitingApproval = 3,
}

// Formula/GetFormula
export interface Formula {
  skuNumber: number;
  plcId: number;
  name: string;
  compatabilityGroup: number;
  maxVolumePct: number;
  specificGravity: number;
  defaultVolume: number;
  shelfLife: number;
  active: boolean;
  revision?: string;
  canAction: boolean;
  specialInstruction?: string | null;
  state: FormulaState;
  cacheInsertedDate?: string;
  cacheLastUpdatedDate?: string;
  cacheItemState?: string;
  id: string;
}

export interface FormulaStep {
  id: string;
  chemicalId?: string;
  formulaId?: string;
  stepNumber: number;
  percentage: number;
  agitatorRunTime: number;
  agitatorFreq: number;
  active: boolean;
}

export interface FillTicket {
  batchLotNumber: string;
  batchVolume: number;
  containers: Containers[];
  createdDate: string;
  expiryDate: string;
  formulaName: string;
  formulaSku: number;
  formulaSpecificGravity: string;
  hasExpiryDate: boolean;
  specialInstructions: string;
  tankId: number;
}

export interface Containers {
  actualQuantity: string;
  containerSize: string;
  productSku: number;
  quantity: number;
  workOrder: string;
}

export interface Instruction {
  id: string;
  formulaId?: string;
  stepNumber: number;
  procedure?: string;
}

export interface Method {
  id: string;
  name: string;
  active: boolean;
}

export interface MixingTank {
  id: string;
  batchId?: string;
  compatabilityGroup: number;
  tankNo: number;
  capacityVolume: number;
  active: boolean;
}

export interface ProductQuantity {
  id?: string
  batchId?: string;
  productId?: string;
  quantity: number;
}

export interface Product {
  id?: string;
  formulaId?: string;
  containerId?: string;
  skuNumber: number;
  active: boolean;
  queuePlace: number;
}

export interface FormulaTolerance {
  id?: string;
  formulaId?: string;
  methodId?: string;
  minimum: number;
  maximum: number;
  units?: string;
  active: boolean;
}

export interface ProductionLog {
  id: string;
  formulaName?: string;
  formulaSku?: string;
  batchSizeInGallons: number;
  batchSizeInPounds: number;
  dateMade?: string;
  lotNumber?: string;
  tankNo: number;
  yield: number;
}

export interface ProductionLogListPagedResponse {
  data?: ProductionLog[];
  isSuccessful: boolean;
  message?: string;
  exceptionMessage?: string;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export interface Validation {
  validationSetId?: string;
  formulaToleranceId?: string;
  measurement: number;
}

export interface ValidationSet {
  batchId?: string;
}

// audit trail getAllUsers.. types might change?
export interface IUser {
  id: any;
  firstName: string;
  lastName: string;
  email: string;
  enabled: boolean;
  username: string;
  roles: string;
  phoneNumber: string;
  countryCode: string;
}

export interface IAppSettings {
  authenticationServer: string;
  webApiUrl: string;
  clientUrl: string;
  clientId: string;
}


// signarl R 
export interface BatchProcessingStatus {
  message: string;
  errorMessage: string;
  statusClass: string;
  batchState: BatchState;
  mixingTankId: string;
  batchId: string;
  currentStep: number;
}

export interface PagedRequest {
  PageNumber: number,
  PageSize: number,
  WhereQuery?: string,
  OrderByQuery?: string,
  SelectQuery?: string,
}

export const CompatibilityGroups = [
  { id: 1, value: 'Iodine' },
  { id: 2, value: 'Iodine solution' },
  { id: 3, value: 'Acid/Detergent' },
  { id: 4, value: 'Chlor-Alk' },
  { id: 5, value: 'Virtual' },
]

// Reports/GetBatchTicketReport
export interface BatchReport {
  formulaName: string,
  formulaSku: number,
  expiryDate: string,
  revision: string,
  currentDate: string,
  page: number,
  lotNumber: string,
  tankId: number,
  rawMaterial: BatchReportRawMaterial[],
  specificGravity: number
  bulkDensity: number
  theoreticalYield: string
  manufacturingDate: string,
  shelfLife: number,
  fillInstructs: any,
  hasExpiryDate: boolean
}

// Reports/GetBatchTicketReport rawMaterial
export interface BatchReportRawMaterial {
  stepIndex: number,
  productSku: number,
  formulaStepsPercentage: number,
  chemicalName: string,
  lotNumber: string | null,
  theoreticalQuantity: number,
  actualQuantity: number,
  wgd: null | any,
  ckd: null | any,
  hasExpiryDate: boolean
}