import { View, Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types'
import React from 'react';

export function TitledEntry(props: { title: string, value?: string | number, blank?: boolean, style?: Style }) {
    let style: Style = { display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10 };
    if (props.style) style = {...style,...props.style};

    return <View style={style}>
        <Text style={{ fontFamily: 'RobotoBold', fontSize: 10 }}>{props.title}</Text>
        {props.value !== undefined &&
            <Text style={{ fontFamily: 'Roboto', fontSize: 10, paddingLeft: 5 }}>{props.value}</Text>}
        {props.blank && <View style={{ borderBottom: "1px dashed black", flex: 1 }}></View>}
    </View>
}