import React from "react";
import Typography from "@mui/material/Typography";
import { boumaticApi } from "../../App";

import Skeleton from "@mui/material/Skeleton";
import { Await } from 'components';
import { Chemical } from "../../api/interfaces";

interface IContainerProps {
    id?: string
    component?: (props:Chemical) => React.ReactNode
}

interface IContainerState {
    response?: Await<ReturnType<typeof boumaticApi.api.Chemicals.GetChemical>>;
}

export class GetChemical extends React.Component<IContainerProps, IContainerState> {
    state: IContainerState = {}

    componentDidMount = async () => {
        if (this.props.id) {
            const response = await boumaticApi.api.Chemicals.GetChemical({id: this.props.id})
            if (response) this.setState({response});
        }        
    }

    render() {
        if (!this.state.response) return <Skeleton />
        

        if (!this.state.response.data) return this.state.response.exceptionMessage;
        let item = this.state.response.data;
        if (this.props.component) {
            return this.props.component(item);
        }
        
        return <Typography>{this.state.response.data.name}</Typography>
    }
}