import React from "react";
import Typography from "@mui/material/Typography";
import { boumaticApi } from "../../App";
import { Instruction } from "../../api/interfaces";
import Skeleton from "@mui/material/Skeleton";

interface IInstructionProps {
    id?: string
    formulaId?: string
    component: (props:Instruction) => React.ReactNode
}

interface IInstructionState {
    instruction?: Instruction;
}

export class GetInstruction extends React.Component<IInstructionProps, IInstructionState> {
    state: IInstructionState = {}

    componentDidMount = async () => {

        if (this.props.id) {
            const instruction = await boumaticApi.api.Instructions.GetInstruction({ id: this.props.id }).then(r => r.data);
            console.log(instruction)
            if (instruction) this.setState({instruction});
        }

        // if (this.props.formulaId) {
        //     const instruction = await boumaticApi.api.Instructions.GetInstruction({ id: this.props.id })

        //     const instruction = await boumaticApi.api.Instructions
        //     .GetInstructionByFormulaId({formulaId: this.props.formulaId}).then(r => r.data);
        //     if (instruction) this.setState({instruction});
        // }        
    }

    render() {
        if (!this.state.instruction) return <Skeleton />

        if (this.props.component) {
            return this.props.component(this.state.instruction)
        }

        return <Typography>{this.state.instruction.procedure}</Typography>
    }
}