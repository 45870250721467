import React from "react";
import TextField from "@mui/material/TextField";
import TableRow from "@mui/material/TableRow";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import { IRow, IExtendedRow, IColumn } from './interfaces'
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { Button, Select, Switch, Typography } from "@mui/material";
import MenuItem from '@mui/material/MenuItem'

import {
    CloseTwoTone,
    ErrorSharp,
} from "@mui/icons-material";

import { Tooltip } from "@mui/material";
import _ from "lodash";
import { ModalWrapper } from "../modalWrapper";
import Box from "@mui/system/Box";

interface DataGridRowState {
    busySaving?: boolean;
    theme: any;
}

export class DataGridRow<T> extends React.Component<IRow<T>, DataGridRowState> {
    state: DataGridRowState = {
        busySaving: false,
        theme: ''

    }

    handleCellChange = (column: IColumn<T>, value: any) => {
        let rowUpdated = _.clone(this.props.row) as IExtendedRow<T>;
        if (!column.name) return;
        rowUpdated._datagridrowBefore = rowUpdated._datagridrowBefore || rowUpdated;
        // TODO change/parse types?

        let newValue: any = value;
        if (column.type === 'number') {
            newValue = parseFloat(value);

            if (column.minimum !== undefined && newValue < column.minimum) newValue = column.minimum; 
            if (column.maximum !== undefined && newValue > column.maximum) newValue = column.maximum;
        }

        if (column.type === 'boolean') {
            newValue = value;
        }

        rowUpdated[column.name as keyof T] = newValue as any;
        console.log('handleCellChange', rowUpdated)
        this.props.onChange?.({
            column,
            row: rowUpdated,
            rowBefore: this.props.row
        });

        if (column.onRowChange) {
            column.onRowChange({
                column,
                row: rowUpdated,
                rowBefore: this.props.row
            })
        }
    }

    renderCell = (
        // index: number,
        // align: TableCellProps["align"],
        // style: TableCellProps["style"],
        props: IRow<T>,
        column: IColumn<T>,
        cellDisplay: any,
        value: any) => {

        if (column.to && !column.renderCell) return <Button
            variant="text"
            color="inherit"
            component={Link}
            to={column.to({
                row: props.row,
                column,
                value
            })}
        >{cellDisplay}</Button>

        if (props.to && !column.renderCell) return <Button
            variant="text"
            color="inherit"
            component={Link}
            to={props.to({
                row: props.row,
                column,
                value
            })}
        >{cellDisplay}</Button>

        return cellDisplay
    }


    render() {
        if (!this.props.row || !this.props.columns) return <Skeleton />

        let rowExtended = this.props.row as IExtendedRow<T>;

        /// EDITROW
        let editrow = (this.props.allowEdit || this.props.editMode) && rowExtended._datagridrowEditMode

        if (!this.props.allowDelete) editrow = false;

        let unsaved = rowExtended._datagridrowBefore;

        // skip inactive
        return <TableRow
            id={`data_grid_row_${this.props.index}`}
            className="data_grid_row"
            selected={this.props.selected || (this.props.selectedRow && this.props.selectedRow(this.props.row)) || undefined}
            // hover={!!this.props.to}
            hover
            // onClick={() => {
            //     if (this.props.onClickRow) { this.props.onClickRow({ row }) }
            // }}
            sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                opacity: unsaved ? 0.75 : 1,
                "td, th": { border: this.props.editMode ? 0 : undefined }
                // "td:first-child": {
                //     borderLeft: unsaved ? '2px solid white' : undefined,
                //     borderColor: "primary",
                //     // background: "primary.main"
                // }
            }}
        >
            {this.props.columns.map((column, index) => {

                let align = column.align || (index === 0 ? "left" : "right");

                let style: any = {
                    textDecoration: 'none',
                    margin: 0,
                    padding: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                }

                let value = ((column.name !== undefined) && (this.props.row[column.name] !== undefined)) ? this.props.row[column.name] : '';

                let typographyProps = {
                    color: column.color,
                    sx: { p: 1 }
                }

                let cellDisplay: React.ReactNode = <Typography {...typographyProps}>error</Typography>

                /// EDIT COMPONENTS:
                let edit = (this.props.allowEdit || this.props.editMode) && rowExtended._datagridrowEditMode

                // override cant edit.
                if (column.editable === false) { edit = false; }
                if (column.editable === true) { edit = true; }

                if (edit) {
                    // DEFAULT TO TEXT FIELD
                    cellDisplay = <TextField
                        hiddenLabel
                        value={value}
                        size="small"
                        type={column.type}
                        // variant={valueChanged? "outlined" : "filled"}
                        // style={{ backgroundColor: 'red'}}
                        onChange={(event) => {
                            this.handleCellChange(column, event.target.value);
                        }}
                        fullWidth
                        inputProps={{
                            sx: {
                                borderRadius: 0,
                                textAlign: align,
                                // color: valueChanged ? "primary.main" : undefined,
                                p: 1
                            },
                            style: { borderRadius: 0 }
                        }}
                        InputProps={{ style: { borderRadius: 0 } }}
                    />

                    if (column.type === 'enum' && column.values) {
                        cellDisplay = <Select
                            className='datagrid_enum_select'
                            value={value === "" ? "_placeholder" : value}
                            sx={{ position: 'relative' }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            } as any}
                            size="small"
                            onChange={(event) => {
                                this.handleCellChange(column, event.target.value);
                            }}
                            fullWidth
                            style={{ marginTop: 0, borderRadius: 0, }}
                            inputProps={{
                                sx: {
                                    borderRadius: 0,
                                    p: 1,
                                    textAlign: align,
                                },
                                style: { borderRadius: 0, padding: 8 }
                            }}
                        >
                            <MenuItem dense autoFocus key="_placeholder1" disabled defaultValue="_placeholder" value="_placeholder"><em>{column.placeholder}</em></MenuItem>
                            {column.values.map((option, index) => <MenuItem id={`datagrid_enum_select_menuitem_${index}`} className='datagrid_enum_select_menuitem' dense key={option.id} value={option.id}>{option.value}</MenuItem>)}
                        </Select>
                    }

                    if (column.type === 'boolean') {
                        cellDisplay = <><Switch checked={!!value} value={!!value}
                            onChange={(e) => {
                                let newVal = !value;
                                console.log(newVal)
                                this.handleCellChange(column, newVal)
                            }}
                        />{value}</>
                    }
                } else {



                    // if its an enum type we lookup the value
                    if (column.type === 'enum' && column.values) {
                        // do lookup
                        let values = column.values.filter(v => v.id === value);
                        if (values.length > 0) {
                            value = values[0].value;
                        } else {
                            value = `enum missing ${value}`
                        }

                    }

                    
                    // if (["number", "string"].indexOf(typeof value) == -1) { console.log(typeof value, value); }

                    let textdisplay = value;
                    if (typeof value === "boolean") textdisplay = JSON.stringify(value);

                    cellDisplay = <Typography {...typographyProps}>{textdisplay}</Typography>

                    if (column.renderCell) {
                        cellDisplay = column.renderCell({ row: this.props.row, column, value });
                        if (typeof cellDisplay === 'string' || typeof cellDisplay === 'number') cellDisplay = <Typography sx={{ p: 1, pr: 2, pl: 2 }}>{cellDisplay}</Typography>
                    }



                    if (column.type === 'boolean') {
                        cellDisplay = <Switch checked={!!value} value={value} defaultChecked />
                    }

                }

                let columnNameCleaned: string = index.toString();                
                if (column?.name) columnNameCleaned = (column.name as string).split(" ").join("_").toLowerCase();

                return <TableCell
                    id={`data_grid_row_${this.props.index}_cell_${columnNameCleaned}`}
                    className="data_grid_cell"
                    key={index}
                    align={align}
                    style={style as TableCellProps["style"]}
                >
                    {this.renderCell(this.props, column, cellDisplay, value)}
                </TableCell>


            })}

            {editrow && <TableCell align="right" sx={{ textDecoration: 'none' }} width={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>


                    {this.props.allowDelete && <ModalWrapper
                        dialog={{
                            title: `Are you sure you want to delete?`,
                        }}
                        theme={this.state.theme}
                        color="error"
                        icon={CloseTwoTone}
                        skipConfirm={(rowExtended._datagridrowBefore === undefined)}
                        onConfirm={async () => {
                            console.log(rowExtended);
                            let row = rowExtended._datagridrowBefore || _.clone(rowExtended);
                            this.props.onDelete({
                                row: row as IExtendedRow<T>,
                                parent: true
                            })
                        }}
                        tooltipTitle="Delete" />}


                    {/* SUCCESS */}
                    {(rowExtended._datagridResult?.isSuccessful === true && rowExtended._datagridResult.message) && <Tooltip
                        placement="top"
                        title={rowExtended._datagridResult?.message || 'Success'}>
                        <ErrorSharp color="success" sx={{ ml: 1 }} />
                    </Tooltip>}

                    {/* ERROR */}
                    {(rowExtended._datagridResult?.isSuccessful === false && rowExtended._datagridResult?.exceptionMessage) && <Tooltip
                        placement="top"
                        title={rowExtended._datagridResult.exceptionMessage}>
                        <ErrorSharp color="error" sx={{ ml: 1 }} />
                    </Tooltip>}
                </Box>
            </TableCell>}
        </TableRow>
    }

}