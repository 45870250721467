import * as signalR from '@microsoft/signalr'
import { EventEmitter } from 'events';

import { BatchProcessingStatus } from './interfaces';

declare interface BouMaticEvents extends EventEmitter {
    on(event: "batchState", listener: (status: BatchProcessingStatus) => void): this;
    on(event: string, listener: Function): this; 
  }

export class APIBouMaticChemistAppSignalR {
    public data: any;
    public hubConnection?: signalR.HubConnection;
    private baseUrl: string = '/signalR/notifyHub';

    events: BouMaticEvents = new EventEmitter();

    constructor() {

        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.baseUrl, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .build();

        this.hubConnection.serverTimeoutInMilliseconds = 600000;
        this.hubConnection
            .start()
            .then(() => console.log('System Mode Connection started'))
            .catch((err) => console.log('Error while starting connection: ' + err));

        this.hubConnection.onclose(() => {
            setTimeout(() => {
                if (this.hubConnection) {
                    this.hubConnection.start();
                }
            }, 1000); // Restart connection after 1 second.
        });

        this.hubConnection.on('onBatchStepUpdate', (status:BatchProcessingStatus) => {
            this.events.emit('onBatchStepUpdate', status);
        })

        /*
        this.hubConnection.on('setSystemMode', (mode: AppMode) => {
            console.log('SIGNALR AppMode', mode);
        });

        this.hubConnection.on('formulaUpdated', (formula: Formula) => {
            console.log('SIGNALR formulaUpdated', formula);
        });

        
        */

        this.hubConnection.on('sendMessage', (status: BatchProcessingStatus) => {
            this.events.emit('batchState', status);

            console.log(
                'SIGNALR Batch Status Received',
                status.message + ': ' + status.batchState.toString()
            );
        });
    }


    // public addSystemModeListener = (cb: (mode: AppMode) => void): void => {
    //     this.hubConnection.on('setSystemMode', cb);
    // }

    // public addFormulaUpdated(cb: (Formula: Formula) => void): void {
    //     this.hubConnection.on('formulaUpdated', cb);
    // }
}