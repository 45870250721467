import React from "react";

import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import { AddTwoTone, CloudUploadTwoTone, SaveTwoTone } from "@mui/icons-material";
import Alert from "@mui/material/Alert";

import * as _ from "lodash";
import { v4 as uuidv4 } from 'uuid';

import { DataGridRow } from './dataGridRow'

import {
    IAddRowOptions,
    IDataGrid,
    IDataGridChangeCallback,
    IDataGridChangeResult,
    IDataGridDeleteCallback,
    IExtendedRow
} from "./interfaces";

import { ColumnFilters, IColumnFilterState } from "./dataGridColumnFilters";

interface DataGridState<T> {
    rows?: IExtendedRow<T>[]
    fields?: IColumnFilterState<T>["fields"]
    editMode?: boolean
}


export class DataGrid<T> extends React.Component<IDataGrid<T>, DataGridState<T>> {
    state: DataGridState<T> = {}

    lastPropsRows: T[] | undefined | null;

    /* Adds a unique guid to each row so we can keep track of them */
    addId = (inp: T): IExtendedRow<T> => {
        let i = inp as IExtendedRow<T>;
        if (!i["_datagridrowId"]) { i["_datagridrowId"] = uuidv4(); }
        
        return i as IExtendedRow<T>;
    }

    componentDidMount = () => {
        if (this.props.rows) {
            this.setState({
                rows: this.props.rows.map(this.addId)
            });
        }
    }

    componentDidUpdate = () => {

        if (JSON.stringify(this.props.rows) !== JSON.stringify(this.lastPropsRows)) {
            this.lastPropsRows = _.clone(this.props.rows);
            let rows = this.props.rows;
            if (rows) { rows = rows.map(this.addId) }
            this.setState({ rows: rows as IExtendedRow<T>[] | undefined });
        }

    }

    /** newRow prop is incase we already know some of the content for the new row. */
    addRow = async (options?: IAddRowOptions) => {
        let rows = this.state.rows || [];

        let rowToAdd = (this.props.onNewRow)
            ? await this.props.onNewRow({ rows: this.state.rows, options })
            : {} as IExtendedRow<T>;

        rowToAdd._datagridrowNew = true;
        rowToAdd = this.addId(rowToAdd);
        rowToAdd._datagridrowEditMode = true; // enable editmode
        // rowToAdd._datagridrowBefore = {} as T;

        rows.unshift(rowToAdd);
        this.setState({ rows });
    }

    cleanUpRow = (row: IExtendedRow<T>) => {
        let cleanrow = _.clone(row)
        delete cleanrow._datagridResult;
        delete cleanrow._datagridrowBefore;
        delete cleanrow._datagridrowEditMode;
        // delete cleanrow._datagridrowId;
        delete cleanrow._datagridrowNew;
        return cleanrow;
    }

    onChange: IDataGridChangeCallback<T> = async (props) => {
        // propagate to parent
        let result: IDataGridChangeResult<T> | void = { isSuccessful: true };



        if (props.parent && this.props.onChange) {

            let cleanProps = {
                row: _.clone(props.row),
                rowBefore: props.rowBefore,
                column: _.clone(props.column),
                event: props.event,
                parent: props.parent
            }

            delete cleanProps.row._datagridResult;
            delete cleanProps.row._datagridrowBefore;
            delete cleanProps.row._datagridrowEditMode;
            // delete cleanProps.row._datagridrowId;
            delete cleanProps.row._datagridrowNew;

            result = await this.props.onChange(cleanProps);
        }

        // update state                                                               
        let rows = this.state.rows || [];

        rows = rows.map(i => {
            if (i._datagridrowId === props.row._datagridrowId) {
                let newRow = _.clone(props.row);
                if (result) { newRow._datagridResult = result; }
                return newRow;
            }
            return i;
        })


        this.setState({ rows });

        if (this.props.onEdit) {

            let cleanProps: any = {
                row: this.cleanUpRow(props.row),
                rows: rows.map(r => this.cleanUpRow(r)),
                rowBefore: props.rowBefore,
                column: _.clone(props.column),
                event: props.event,
                parent: props.parent
            }

            result = await this.props.onEdit(cleanProps);
        }

        return result;
    }

    onDelete: IDataGridDeleteCallback<T> = async (props) => {

        let { parent, row } = props;

        console.log('data grid delete', row);
        let response: IDataGridChangeResult<T> | void;

        // propagate to parent component usually to do api call to server
        if (row._datagridrowBefore || row._datagridrowNew !== true) {
            if (this.props.onDelete && parent !== false) {
                response = await this.props.onDelete(props);
                if (!response) return { isSuccessful: false, exceptionMessage: 'request failed.' }
                if (!response.isSuccessful) return response;
            }
        }

        let rows = this.state.rows || [];
        rows = rows.filter(r => (r._datagridrowId !== row._datagridrowId));
        this.setState({ rows });
        return { isSuccessful: true, message: (response && response.message) ? response.message : 'row removed' }
    }

    rowFilter = (row: T): boolean => {
        if (this.state.fields) {

            // check row if it matches any of the fields.
            let matchCount = 0;
            let toMatch = 0;

            // search column fields
            Object.entries(this.state.fields).forEach((props) => {
                let [key, value] = props as [key: keyof T, value: any]
                if (value === "") return;

                if (row[key] !== undefined && value !== undefined) {
                    toMatch++;

                    let rowValue = JSON.stringify(row[key]).toLowerCase();
                    let matchValue = value.toLowerCase();

                    if (rowValue.indexOf(matchValue) >= 0) {
                        matchCount++;
                    }
                }
            })

            return (toMatch === matchCount);
        }
        return true;
    }

    rowSorter = (rowA: T, rowB: T): number => {

        let sortDir = 1; // default

        if (this.props.sortDirection === 'DESC') {
            sortDir *= -1; // flip;
        }

        if (this.props.sortBy) {
            return (rowA[this.props.sortBy] > rowB[this.props.sortBy]) ? sortDir : -sortDir
        }

        return sortDir;
    }

    saveAll = async () => {
        if (!this.props.onChange) return;
        let saveList = this.state.rows?.filter(r => r._datagridrowBefore).map(r => {
            if (this.props.onChange && r._datagridrowBefore) {
                let newRow = _.clone(r);
                delete newRow._datagridrowBefore;
                delete newRow._datagridrowEditMode;
                return this.props.onChange({ row: newRow, column: "bulksave" as any, rowBefore: r._datagridrowBefore });
            }
            return undefined;
        }).filter(f => f !== undefined)

        if (!saveList) return;

        const result = await Promise.all(saveList.filter(i => i !== undefined))

        // success?
        let rows = this.state.rows?.map(r => {
            delete r._datagridrowBefore;
            return r;
        })

        this.setState({ rows });

        return result;
    }

    render() {
        let columns = this.props.columns;

        let tableHeaderCellStyle: React.CSSProperties = {
            padding: (this.props.showHeadings === false) ? 0 : undefined,
            border: (this.props.showHeadings === false) ? 'none' : undefined,
            whiteSpace: 'nowrap'
        }



        // const AddRowButton = <TableCell style={tableHeaderCellStyle} sx={{ m: 0, p: 0 }}>
        //     <Button fullWidth
        //         onClick={this.addRow}
        //         color="secondary"
        //         variant="outlined"
        //         sx={{ borderRadius: 0 }}
        //         startIcon={<AddTwoTone />}>
        //         {this.props.addNewButtonText || 'ADD NEW'}
        //     </Button>
        // </TableCell>

        // going to use anothe column or header?
        let showTopRowActionsText = (this.props.allowEdit || this.props.editMode);
        if (this.props.allowDelete === false && this.props.editMode) { showTopRowActionsText = false; }

        let showTopAddRowButton = this.props.allowAddRow;

        // editmode
        let rows = this.state.rows;
        if (this.props.editMode && this.state.rows) {
            rows = this.state.rows?.map((row) => {
                let newRow = _.clone(row);
                // if (!newRow._datagridrowBefore) {
                //     newRow._datagridrowBefore = _.clone(row);
                // }                
                newRow._datagridrowEditMode = this.props.editMode
                return newRow;
            })
        }

        let id = undefined;

        if (this.props.title) id = `data_grid_${this.props.title?.toLowerCase().split(' ').join('_')}`;
        if (this.props.id) id = this.props.id;

        return <>
            <TableContainer
                id={id}
                style={{
                    overflow: "hidden",
                    overflowY: "auto",
                    flex: 1,
                    height: this.props.height
                }}

                sx={this.props.sx}
            >
                <Table aria-label="simple table" stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={99} sx={{ m: 0, p: 0, border: 'none', whiteSpace: 'nowrap' }} >
                                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography variant="h6" color="secondary" sx={{ p: 0.5, pl: 1, m: 0 }}>{this.props.title}</Typography>

                                    <Box sx={{ flex: 1 }} />

                                    {(this.props.allowImport && <Button
                                        onClick={() => { }}
                                        color="secondary"
                                        variant="text"
                                        component="label"
                                        startIcon={<CloudUploadTwoTone />}>IMPORT <input accept="text/*" type="file" onChange={async (e) => {
                                            const result = await e.target.files?.[0].text();
                                            console.log(result)
                                            if (!result) return;

                                            // split into lines                                                                                       
                                            let items = result?.split('\n');
                                            if (!items) return;
                                            for (const line of items) {
                                                await this.addRow({ line });
                                            }

                                        }} hidden /></Button>)}

                                    {this.props.showSaveAllButton && <Button
                                        onClick={() => { this.saveAll() }}
                                        color="secondary"
                                        variant="text"
                                        startIcon={<SaveTwoTone />}
                                    >
                                        SAVE
                                    </Button>}

                                    {(showTopAddRowButton) && <Button
                                        id="datagrid_add_row_button"
                                        onClick={() => { this.addRow() }}
                                        color="secondary"
                                        variant="text"
                                        startIcon={<AddTwoTone />}>{this.props.addNewButtonText || 'ADD NEW'}</Button>
                                    }

                                </Box>
                            </TableCell>
                        </TableRow>

                        <TableRow color="primary" id="data_grid_header">
                            {columns.map((column, index) => (
                                <TableCell
                                    className="data_grid_header_cell"
                                    // @ts-ignore
                                    key={index}
                                    align={column.align || (index === 0 ? "left" : "right")}
                                    style={tableHeaderCellStyle}
                                    width={column.width}
                                >
                                    {(this.props.showHeadings !== false) && column.title}
                                </TableCell>
                            ))}

                            {(showTopRowActionsText) && <TableCell align="right" style={tableHeaderCellStyle} width={0} />}

                            {/* {ActionTableHeader} */}


                        </TableRow>

                        {this.props.allowSearch && <TableRow color="primary">
                            <ColumnFilters<T>
                                columns={columns}
                                style={tableHeaderCellStyle}
                                onChange={(fields) => {
                                    this.setState({ fields });
                                }}
                            />
                            {(showTopRowActionsText) && <TableCell align="right" style={tableHeaderCellStyle} width={0} />}
                            {/* {this.props.allowAddRow && <AddRowButton
                                onClick={this.addRow}
                                title={this.props.addNewButtonText} />} */}
                        </TableRow>}
                    </TableHead>

                    <TableBody>
                        {rows === undefined && <TableRow>
                            <TableCell colSpan={columns.length + 1}><LinearProgress sx={{ width: '100%' }} /></TableCell>
                        </TableRow>}

                        {(rows === null || rows?.length === 0) && <TableRow>
                            <TableCell colSpan={columns.length + 1}>
                                <Alert severity="info" sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                                    {this.props.emptyMessage || "EMPTY"}
                                </Alert>
                            </TableCell>
                        </TableRow>}

                        {(rows === undefined) && this.generatePlaceholderRows(this.props.pageSize || 1)}

                        {rows?.filter(this.rowFilter).sort(this.rowSorter).map((row, index) => <DataGridRow<T>
                            // @ts-ignore
                            key={row["_datagridrowId"] + index}
                            index={index}
                            allowEdit={this.props.allowEdit}
                            editMode={this.props.editMode}
                            allowDelete={this.props.allowDelete}
                            columns={this.props.columns}
                            row={row}
                            // highlightRow={this.props.highlightRow}
                            selectedRow={this.props.selectedRow}
                            onChange={this.onChange}
                            to={this.props.to}
                            onDelete={this.onDelete}
                        />)}
                    </TableBody>
                </Table>
            </TableContainer>

            {this.props.showFooter &&
                <Paper elevation={0} sx={{ p: 1, pl: 2 }}>
                    <Typography variant="caption" sx={{ opacity: 0.5 }}>
                        {this.footerText(rows)}
                    </Typography>
                </Paper>
            }
        </>
    }



    footerText = (rows?: T[]) => {
        if (!rows) return 'LOADING...'
        if (rows) return `${rows.length} ROWS`

        return "ERROR"
    }

    generatePlaceholderRows = (pageSize: number) => {
        // calculate if we use another column for actions
        let showTopRowActionsText = (this.props.allowEdit && (!this.props.allowAddRow || this.props.allowSearch));
        let showTopAddRowButton = (this.props.allowAddRow && !this.props.allowSearch);
        let extracolumn = (showTopRowActionsText || showTopAddRowButton);

        return _.range(pageSize).map(i => <TableRow key={i}>
            {this.props.columns.map((column, index) => (<TableCell key={index}><Skeleton /></TableCell>))}


            {extracolumn && <TableCell key={"actions"}><Skeleton /></TableCell>}
        </TableRow>)
    }
}



