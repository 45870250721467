import React from "react";
import { clone } from "lodash";

import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/system/Box";
import InputLabel from "@mui/material/InputLabel";
import {FormControl, IconButton} from "@mui/material";

// icons
import {
    RefreshTwoTone as ResetIcon,
    AssignmentTwoTone as GenerateReportIcon,
    Assignment, Print
} from "@mui/icons-material";

import { boumaticApi } from "../../App";
import { PagedRequest, BatchAuditLog } from "../../api/interfaces";
import { Await } from 'components';

import { getDaysFromNow, getFirstDayOfMonth, DataGrid } from 'components';
import {Link} from "react-router-dom";


interface State {
    searchArgs: PagedRequest;

    productionLogResponse?: Await<ReturnType<
        typeof boumaticApi.api.Reports.GetProductionLog>>;

    getFormulasResponse?: Await<ReturnType<
        typeof boumaticApi.api.Formulas.GetFormulas>>;

    mixingTanks?: Await<ReturnType<
        typeof boumaticApi.api.MixingTanks.GetMixingTanks>>;

    getChemicalsResponse?: Await<ReturnType<typeof boumaticApi.api.Chemicals.GetChemicals>>;

    batchAuditLogResponse?: Await<ReturnType<typeof boumaticApi.api.AuditTrails.GetBatchAuditLog>>;

    allUsers?: Await<ReturnType<typeof boumaticApi.api.AuditTrails.GetAllUsers>>;

    formulaId?: string
    startDate?: string
    endDate?: string
    tankId?: string
    filterByCreatedByUserEmail?: string
    filterChemicalId?: string
}

const defaultSearchArgs: PagedRequest = {
    PageNumber: 1,
    PageSize: 999
}
interface IBatchProps {
    activeBatchId?: string;
}

export class BatchAuditLogList extends React.Component<IBatchProps, State> {
    state: State = {
        searchArgs: clone(defaultSearchArgs),
        productionLogResponse: undefined,
        mixingTanks: undefined,
        formulaId: undefined,
        startDate: getFirstDayOfMonth(-3).toISOString(),
        endDate: getDaysFromNow(1).toISOString(),
    };


    componentDidMount = async () => {
        this.getProductionLog();
        this.getData();
        this.getBatchAuditLog();
    };

    getData = async () => {
        const [mixingTanks, getFormulasResponse, getChemicalsResponse, allUsers] = await Promise.all([
            boumaticApi.api.MixingTanks.GetMixingTanks(),
            boumaticApi.api.Formulas.GetFormulas({
                includeInactiveFormulas: true,
            }),
            boumaticApi.api.Chemicals.GetChemicals(),
            boumaticApi.api.AuditTrails.GetAllUsers()
        ])
        this.setState({ mixingTanks, getFormulasResponse, getChemicalsResponse, allUsers });
    }

    getBatchAuditLog = async () => {
        this.setState({ batchAuditLogResponse: undefined });
        const batchAuditLogResponse = await boumaticApi.api.AuditTrails.GetBatchAuditLog({
            "formulaId": this.state.formulaId || null,
            "chemicalId": this.state.filterChemicalId || null,
            "mixingTankId": this.state.tankId || null,
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
            "tableName": "Batch",
            "pageSize": 100,
            "pageNumber": 1,
            "userId": this.state.filterByCreatedByUserEmail || null
        })

        this.setState({ batchAuditLogResponse });
    }


    getProductionLog = async () => {
        this.setState({ productionLogResponse: undefined });

        let searchArgs = this.state.searchArgs;

        let WhereQuery = `batch =>  batch.timeStamp >= "${this.state.startDate}" AND batch.timeStamp <= "${this.state.endDate}"`;

        if (this.state.tankId) {
            WhereQuery += ` AND batch.MixingTankId.Equals("${this.state.tankId}")`;
        }

        if (this.state.formulaId) {
            WhereQuery += ` AND batch.FormulaId.Equals("${this.state.formulaId}")`;
        }

        WhereQuery += ` AND batch.BatchState == "8"`; // done?


        searchArgs.WhereQuery = WhereQuery;


        const productionLogResponse = await boumaticApi.api.Reports.GetProductionLog(this.state.searchArgs);

        this.setState({ productionLogResponse });

        // /api/AuditTrails/GetAuditTrails?PageNumber=1&PageSize=10&WhereQuery=audit%20=%3E%20audit.ReferenceId.Equals(%222a938c20-d1bc-4511-b98f-fff1ce3dcd6a%22)

        /*
        WhereQuery = 'audit => audit.ReferenceId.Equals("2a938c20-d1bc-4511-b98f-fff1ce3dcd6a")';
        */

    };

    render() {


        return (
            <Paper sx={{ display: "flex", flexGrow: 1, flexDirection: "row", p: 2 }}>
                <Paper style={{ flex: 1 }} elevation={3}>
                    <AppBar position="static" color="primary" enableColorOnDark>
                        <Toolbar variant="dense" color={"primary"}>
                            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                                Batch Audit Log
                            </Typography>

                            <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                startIcon={<ResetIcon />}
                                sx={{ mr: 2 }}
                                onClick={async () => {
                                    await this.setState({
                                        startDate: getFirstDayOfMonth(-3).toISOString(),
                                        endDate: getDaysFromNow(1).toISOString(),
                                        formulaId: undefined,
                                        tankId: undefined,
                                        filterChemicalId: undefined,
                                        searchArgs: clone(defaultSearchArgs),
                                        filterByCreatedByUserEmail: undefined
                                    });
                                    this.getBatchAuditLog();
                                }}
                            >
                                Reset
                            </Button>

                            <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                startIcon={<GenerateReportIcon />}
                                sx={{ mr: 2 }}
                                onClick={async () => {
                                    this.getBatchAuditLog();
                                }}
                            >
                                Generate Report
                            </Button>

                            {/* <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                startIcon={<ExportReportIcon />}
                                sx={{ mr: 2 }}
                            >
                                Export Report
                            </Button> */}
                        </Toolbar>
                    </AppBar>

                    {/* TOP OPTIONS BOX */}
                    <Paper sx={{ m: 1 }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Box sx={{ m: 1, flex: 1 }} id="batchauditlog_startdate_cal">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Batch Created Start Date"
                                        inputFormat="yyyy/MM/dd"
                                        value={this.state.startDate}
                                        onChange={async (newStartDate) => {
                                            if (!newStartDate) return;
                                            await this.setState({ startDate: new Date(newStartDate).toISOString() });
                                            this.getBatchAuditLog();
                                        }}
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>

                            <Box sx={{ m: 1, flex: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Batch Created End Date"
                                        inputFormat="yyyy/MM/dd"
                                        value={this.state.endDate}
                                        onChange={async (newEndDate) => {
                                            if (!newEndDate) return;
                                            await this.setState({ endDate: new Date(newEndDate).toISOString() });
                                            this.getBatchAuditLog();
                                        }}
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>

                            <Box sx={{ m: 1, flex: 1 }}>
                                <FormControl fullWidth>
                                    <InputLabel  id="select-formula">
                                        Select Formula
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="select-formula"
                                        value={this.state.formulaId || ''}
                                        label="Select Formula"
                                        onChange={async (e) => {
                                            await this.setState({ formulaId: e.target.value });
                                            this.getBatchAuditLog();
                                        }}
                                        fullWidth
                                    >
                                        {this.state.getFormulasResponse?.data?.map((f) => (
                                            <MenuItem value={f.id} key={f.id}>
                                                {f.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'row' }}>
                            <Box sx={{ m: 1, flex: 1 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-mixing-tank">
                                        Select MixingTank
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="select-mixing-tank"
                                        value={this.state.tankId || ''}
                                        label="Select MixingTank"
                                        onChange={async (e) => {
                                            await this.setState({ tankId: e.target.value });
                                            this.getBatchAuditLog();
                                        }}
                                        fullWidth
                                    >
                                        {this.state.mixingTanks?.data?.map((tank) => (
                                            <MenuItem value={tank.id} key={tank.id}>
                                                Tank No {tank.tankNo} (capacity: {tank.capacityVolume})
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ m: 1, flex: 1 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-chemical">
                                        Select Chemical
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="select-chemical"
                                        value={this.state.filterChemicalId || ''}
                                        label="Select Chemical"
                                        onChange={async (e) => {
                                            await this.setState({ filterChemicalId: e.target.value });
                                            this.getBatchAuditLog();
                                        }}
                                        fullWidth
                                    >
                                        {this.state.getChemicalsResponse?.data?.sort((a, b) => ((a.name || '') > (b.name || '')) ? 1 : -1).map((chem) => (
                                            <MenuItem value={chem.id} key={chem.id}>
                                                {chem.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ m: 1, flex: 1 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-created-by">
                                        Created by:
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="select-created-by"
                                        value={this.state.filterByCreatedByUserEmail || ''}
                                        label="Created by:"
                                        onChange={async (e) => {
                                            await this.setState({ filterByCreatedByUserEmail: e.target.value });
                                            this.getBatchAuditLog();
                                        }}
                                        fullWidth
                                    >
                                        {this.state.allUsers?.data?.filter(u => (u.email)).map((user) => (
                                            <MenuItem value={user.email} key={user.email}>
                                                {user.email ? user.email : 'null'}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Paper>

                    {/* START AUDIT DATA TABLE */}
                    <DataGrid<BatchAuditLog>
                        columns={[{
                            name: "lotNumber",
                            title: "LOT NUMBER"
                        }, {
                            name: "formulaName",
                            title: "FORMULA",
                            align: "left"
                        }, {
                            name: "mixingTankNo",
                            title: "TANK ID"
                        }, {
                            name: "weight",
                            title: "WEIGHT"
                        }, {
                            name: "batchState",
                            title: "BATCHSTATE"
                        }, {
                            name: "createdBy",
                            title: "CREATED BY"
                        },

                        {
                            width: 45,
                            align: "center",
                            title: "PRINT",
                            renderCell: (props) => <Tooltip placement="top" title="Print">
                                <IconButton component={Link} to={`/batch-audit-log-report/${this.state.startDate}/${this.state.endDate}/${props.row.batchId}`}>
                                    <Print  />
                                </IconButton>
                            </Tooltip>
                        },
                        {
                            title: "VIEW",
                            width: 45,
                            align: "center",
                            renderCell: (props) => <Tooltip placement="top" title="View tickets">
                                <IconButton component={Link} to={`/coa-report/${props.row.lotNumber}`}>
                                    <Assignment  />
                                </IconButton>
                            </Tooltip>
                        }]}
                        rows={this.state.batchAuditLogResponse?.data}
                        // highlightRow={(props => props.row.id === this.props.activeid)}
                        selectedRow={props => props.batchId === this.props.activeBatchId}
                        to={(props) => `/admin/audittrailloglist/${props.row.batchId}`}
                    />

                    {/* On click show audit trail? */}

                </Paper>
            </Paper>
        );
    }
}
