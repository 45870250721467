import { getDecodedToken } from "./tokenParse";

export function checkAdmin(): boolean {
    let admin = false;
    let account = getDecodedToken();
    if (account) {
      if (account.role.indexOf("ACCOUNT_OWNER") >= 0) admin = true;
      if (account.role.indexOf("Administrator") >= 0) admin = true;
      if (account.role === "ADMINISTRATOR") admin = true;
    }
    return admin;
}