import React from "react";
import Typography from "@mui/material/Typography";

import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import moment from "moment";


// icons
import {
    RefreshTwoTone as ResetIcon,
    AssignmentTwoTone as GenerateReportIcon,
    AssignmentReturnedTwoTone as ExportReportIcon
} from "@mui/icons-material";

//api
import { boumaticApi } from "../../App";
import { ProductionLog, PagedRequest } from "../../api/interfaces";
import {Await, getFirstDayOfMonth, DataGrid, exportToXLSX} from 'components';

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/system/Box";
import InputLabel from "@mui/material/InputLabel";
import { FormControl } from "@mui/material";
import { clone } from "lodash";

interface State {
    searchArgs: PagedRequest;

    productionLogResponse?: Await<ReturnType<
        typeof boumaticApi.api.Reports.GetProductionLog>>;

    getFormulasResponse?: Await<ReturnType<
        typeof boumaticApi.api.Formulas.GetFormulas>>;

    mixingTanks?: Await<ReturnType<
        typeof boumaticApi.api.MixingTanks.GetMixingTanks>>;

    formulaId?: string
    startDate?: string
    endDate?: string
    tankId?: string
    canExport: boolean
}

const defaultSearchArgs: PagedRequest = {
    PageNumber: 1,
    PageSize: 999
}

export class ProductionLogList extends React.Component<{}, State> {
    state: State = {
        searchArgs: clone(defaultSearchArgs),
        productionLogResponse: undefined,
        mixingTanks: undefined,
        formulaId: undefined,
        canExport: true,
        startDate: getFirstDayOfMonth(-12).toISOString(),
        endDate: new Date().toISOString(),
    };

    componentDidMount = async () => {
        this.getFormulas();
        this.getProductionLog();
        this.getMixingTanks();
    };

    getMixingTanks = async () => {
        const mixingTanks = await boumaticApi.api.MixingTanks.GetMixingTanks();
        this.setState({ mixingTanks });
    }

    getFormulas = async () => {
        const getFormulasResponse = await boumaticApi.api.Formulas.GetFormulas({
            includeInactiveFormulas: true,
        });
        this.setState({ getFormulasResponse });
    };

    exportReport = async () => {

        const getProductionLog = await boumaticApi.api.Reports.GetProductionLog(this.state.searchArgs);
        if (getProductionLog.isSuccessful && getProductionLog.data) {
            let productionReportLogs = getProductionLog.data;
            if (productionReportLogs.length > 0) {
                this.setState({canExport: true});
                const data = productionReportLogs.map((p) => {
                    const mapped = {
                        FormulaName: p.formulaName,
                        FormulaSku: p.formulaSku,
                        BatchSizeInGallons: p.batchSizeInGallons.toFixed(2),
                        BatchSizeInPounds: p.batchSizeInPounds.toFixed(2),
                        DateMade: moment(p.dateMade).format('DD-MMM-YYYY'),
                        LotNumber: p.lotNumber,
                        TankNo: p.tankNo,
                        Yield: p.yield,
                    };

                    return mapped;
                });

                exportToXLSX(data, 'production_log');
            } else {
                this.setState({canExport: false});
            }
        }

    }


    getProductionLog = async () => {
        this.setState({ productionLogResponse: undefined });

        let searchArgs = this.state.searchArgs;
        // let startDate = '2021-10-01T13:47';
        // let endDate = '2021-11-19T13:47';

        let WhereQuery = `batch => batch.timeStamp >= "${this.state.startDate}" AND batch.timeStamp <= "${this.state.endDate}"`;

        if (this.state.tankId) {
            WhereQuery += ` AND batch.MixingTankId.Equals("${this.state.tankId}")`;
        }

        if (this.state.formulaId) {
            WhereQuery += ` AND batch.FormulaId.Equals("${this.state.formulaId}")`;
        }

        WhereQuery += ` AND batch.BatchState == "8"`; // done?


        searchArgs.WhereQuery = WhereQuery;


        const productionLogResponse =
            await boumaticApi.api.Reports.GetProductionLog(this.state.searchArgs);
        this.setState({ productionLogResponse });

        // /api/AuditTrails/GetAuditTrails?PageNumber=1&PageSize=10&WhereQuery=audit%20=%3E%20audit.ReferenceId.Equals(%222a938c20-d1bc-4511-b98f-fff1ce3dcd6a%22)

        /*
        WhereQuery = 'audit => audit.ReferenceId.Equals("2a938c20-d1bc-4511-b98f-fff1ce3dcd6a")';
        */

    };

    render() {


        return (
            <Paper sx={{ display: "flex", flexGrow: 1, flexDirection: "row", p: 2 }}>
                <Paper style={{ flex: 1 }} elevation={3}>
                    <AppBar position="static" color="primary" enableColorOnDark>
                        <Toolbar variant="dense" color={"primary"}>
                            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                                Production Log
                            </Typography>

                            <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                startIcon={<ResetIcon />}
                                sx={{ mr: 2 }}
                                onClick={async () => {
                                    await this.setState({ searchArgs: clone(defaultSearchArgs) });
                                    this.getProductionLog();
                                }}
                            >
                                Reset
                            </Button>

                            {/*<Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                id={'production_log_generate_report'}
                                startIcon={<GenerateReportIcon />}
                                sx={{ mr: 2 }}
                            >
                                Generate Report
                            </Button>*/}

                            <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                id={'production_log_download_report'}
                                startIcon={<ExportReportIcon />}
                                sx={{ mr: 2 }}
                                onClick={() => this.exportReport()}
                                disabled={!this.state.canExport}
                            >
                                Export Report
                            </Button>
                        </Toolbar>
                    </AppBar>

                    {/* TOP OPTIONS BOX */}
                    <Paper sx={{ m: 1 }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Box sx={{ m: 1, flex: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Start Date"
                                        inputFormat="yyyy/MM/dd"
                                        value={this.state.startDate}
                                        onChange={(newStartDate) => {
                                            if (!newStartDate) return;
                                            this.setState({ startDate: new Date(newStartDate).toISOString() });
                                            this.getProductionLog();
                                        }}
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>

                            <Box sx={{ m: 1, flex: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="End Date"
                                        inputFormat="yyyy/MM/dd"
                                        value={this.state.endDate}
                                        onChange={(newEndDate) => {
                                            if (!newEndDate) return;
                                            this.setState({ endDate: new Date(newEndDate).toISOString() });
                                            this.getProductionLog();
                                        }}
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>

                            <Box sx={{ m: 1, flex: 1 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Select Formula
                                    </InputLabel>
                                    <Select
                                        labelId="select-formula-label"
                                        id="select-formula"
                                        value={this.state.formulaId}
                                        label="Select Formula"
                                        onChange={async (e) => {
                                            await this.setState({ formulaId: e.target.value });
                                            this.getProductionLog();
                                        }}
                                        fullWidth
                                    >
                                        {this.state.getFormulasResponse?.data?.map((f) => (
                                            <MenuItem value={f.id} key={f.id}>
                                                {f.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ m: 1, flex: 1 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-mixing-tank-label">
                                        Select MixingTank
                                    </InputLabel>
                                    <Select
                                        labelId="select-mixing-tank-label"
                                        id="select-mixing-tank"
                                        value={this.state.tankId}
                                        label="Select MixingTank"
                                        onChange={async (e) => {
                                            await this.setState({ tankId: e.target.value});
                                            this.getProductionLog();
                                        }}
                                        fullWidth
                                    >
                                        {this.state.mixingTanks?.data?.map((tank) => (
                                            <MenuItem value={tank.id} key={tank.id}>
                                                Tank No {tank.tankNo} (capacity: {tank.capacityVolume})
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Paper>

                    {/* START AUDIT DATA TABLE */}
                    <DataGrid<ProductionLog>
                        columns={[
                            {
                                name: "formulaSku",
                                title: "FORMULA SKU",
                            },
                            {
                                name: "formulaName",
                                title: "FORMULA NAME",
                            },
                            {
                                name: "batchSizeInGallons",
                                title: "BATCH SIZE (GALLONS)",
                            },
                            {
                                name: "batchSizeInPounds",
                                title: "BATCH SIZE (POUNDS)",
                            },
                            {
                                name: "dateMade",
                                title: "DATE MADE",
                            },
                            {
                                name: "lotNumber",
                                title: "LOT NUMBER",
                            },
                            {
                                name: "yield",
                                title: "YIELD",
                            },
                            {
                                name: "tankNo",
                                title: "TANK",
                                renderCell: (props) => `Tank ${props.value}`
                            }
                        ]}
                        rows={this.state.productionLogResponse?.data}
                        // highlightRow={(props => props.row.id === this.props.activeid)}
                        // selectedRow={(row => row.id === this.props.activeid)}
                        //to={() => "/test"}
                        pageSize={100}
                        height={600}

                    />

                    {/* On click show audit trail? */}

                </Paper>
            </Paper>
        );
    }
}
