import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import React from "react";
import { MixingTankBatchList } from ".";
import { boumaticApi, boumaticApiSignalR } from "../../App";
import { Await } from 'components';
import { Batch } from "api/interfaces";

interface MixingTankListProps {
    /** optional. This is the selected batchId, used for the active background color on the row */
    batchId?: string
    onNewBatch: (batch: Batch) => void;
}

interface MixingTankListState {
    mixingTanks?: Await<ReturnType<typeof boumaticApi.api.MixingTanks.GetMixingTanks>>;
}

export class MixingTankList extends React.Component<MixingTankListProps> {
    state: MixingTankListState = {}

    componentDidMount = () => {
        this.getData();
        boumaticApiSignalR.events.on("batchState", this.handleBatchStateUpdate);
    }

    handleBatchStateUpdate = () => {
        // something changed..
        this.getData();
    }

    componentWillUnmount = () => {
        boumaticApiSignalR.events.removeListener("batchState", this.handleBatchStateUpdate);
    }

    getData = async () => {
        console.log('Updating mixing tanks...')
        const mixingTanks = await boumaticApi.api.MixingTanks.GetMixingTanks();
        this.setState({ mixingTanks });
    }

    render() {
        return <Box id="mixing_tank_list">
            {this.state.mixingTanks?.isSuccessful === false && (
                <Alert severity="error">
                    {this.state.mixingTanks.exceptionMessage}
                </Alert>
            )}

            {this.state.mixingTanks?.data?.filter(t => t.active ).map((tank, index) => <Box
                id={`mixing_tank_list_item_${tank.id}`}
                className="mixing_tank_list_item"
                key={tank.id}
                sx={{
                    mb: (index < (this.state.mixingTanks?.data?.length || 999) - 1) ? 3 : 0
                }}>
                <MixingTankBatchList
                    onNewBatch={this.props.onNewBatch}
                    key={tank.id}
                    tank={tank}
                    activeid={this.props.batchId}
                    tankNo={tank.tankNo}
                    mixingTankId={tank.id} /></Box>)}
        </Box>
    }
}