import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { a11yProps } from 'components';
import { Link, useLocation, Outlet } from "react-router-dom"


export function ConfigRoutes() {    
    let params = useLocation();
    let tab = params.pathname.slice("/config/".length).split("/")[0];
    const tabList = ["chemicals", "containers", "methods"];;
    let tabIndex: number | undefined = tabList.indexOf(tab)
    if (tabIndex < 0) tabIndex = 0;
    return <Paper sx={{ display: "flex", flexGrow: 1, flexDirection: "column", p: 0 }}>
        <Paper sx={{ flex: 1, borderTop: 1, borderColor: 'divider', borderRadius: 0 }}elevation={0}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} aria-label="basic tabs example">
                    <Tab label="Chemicals" {...a11yProps(0)} component={Link} to="/config/chemicals" />
                    <Tab label="Containers" {...a11yProps(1)} component={Link} to="/config/containers" />
                    <Tab label="Methods" {...a11yProps(2)} component={Link} to="/config/methods" />
                </Tabs>
            </Box>
        </Paper>
        
        <Paper sx={{m:2, p: 0}} elevation={24}>
            <Outlet />
        </Paper>
    </Paper>
}