import React from "react";

import { Alert, Button } from "@mui/material";
import { boumaticApi } from "../../App";
import { Await, DataGrid } from 'components';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { AddTwoTone as AddIcon } from "@mui/icons-material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Formula, FormulaState } from "../../api/interfaces";
import { FormulaStatus } from "./formulaStatus";
import { Link } from "react-router-dom";

interface IFormulasState {
  includeInactiveFormulas: boolean;
  formulasResponse?: Await<
    ReturnType<typeof boumaticApi.api.Formulas.GetFormulas>
  >;
}

interface IFormulasProps {
  width?: number;
  /** url path formula id so we can highlight it */
  activeid?: string;
}

interface IColumn {
  name: keyof Formula;
  title: string;
  renderCell?: (props: ICellProps) => React.ReactNode;
}

interface ICellProps {
  row: any;
  column: IColumn;
  value: any;
}

export class FormulaList extends React.Component<IFormulasProps, IFormulasState> {
  state: IFormulasState = {
    includeInactiveFormulas: true,
  };

  lastactiveid?: string;

  componentDidMount = async () => {
    this.getData();
    // setInterval(() => this.getData(), 30000); // removed.
  };

  componentDidUpdate = async () => {
    console.log('Formula List Did Update');

    // // is there a new activeid that does not exist in our list? A new Formula?
    // let matches = this.state.formulasResponse?.data?.filter(f => f.id === this.props.activeid);

    // if (!matches || matches.length !== 1) this.getData();
  }

  getData = async () => {
    this.setState({ formulasResponse: undefined });

    const formulasResponse = await boumaticApi.api.Formulas.GetFormulas({
      includeInactiveFormulas: this.state.includeInactiveFormulas,
    });


    this.setState({ formulasResponse });
  };

  render() {
    return (
      <>
        <AppBar position="static" color="primary" enableColorOnDark>
          <Toolbar variant="dense" color={"primary"}>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
              Formulas
            </Typography>

            <FormControlLabel
              control={
                <Switch
                  id="formula_list_include_inactive_switch"
                  checked={this.state.includeInactiveFormulas}
                  value={this.state.includeInactiveFormulas}
                  color="secondary"
                  onChange={() => { this.setState({ includeInactiveFormulas: !this.state.includeInactiveFormulas }); }}
                />
              }
              label="Include Inactive"
              style={{ opacity: this.state.includeInactiveFormulas ? 1 : 0.5 }}
            />

            <Button
              id="button_new_formula"
              color="secondary"
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              component={Link}
              to="/admin/formulas/new"
            >
              New Formula
            </Button>
          </Toolbar>
        </AppBar>

        {this.state.formulasResponse?.isSuccessful === false && (
          <Alert severity="error">
            {this.state.formulasResponse.exceptionMessage}
          </Alert>
        )}

        <DataGrid<Formula>
          id="datagrid_formula_list"
          allowSearch
          columns={[
            {
              name: "name",
              title: "NAME",
              align: "left",
            },
            {
              name: "state",
              title: "STATUS",
              width: 40,
              align: "center",
              renderCell: (props) => <FormulaStatus status={props.value as FormulaState} />
            },
            {
              name: "defaultVolume",
              width: 110,
              title: "SIZE",
            },
            {
              width: 110,
              name: "revision",
              title: "REV",
            },
          ]}
          rows={this.state.formulasResponse?.data?.reverse().filter(formula => (this.state.includeInactiveFormulas || formula.state === FormulaState.Approved))}
          // highlightRow={(props => props.row.id === this.props.activeid)}
          selectedRow={(row => row.id === this.props.activeid)}
          to={(props) => `/admin/formulas/${props.row.id}`}
        />
      </>
    );
  }

}
