import React from "react";
import { View, Text } from '@react-pdf/renderer'
import { IDataGridPDFColumn } from './dataGridPDF'

interface IRowPDF<T> {
    row: T,
    columns: IDataGridPDFColumn<T>[]
    verticalBorders?: boolean
}

export class DataGridPDFRow<T> extends React.Component<IRowPDF<T>> {
    render() {
        return <View style={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
        }}>
            {this.props.columns?.map((column, i) => {

                let value = ((column.name !== undefined) && (this.props.row[column.name] !== undefined)) ? this.props.row[column.name] : '';

                // if its an enum type we lookup the value
                if (column.type === 'enum' && column.values) {
                    // do lookup
                    let values = column.values.filter(v => v.id === value);
                    if (values.length > 0) {
                        value = values[0].value;
                    } else {
                        value = `enum missing ${value}`
                    }

                }
                let style = {
                    fontSize: 8,
                    fontFamily: "Roboto",
                    width: column.width,
                    borderLeft: '1px solid black',
                    padding: 3,
                    textAlign: column.align
                }

                let cellDisplay = <Text style={style} wrap={false}>
                    {value}
                </Text>

                if (column.renderCell) {
                    const renderCell = column.renderCell({ row: this.props.row, column, value });
                    if (['string', 'number'].includes(typeof renderCell)) { 
                        
                        cellDisplay = <Text style={style} wrap={false}>
                            {renderCell}
                        </Text>

                    } else {
                        cellDisplay = renderCell as any;
                    }
                }

                return cellDisplay
            })}
        </View>
    }
}