import { Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import React from 'react'
import { Batch, BatchIngredient } from '../../api/interfaces'
import { boumaticApi } from '../../App'
import { DataGrid } from 'components'
import { Await } from 'components'

interface BatchIngredientsListProps {
    batch: Batch
    batchId?: string
}

interface BatchIngredientsListState {
    batchIngredients?: Await<ReturnType<typeof boumaticApi.api.BatchIngredients
        .GetBatchIngredientByBatchId>>;
}

export class BatchIngredientsList extends React.Component<
    BatchIngredientsListProps,
    BatchIngredientsListState> {

    state: BatchIngredientsListState = {}

    lastBatchId?: string;

    componentDidMount = () => {
        this.getData();
    }

    componentDidUpdate = () => {
        if (this.lastBatchId !== this.props.batchId) {
            this.getData();
        }
    }

    getData = async () => {
        if (this.props.batchId) {
            this.setState({ batchIngredients: undefined });
            this.lastBatchId = this.props.batchId;
            const batchIngredients = await boumaticApi.api.BatchIngredients
                .GetBatchIngredientByBatchId({ batchId: this.props.batchId });

            this.setState({ batchIngredients });
        }

    }

    render() {
        return <Paper elevation={2}>
            <DataGrid<BatchIngredient>
                title="Batch Ingredients"
                columns={[{
                    name: 'stepNumber',
                    title: 'STEP NO.',
                    renderCell: (props) => <Typography sx={{ pl: 2 }} color={ (this.props.batch.nextPlcStep >= props.value) ? "success.main" : "primary"}>{props.value}</Typography>
                },
                { name: 'chemical', title: "CHEMICAL", align: "left" },
                { title: 'SKU NO.', name: 'chemicalSku' },
                {
                    title: 'ACTUAL QTY (LBS)', name: "weight", renderCell: (props) => props.value.toLocaleString(undefined, { minimumFractionDigits: 2 })
                },
                { title: 'ADDED WEIGHT (LBS)', name: "adjustmentWeight" },
                { title: "LOT NO.", name: 'lotNumber' }

                ]}
                rows={(this.state.batchIngredients?.data === null) ? [] : this.state.batchIngredients?.data}
                selectedRow={(row) => this.props.batch.nextPlcStep >= row.stepNumber}
                
            />
        </Paper>
    }
}