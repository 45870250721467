import React from "react";
import Typography from "@mui/material/Typography";
import { boumaticApi } from "../../App";
import { Container } from "../../api";
import Skeleton from "@mui/material/Skeleton";

interface IContainer {
    id?: string
}

interface IContainerState {
    container?: Container;
}

export class ContainerName extends React.Component<IContainer, IContainerState> {
    state: IContainerState = {}

    componentDidMount = async () => {
        if (this.props.id) {
            const container = await boumaticApi.api.Containers.GetContainer({id: this.props.id}).then(r => r.data);
            if (container) this.setState({container});
        }        
    }

    render() {
        if (!this.state.container) return <Skeleton />
        return <Typography>{this.state.container.name}</Typography>
    }
}