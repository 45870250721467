// Title: BouMatic Chemist App
// Auto Generated API using OpenSwag on 2021-11-12T16:28:44.164Z


import EventEmitter from 'events';
import { isJson } from './utils/isjson';
import {
    CloudServiceActionResult,
    AppMode,
    AuditTrailSearchArgs,
    BatchAuditLogListPagedResponse,
    AuditTrailResponseListPagedResponse,
    AuditTrail,
    FormulaAuditTrailListPagedResponse,
    Batch,
    BatchIngredient,
    CertificateOfAnalysis,
    Chemical,
    ICurrentConfigurationResponse,
    Container,
    Formula,
    FormulaStep,
    Instruction,
    Method,
    MixingTank,
    ProductQuantity,
    Product,
    FormulaTolerance,
    ProductionLogListPagedResponse,
    IUser,
    IAppSettings,
    PagedRequest,
    CertificateOfAnalysisRequestByFormulaId,
    BatchAuditReport,
    FillTicket,
    BatchReport
} from './interfaces';
import { ValidationResponse } from './interfaces/validation';

// create-react-app env vars not included by default in 4.0.3
// v5 not yet ready to upgrade to.

// workaround for env process vars
// https://www.npmjs.com/package/process
require('process');

let isUnitTest = (process?.env?.NODE_ENV === "test");

export class APIBouMaticChemistApp {
    authorization = '';
    baseUrl = '';
    events = new EventEmitter();

    private handleResponse = async (res: Response) => {
        if (res.status !== 200) {
            const a = await res.text();
            if (res.status === 400 && isJson(a)) {
                // validation error?
                let resvalidation: ValidationResponse = JSON.parse(a);
                if (resvalidation.title && resvalidation.errors) {
                    return {
                        isSuccessful: false,
                        exceptionMessage: `${resvalidation.title} ${Object.entries(resvalidation.errors).map( i => `${i[0]}: ${i[1]}` )}`,
                        message: `Error ${res.status} ${res.statusText}`
                    } as CloudServiceActionResult<any>
                }
            }

            // throw new Error(`Error ${res.status} ${res.statusText}`);
            return {
                isSuccessful: false,
                exceptionMessage: res.statusText,
                message: `Error ${res.status} ${res.statusText}`
            } as CloudServiceActionResult<any>
        }

        const a = await res.text()
        if (isJson(a)) {
            return JSON.parse(a);
        }
    }

    private rest_get_apiAppSettingsGet = async (): Promise<CloudServiceActionResult<IAppSettings>> => {       
        // if we are in a unit test we skip the localStorage part.
        if (!isUnitTest) {
            const r = localStorage.getItem('AppSettings');
            if (isJson(r)) {
                if (r) {
                    let appSettings: CloudServiceActionResult<IAppSettings> = JSON.parse(r);
                    if (window.location.origin === appSettings.data?.clientUrl) {
                        return appSettings;
                    }                
                }
            }
        }
        

        return fetch(`${this.baseUrl}/api/AppSettings/Get`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse).then(r => {
            let a: CloudServiceActionResult<IAppSettings> = r;

            if (a.isSuccessful && !isUnitTest) {
                localStorage.setItem('AppSettings', JSON.stringify(a));
            }

            return r;
        });
    };

    private rest_get_apiAppSettingsGetSystemMode = async (): Promise<CloudServiceActionResult<AppMode>> => {
        return fetch(`${this.baseUrl}/api/AppSettings/GetSystemMode`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiAppSettingsSetSystemMode = async (params: { mode: AppMode, }): Promise<CloudServiceActionResult<AppMode>> => {
        return fetch(`${this.baseUrl}/api/AppSettings/SetSystemMode?${encodeQueryData(params)}`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
            },
            body: JSON.stringify({}),
        }).then(this.handleResponse);
    };

    private rest_get_apiAppSettingsGetSiteVersion = async (): Promise<CloudServiceActionResult<string>> => {
        return fetch(`${this.baseUrl}/api/AppSettings/GetSiteVersion`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    // tested
    private rest_post_apiAuditTrailsGetBatchAuditLog = async (request: AuditTrailSearchArgs): Promise<BatchAuditLogListPagedResponse> => {
        return fetch(`${this.baseUrl}/api/AuditTrails/GetBatchAuditLog`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(request),
        }).then(this.handleResponse);
    };

    // testing
    private rest_get_apiAuditTrailsGetAuditTrails = async (params: PagedRequest): Promise<AuditTrailResponseListPagedResponse> => {
        return fetch(`${this.baseUrl}/api/AuditTrails/GetAuditTrails?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    // testing... 
    private rest_get_apiAuditTrailsGetAllUsers = async (): Promise<CloudServiceActionResult<IUser[]>> => {
        return fetch(`${this.baseUrl}/api/AuditTrails/GetAllUsers`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    /** returns the username/email of the user only */
    private rest_get_apiAuditTrailsGetLastUserToEditFormula = async (params: { formulaId: string, }): Promise<CloudServiceActionResult<string>> => {
        return fetch(`${this.baseUrl}/api/AuditTrails/GetLastUserToEditFormula?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    // testing...
    private rest_get_apiAuditTrailsGetBatchAuditReport = async (params: {
        startDate: string, endDate: string,
        /** this id can be formulaid, batchid etc.. */
        referenceId: string,
    }): Promise<CloudServiceActionResult<BatchAuditReport>> => {
        return fetch(`${this.baseUrl}/api/AuditTrails/GetBatchAuditReport?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiAuditTrailsUpsertAuditTrail = async (body: AuditTrail): Promise<CloudServiceActionResult<AuditTrail>> => {
        return fetch(`${this.baseUrl}/api/AuditTrails/UpsertAuditTrail`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(body),
        }).then(this.handleResponse);
    };

    private rest_post_apiAuditTrailsExportFormulaAuditTrail = async (params: AuditTrailSearchArgs, query: { onlyUnique: boolean }): Promise<FormulaAuditTrailListPagedResponse> => {
        return fetch(`${this.baseUrl}/api/AuditTrails/ExportFormulaAuditTrail?${encodeQueryData(query)}`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(params),
        }).then(this.handleResponse);
    };

    // fixed batch type
    private rest_get_apiBatchesGetBatches = async (): Promise<CloudServiceActionResult<Batch[]>> => {
        return fetch(`${this.baseUrl}/api/Batches/GetBatches`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);

    };

    private rest_get_apiBatchesGetBatch = async (params: { id: string, }): Promise<CloudServiceActionResult<Batch>> => {
        return fetch(`${this.baseUrl}/api/Batches/GetBatch?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiBatchesRevokeBatch = async (params: { id: string, }): Promise<CloudServiceActionResult<unknown>> => {
        console.log(`${this.baseUrl}/api/Batches/RevokeBatch?${encodeQueryData(params)}`);
        return fetch(`${this.baseUrl}/api/Batches/RevokeBatch?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_delete_apiBatchesDeleteBatch = async (params: { id: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/Batches/DeleteBatch?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiBatchesGetBatchByLotNumber = async (params: { lotNumber: string, }): Promise<CloudServiceActionResult<Batch>> => {
        return fetch(`${this.baseUrl}/api/Batches/GetBatchByLotNumber?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiBatchesGetBatchByFormulaId = async (params: { formulaId: string, }): Promise<CloudServiceActionResult<Batch[]>> => {
        return fetch(`${this.baseUrl}/api/Batches/GetBatchByFormulaId?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiBatchesGetBatchByMixingTankId = async (params: { mixingTankId: string, }): Promise<CloudServiceActionResult<Batch[]>> => {
        return fetch(`${this.baseUrl}/api/Batches/GetBatchByMixingTankId?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiBatchesUpsertBatch = async (body: Batch): Promise<CloudServiceActionResult<Batch>> => {
        return fetch(`${this.baseUrl}/api/Batches/UpsertBatch`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(body),
        }).then(this.handleResponse);
    };

    private rest_post_apiBatchesUpsertBatches = async (body: Batch[]): Promise<CloudServiceActionResult<Batch[]>> => {
        return fetch(`${this.baseUrl}/api/Batches/UpsertBatches`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(body),
        }).then(this.handleResponse);
    };

    private rest_get_apiBatchIngredientsGetBatchIngredients = async (): Promise<CloudServiceActionResult<BatchIngredient[]>> => {
        return fetch(`${this.baseUrl}/api/BatchIngredients/GetBatchIngredients`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiBatchIngredientsGetBatchIngredient = async (params: { id: string, }): Promise<CloudServiceActionResult<BatchIngredient>> => {
        return fetch(`${this.baseUrl}/api/BatchIngredients/GetBatchIngredient?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiBatchIngredientsGetBatchIngredientByBatchId = async (params: { batchId: string, }): Promise<CloudServiceActionResult<BatchIngredient[]>> => {
        return fetch(`${this.baseUrl}/api/BatchIngredients/GetBatchIngredientByBatchId?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiBatchIngredientsGetBatchIngredientByChemicalId = async (params: { chemicalId: string, }): Promise<CloudServiceActionResult<BatchIngredient[]>> => {
        return fetch(`${this.baseUrl}/api/BatchIngredients/GetBatchIngredientByChemicalId?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiBatchIngredientsUpsertBatchIngredient = async (body: BatchIngredient): Promise<CloudServiceActionResult<BatchIngredient>> => {
        return fetch(`${this.baseUrl}/api/BatchIngredients/UpsertBatchIngredient`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(body),
        }).then(this.handleResponse);
    };

    private rest_post_apiBatchIngredientsUpsertBatchIngredients = async (body: BatchIngredient[]): Promise<CloudServiceActionResult<BatchIngredient[]>> => {
        return fetch(`${this.baseUrl}/api/BatchIngredients/UpsertBatchIngredients`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(body),
        }).then(this.handleResponse);
    };

    private rest_delete_apiBatchIngredientsDeleteBatchIngredient = async (params: { id: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/BatchIngredients/DeleteBatchIngredient?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiCertificateOfAnalysisGetAllCertificateOfAnalysis = async (): Promise<CloudServiceActionResult<CertificateOfAnalysis[]>> => {
        return fetch(`${this.baseUrl}/api/CertificateOfAnalysis/GetAllCertificateOfAnalysis`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiCertificateOfAnalysisGetCertificateOfAnalysis = async (params: { id: string, }): Promise<CloudServiceActionResult<CertificateOfAnalysis>> => {
        return fetch(`${this.baseUrl}/api/CertificateOfAnalysis/GetCertificateOfAnalysis?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
        }).then(this.handleResponse);
    };

    private rest_delete_apiCertificateOfAnalysisDeleteCertificateOfAnalysis = async (params: { id: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/CertificateOfAnalysis/DeleteCertificateOfAnalysis?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiCertificateOfAnalysisUpsertCertificateOfAnalysis = async (certificate: CertificateOfAnalysis): Promise<CloudServiceActionResult<CertificateOfAnalysis>> => {
        return fetch(`${this.baseUrl}/api/CertificateOfAnalysis/UpsertCertificateOfAnalysis`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(certificate),
        }).then(this.handleResponse);
    };

    private rest_get_apiCertificateOfAnalysisGetCertificateOfAnalysisByFormulaId = async (params: CertificateOfAnalysisRequestByFormulaId): Promise<CloudServiceActionResult<CertificateOfAnalysis>> => {
        return fetch(`${this.baseUrl}/api/CertificateOfAnalysis/GetCertificateOfAnalysisByFormulaId?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiCertificateOfAnalysisGetCertificateOfAnalysisByBatchNo = async (params: { lotNumber: string, }): Promise<CloudServiceActionResult<CertificateOfAnalysis>> => {
        return fetch(`${this.baseUrl}/api/CertificateOfAnalysis/GetCertificateOfAnalysisByBatchNo?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiChemicalsGetChemicals = async (): Promise<CloudServiceActionResult<Chemical[]>> => {
        return fetch(`${this.baseUrl}/api/Chemicals/GetChemicals`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiChemicalsGetChemical = async (params: { id: string, }): Promise<CloudServiceActionResult<Chemical>> => {
        return fetch(`${this.baseUrl}/api/Chemicals/GetChemical?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
        }).then(this.handleResponse);
    };

    private rest_delete_apiChemicalsDeleteChemical = async (params: { id: string, }): Promise<CloudServiceActionResult<Chemical>> => {
        return fetch(`${this.baseUrl}/api/Chemicals/DeleteChemical?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiChemicalsUpsertChemical = async (chemical: Chemical): Promise<CloudServiceActionResult<Chemical>> => {
        return fetch(`${this.baseUrl}/api/Chemicals/UpsertChemical`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(chemical),
        }).then(this.handleResponse);
    };

    private rest_post_apiConfigurationCurrentConfiguration = async (): Promise<ICurrentConfigurationResponse> => {
        return fetch(`${this.baseUrl}/api/Configuration/CurrentConfiguration`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify({}),
        }).then(this.handleResponse);
    };

    private rest_get_apiContainersGetContainers = async (): Promise<CloudServiceActionResult<Container[]>> => {
        return fetch(`${this.baseUrl}/api/Containers/GetContainers`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiContainersGetContainer = async (params: { id: string, }): Promise<CloudServiceActionResult<Container>> => {
        return fetch(`${this.baseUrl}/api/Containers/GetContainer?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_delete_apiContainersDeleteContainer = async (params: { id: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/Containers/DeleteContainer?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiContainersUpsertContainer = async (container: Container): Promise<CloudServiceActionResult<Container>> => {
        return fetch(`${this.baseUrl}/api/Containers/UpsertContainer`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(container),
        }).then(this.handleResponse);
    };

    private rest_get_apiDeviationDocDeviationDoc = async (): Promise<unknown> => {
        return fetch(`${this.baseUrl}/api/DeviationDoc/DeviationDoc`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiFormulasGetFormulas = async (params: { includeInactiveFormulas: boolean, }): Promise<CloudServiceActionResult<Formula[]>> => {
        return fetch(`${this.baseUrl}/api/Formulas/GetFormulas?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiFormulasGetFormula = async (params: { id: string, }): Promise<CloudServiceActionResult<Formula>> => {
        return fetch(`${this.baseUrl}/api/Formulas/GetFormula?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiFormulasGetLastFormulaBasedOnSkuNumber = async (): Promise<CloudServiceActionResult<Formula>> => {
        return fetch(`${this.baseUrl}/api/Formulas/GetLastFormulaBasedOnSkuNumber`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiFormulasUpsertFormula = async (formula: Formula): Promise<CloudServiceActionResult<Formula>> => {
        return fetch(`${this.baseUrl}/api/Formulas/UpsertFormula`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(formula),
        }).then(this.handleResponse);
    };

    private rest_get_apiFormulaStepsGetFormulaSteps = async (): Promise<CloudServiceActionResult<FormulaStep[]>> => {
        return fetch(`${this.baseUrl}/api/FormulaSteps/GetFormulaSteps`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiFormulaStepsGetFormulaStepByFormulaId = async (params: { formulaId: string, }): Promise<CloudServiceActionResult<FormulaStep[]>> => {
        return fetch(`${this.baseUrl}/api/FormulaSteps/GetFormulaStepByFormulaId?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiFormulaStepsUpsertFormulaStep = async (formulaStep:FormulaStep): Promise<CloudServiceActionResult<FormulaStep>> => {
        return fetch(`${this.baseUrl}/api/FormulaSteps/UpsertFormulaStep`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(formulaStep),
        }).then(this.handleResponse);
    };

    private rest_delete_apiFormulaStepsDeleteFormulaStep = async (params: { id: string, }): Promise<CloudServiceActionResult<FormulaStep>> => {
        return fetch(`${this.baseUrl}/api/FormulaSteps/DeleteFormulaStep?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiFormulaStepsGetFormulaStep = async (params: { id: string, }): Promise<CloudServiceActionResult<FormulaStep>> => {
        return fetch(`${this.baseUrl}/api/FormulaSteps/GetFormulaStep?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiFormulaStepsReOrderFormulaSteps = async (body: FormulaStep[]): Promise<CloudServiceActionResult<FormulaStep[]>> => {
        return fetch(`${this.baseUrl}/api/FormulaSteps/ReOrderFormulaSteps`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(body),
        }).then(this.handleResponse);
    };

    private rest_get_metricsGraphs = async (): Promise<unknown> => {
        return fetch(`${this.baseUrl}/metricsGraphs`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_metricsGraphsupdateData = async (): Promise<unknown> => {
        return fetch(`${this.baseUrl}/metricsGraphs/updateData`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiInstructionsGetInstructions = async (): Promise<CloudServiceActionResult<Instruction[]>> => {
        return fetch(`${this.baseUrl}/api/Instructions/GetInstructions`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiInstructionsGetInstruction = async (params: { id: string, }): Promise<CloudServiceActionResult<Instruction>> => {
        return fetch(`${this.baseUrl}/api/Instructions/GetInstruction?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_delete_apiInstructionsDeleteInstruction = async (params: { id: string, }): Promise<CloudServiceActionResult<Instruction[]>> => {
        return fetch(`${this.baseUrl}/api/Instructions/DeleteInstruction?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiInstructionsUpsertInstruction = async (instruction: Instruction): Promise<CloudServiceActionResult<Instruction>> => {
        return fetch(`${this.baseUrl}/api/Instructions/UpsertInstruction`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(instruction),
        }).then(this.handleResponse);
    };

    private rest_get_apiInstructionsGetInstructionByFormulaId = async (params: { formulaId: string, }): Promise<CloudServiceActionResult<Instruction[]>> => {
        return fetch(`${this.baseUrl}/api/Instructions/GetInstructionByFormulaId?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiInstructionsUploadInstructionsFile = async (params: { formulaId: string, }): Promise<CloudServiceActionResult<Instruction[]>> => {
        return fetch(`${this.baseUrl}/api/Instructions/UploadInstructionsFile?${encodeQueryData(params)}`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify({}),
        }).then(this.handleResponse);
    };

    private rest_post_apiInstructionsReOrderFormulaInstructions = async (body: Instruction[]): Promise<CloudServiceActionResult<Instruction[]>> => {
        return fetch(`${this.baseUrl}/api/Instructions/ReOrderFormulaInstructions`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(body),
        }).then(this.handleResponse);
    };

    private rest_get_apiMethodsGetMethods = async (): Promise<CloudServiceActionResult<Method[]>> => {
        return fetch(`${this.baseUrl}/api/Methods/GetMethods`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiMethodsGetMethod = async (params: { id: string, }): Promise<CloudServiceActionResult<Method>> => {
        return fetch(`${this.baseUrl}/api/Methods/GetMethod?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiMethodsUpsertMethod = async (method: Method): Promise<CloudServiceActionResult<Method>> => {
        return fetch(`${this.baseUrl}/api/Methods/UpsertMethod`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(method),
        }).then(this.handleResponse);
    };

    private rest_delete_apiMethodsDeleteMethod = async (params: { id: string, }): Promise<CloudServiceActionResult<Method>> => {
        return fetch(`${this.baseUrl}/api/Methods/DeleteMethod?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiMixingTanksGetMixingTanks = async (): Promise<CloudServiceActionResult<MixingTank[]>> => {
        return fetch(`${this.baseUrl}/api/MixingTanks/GetMixingTanks`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiMixingTanksGetMixingTank = async (params: { id: string, }): Promise<CloudServiceActionResult<MixingTank>> => {
        return fetch(`${this.baseUrl}/api/MixingTanks/GetMixingTank?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_delete_apiMixingTanksDeleteMixingTank = async (params: { id: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/MixingTanks/DeleteMixingTank?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiMixingTanksUpsertMixingTank = async (body: MixingTank): Promise<CloudServiceActionResult<MixingTank>> => {
        return fetch(`${this.baseUrl}/api/MixingTanks/UpsertMixingTank`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(body),
        }).then(this.handleResponse);
    };

    private rest_get_apiProductQuantityGetProductQuantities = async (): Promise<CloudServiceActionResult<ProductQuantity[]>> => {
        return fetch(`${this.baseUrl}/api/ProductQuantity/GetProductQuantities`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiProductQuantityGetProductQuantity = async (params: { id: string, }): Promise<CloudServiceActionResult<ProductQuantity>> => {
        return fetch(`${this.baseUrl}/api/ProductQuantity/GetProductQuantity?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiProductQuantityGetProductQuantityByBatchId = async (params: { batchId: string, }): Promise<CloudServiceActionResult<ProductQuantity[]>> => {
        return fetch(`${this.baseUrl}/api/ProductQuantity/GetProductQuantityByBatchId?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiProductQuantityGetProductQuantityByProductId = async (params: { productId: string, }): Promise<CloudServiceActionResult<ProductQuantity>> => {
        return fetch(`${this.baseUrl}/api/ProductQuantity/GetProductQuantityByProductId?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiProductQuantityUpsertProductQuantity = async (body: ProductQuantity): Promise<CloudServiceActionResult<ProductQuantity>> => {
        return fetch(`${this.baseUrl}/api/ProductQuantity/UpsertProductQuantity`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(body),
        }).then(this.handleResponse);
    };

    private rest_post_apiProductQuantityUpsertProductQuantities = async (body: ProductQuantity[]): Promise<CloudServiceActionResult<ProductQuantity[]>> => {
        return fetch(`${this.baseUrl}/api/ProductQuantity/UpsertProductQuantities`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(body),
        }).then(this.handleResponse);
    };

    private rest_delete_apiProductQuantityDeleteProductQuantity = async (params: { id: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/ProductQuantity/DeleteProductQuantity?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiProductsGetProducts = async (): Promise<CloudServiceActionResult<Product[]>> => {
        return fetch(`${this.baseUrl}/api/Products/GetProducts`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiProductsGetProductByFormulaId = async (params: { formulaId: string, }): Promise<CloudServiceActionResult<Product[]>> => {
        return fetch(`${this.baseUrl}/api/Products/GetProductByFormulaId?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiProductsGetProduct = async (params: { id: string, }): Promise<CloudServiceActionResult<Product>> => {
        return fetch(`${this.baseUrl}/api/Products/GetProduct?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_delete_apiProductsDeleteProduct = async (params: { id: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/Products/DeleteProduct?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiProductsUpsertProduct = async (product: Product): Promise<CloudServiceActionResult<Product>> => {
        return fetch(`${this.baseUrl}/api/Products/UpsertProduct`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(product),
        }).then(this.handleResponse);
    };

    // not sure how this api call works
    private rest_post_apiProductsUpsertProducts = async (body: Product[]): Promise<CloudServiceActionResult<Product[]>> => {
        return fetch(`${this.baseUrl}/api/Products/UpsertProducts`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(body),
        }).then(this.handleResponse);
    };

    private rest_get_apiQualityControlsGetFormulaTolerances = async (): Promise<CloudServiceActionResult<FormulaTolerance[]>> => {
        return fetch(`${this.baseUrl}/api/QualityControls/GetFormulaTolerances`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiQualityControlsGetFormulaToleranceByFormulaId = async (params: { formulaId: string, }): Promise<CloudServiceActionResult<FormulaTolerance[]>> => {
        return fetch(`${this.baseUrl}/api/QualityControls/GetFormulaToleranceByFormulaId?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiQualityControlsUpsertFormulaTolerance = async (params: FormulaTolerance): Promise<CloudServiceActionResult<FormulaTolerance>> => {
        return fetch(`${this.baseUrl}/api/QualityControls/UpsertFormulaTolerance`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify(params),
        }).then(this.handleResponse);
    };

    private rest_delete_apiQualityControlsDeleteFormulaTolerance = async (params: { id: string, }): Promise<CloudServiceActionResult<FormulaTolerance>> => {
        return fetch(`${this.baseUrl}/api/QualityControls/DeleteFormulaTolerance?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiQualityControlsGetFormulaTolerance = async (params: { id: string, }): Promise<CloudServiceActionResult<FormulaTolerance>> => {
        return fetch(`${this.baseUrl}/api/QualityControls/GetFormulaTolerance?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiReportsGetFillTicketReport = async (params: { formulaId: string, }): Promise<CloudServiceActionResult<FillTicket>> => {
        return fetch(`${this.baseUrl}/api/Reports/GetFillTicketReport?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiReportsGetBatchTicketReport = async (params: { batchId: string, }): Promise<CloudServiceActionResult<BatchReport>> => {
        return fetch(`${this.baseUrl}/api/Reports/GetBatchTicketReport?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiReportsGetFormulaReport = async (params: { formulaId: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/Reports/GetFormulaReport?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiReportsGetProductionLog = async (params: PagedRequest): Promise<ProductionLogListPagedResponse> => {
        return fetch(`${this.baseUrl}/api/Reports/GetProductionLog?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiUsersGetAllUsers = async (): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/Users/GetAllUsers`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiValidationsGetValidations = async (): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/Validations/GetValidations`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiValidationsGetValidationByValidationSetId = async (params: { validationSetId: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/Validations/GetValidationByValidationSetId?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiValidationsUpsertValidation = async (): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/Validations/UpsertValidation`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify({}),
        }).then(this.handleResponse);
    };

    private rest_delete_apiValidationsDeleteValidation = async (params: { id: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/Validations/DeleteValidation?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiValidationsGetValidation = async (params: { id: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/Validations/GetValidation?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiValidationsGetValidationByFormulaToleranceId = async (params: { formulaToleranceId: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/Validations/GetValidationByFormulaToleranceId?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiValidationSetsGetValidationSets = async (): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/ValidationSets/GetValidationSets`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiValidationSetsGetValidationSetByBatchId = async (params: { batchId: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/ValidationSets/GetValidationSetByBatchId?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_post_apiValidationSetsUpsertValidationSet = async (): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/ValidationSets/UpsertValidationSet`, {
            method: 'post',
            headers: {
                authorization: this.authorization,
                "content-type": 'application/json'
            },
            body: JSON.stringify({}),
        }).then(this.handleResponse);
    };

    private rest_delete_apiValidationSetsDeleteValidationSet = async (params: { id: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/ValidationSets/DeleteValidationSet?${encodeQueryData(params)}`, {
            method: 'delete',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    private rest_get_apiValidationSetsGetValidationSet = async (params: { id: string, }): Promise<CloudServiceActionResult<unknown>> => {
        return fetch(`${this.baseUrl}/api/ValidationSets/GetValidationSet?${encodeQueryData(params)}`, {
            method: 'get',
            headers: {
                authorization: this.authorization,
            },
        }).then(this.handleResponse);
    };

    api = {
        AppSettings: {
            Get: this.rest_get_apiAppSettingsGet,
            GetSystemMode: this.rest_get_apiAppSettingsGetSystemMode,
            SetSystemMode: this.rest_post_apiAppSettingsSetSystemMode,
            GetSiteVersion: this.rest_get_apiAppSettingsGetSiteVersion
        },
        AuditTrails: {
            GetBatchAuditLog: this.rest_post_apiAuditTrailsGetBatchAuditLog,
            GetAuditTrails: this.rest_get_apiAuditTrailsGetAuditTrails,
            GetAllUsers: this.rest_get_apiAuditTrailsGetAllUsers,
            GetLastUserToEditFormula: this.rest_get_apiAuditTrailsGetLastUserToEditFormula,
            GetBatchAuditReport: this.rest_get_apiAuditTrailsGetBatchAuditReport,
            UpsertAuditTrail: this.rest_post_apiAuditTrailsUpsertAuditTrail,
            ExportFormulaAuditTrail: this.rest_post_apiAuditTrailsExportFormulaAuditTrail
        },
        Batches: {
            GetBatches: this.rest_get_apiBatchesGetBatches,
            GetBatch: this.rest_get_apiBatchesGetBatch,
            RevokeBatch: this.rest_get_apiBatchesRevokeBatch,
            DeleteBatch: this.rest_delete_apiBatchesDeleteBatch,
            GetBatchByLotNumber: this.rest_get_apiBatchesGetBatchByLotNumber,
            GetBatchByFormulaId: this.rest_get_apiBatchesGetBatchByFormulaId,
            GetBatchByMixingTankId: this.rest_get_apiBatchesGetBatchByMixingTankId,
            UpsertBatch: this.rest_post_apiBatchesUpsertBatch,
            UpsertBatches: this.rest_post_apiBatchesUpsertBatches
        },
        BatchIngredients: {
            GetBatchIngredients: this.rest_get_apiBatchIngredientsGetBatchIngredients,
            GetBatchIngredient: this.rest_get_apiBatchIngredientsGetBatchIngredient,
            GetBatchIngredientByBatchId: this.rest_get_apiBatchIngredientsGetBatchIngredientByBatchId,
            GetBatchIngredientByChemicalId: this.rest_get_apiBatchIngredientsGetBatchIngredientByChemicalId,
            UpsertBatchIngredient: this.rest_post_apiBatchIngredientsUpsertBatchIngredient,
            UpsertBatchIngredients: this.rest_post_apiBatchIngredientsUpsertBatchIngredients,
            DeleteBatchIngredient: this.rest_delete_apiBatchIngredientsDeleteBatchIngredient
        },
        CertificateOfAnalysis: {
            GetAllCertificateOfAnalysis: this.rest_get_apiCertificateOfAnalysisGetAllCertificateOfAnalysis,
            GetCertificateOfAnalysis: this.rest_get_apiCertificateOfAnalysisGetCertificateOfAnalysis,
            DeleteCertificateOfAnalysis: this.rest_delete_apiCertificateOfAnalysisDeleteCertificateOfAnalysis,
            UpsertCertificateOfAnalysis: this.rest_post_apiCertificateOfAnalysisUpsertCertificateOfAnalysis,
            GetCertificateOfAnalysisByFormulaId: this.rest_get_apiCertificateOfAnalysisGetCertificateOfAnalysisByFormulaId,
            GetCertificateOfAnalysisByBatchNo: this.rest_get_apiCertificateOfAnalysisGetCertificateOfAnalysisByBatchNo
        },
        Chemicals: {
            GetChemicals: this.rest_get_apiChemicalsGetChemicals,
            GetChemical: this.rest_get_apiChemicalsGetChemical,
            DeleteChemical: this.rest_delete_apiChemicalsDeleteChemical,
            UpsertChemical: this.rest_post_apiChemicalsUpsertChemical
        },
        Configuration: {
            CurrentConfiguration: this.rest_post_apiConfigurationCurrentConfiguration
        },
        Containers: {
            GetContainers: this.rest_get_apiContainersGetContainers,
            GetContainer: this.rest_get_apiContainersGetContainer,
            DeleteContainer: this.rest_delete_apiContainersDeleteContainer,
            UpsertContainer: this.rest_post_apiContainersUpsertContainer
        },
        DeviationDoc: {
            DeviationDoc: this.rest_get_apiDeviationDocDeviationDoc
        },
        Formulas: {
            GetFormulas: this.rest_get_apiFormulasGetFormulas,
            GetFormula: this.rest_get_apiFormulasGetFormula,
            GetLastFormulaBasedOnSkuNumber: this.rest_get_apiFormulasGetLastFormulaBasedOnSkuNumber,
            UpsertFormula: this.rest_post_apiFormulasUpsertFormula
        },
        FormulaSteps: {
            GetFormulaSteps: this.rest_get_apiFormulaStepsGetFormulaSteps,
            GetFormulaStepByFormulaId: this.rest_get_apiFormulaStepsGetFormulaStepByFormulaId,
            UpsertFormulaStep: this.rest_post_apiFormulaStepsUpsertFormulaStep,
            DeleteFormulaStep: this.rest_delete_apiFormulaStepsDeleteFormulaStep,
            GetFormulaStep: this.rest_get_apiFormulaStepsGetFormulaStep,
            ReOrderFormulaSteps: this.rest_post_apiFormulaStepsReOrderFormulaSteps
        },
        Instructions: {
            GetInstructions: this.rest_get_apiInstructionsGetInstructions,
            GetInstruction: this.rest_get_apiInstructionsGetInstruction,
            DeleteInstruction: this.rest_delete_apiInstructionsDeleteInstruction,
            UpsertInstruction: this.rest_post_apiInstructionsUpsertInstruction,
            GetInstructionByFormulaId: this.rest_get_apiInstructionsGetInstructionByFormulaId,
            UploadInstructionsFile: this.rest_post_apiInstructionsUploadInstructionsFile,
            ReOrderFormulaInstructions: this.rest_post_apiInstructionsReOrderFormulaInstructions
        },
        Methods: {
            GetMethods: this.rest_get_apiMethodsGetMethods,
            GetMethod: this.rest_get_apiMethodsGetMethod,
            UpsertMethod: this.rest_post_apiMethodsUpsertMethod,
            DeleteMethod: this.rest_delete_apiMethodsDeleteMethod
        },
        MixingTanks: {
            GetMixingTanks: this.rest_get_apiMixingTanksGetMixingTanks,
            GetMixingTank: this.rest_get_apiMixingTanksGetMixingTank,
            DeleteMixingTank: this.rest_delete_apiMixingTanksDeleteMixingTank,
            UpsertMixingTank: this.rest_post_apiMixingTanksUpsertMixingTank
        },
        ProductQuantity: {
            GetProductQuantities: this.rest_get_apiProductQuantityGetProductQuantities,
            GetProductQuantity: this.rest_get_apiProductQuantityGetProductQuantity,
            GetProductQuantityByBatchId: this.rest_get_apiProductQuantityGetProductQuantityByBatchId,
            GetProductQuantityByProductId: this.rest_get_apiProductQuantityGetProductQuantityByProductId,
            UpsertProductQuantity: this.rest_post_apiProductQuantityUpsertProductQuantity,
            UpsertProductQuantities: this.rest_post_apiProductQuantityUpsertProductQuantities,
            DeleteProductQuantity: this.rest_delete_apiProductQuantityDeleteProductQuantity
        },
        Products: {
            GetProducts: this.rest_get_apiProductsGetProducts,
            GetProductByFormulaId: this.rest_get_apiProductsGetProductByFormulaId,
            GetProduct: this.rest_get_apiProductsGetProduct,
            DeleteProduct: this.rest_delete_apiProductsDeleteProduct,
            UpsertProduct: this.rest_post_apiProductsUpsertProduct,
            UpsertProducts: this.rest_post_apiProductsUpsertProducts
        },
        QualityControls: {
            GetFormulaTolerances: this.rest_get_apiQualityControlsGetFormulaTolerances,
            GetFormulaToleranceByFormulaId: this.rest_get_apiQualityControlsGetFormulaToleranceByFormulaId,
            UpsertFormulaTolerance: this.rest_post_apiQualityControlsUpsertFormulaTolerance,
            DeleteFormulaTolerance: this.rest_delete_apiQualityControlsDeleteFormulaTolerance,
            GetFormulaTolerance: this.rest_get_apiQualityControlsGetFormulaTolerance
        },
        Reports: {
            GetFillTicketReport: this.rest_get_apiReportsGetFillTicketReport,
            GetBatchTicketReport: this.rest_get_apiReportsGetBatchTicketReport,
            GetFormulaReport: this.rest_get_apiReportsGetFormulaReport,
            GetProductionLog: this.rest_get_apiReportsGetProductionLog
        },
        Users: {
            GetAllUsers: this.rest_get_apiUsersGetAllUsers
        },
        Validations: {
            GetValidations: this.rest_get_apiValidationsGetValidations,
            GetValidationByValidationSetId: this.rest_get_apiValidationsGetValidationByValidationSetId,
            UpsertValidation: this.rest_post_apiValidationsUpsertValidation,
            DeleteValidation: this.rest_delete_apiValidationsDeleteValidation,
            GetValidation: this.rest_get_apiValidationsGetValidation,
            GetValidationByFormulaToleranceId: this.rest_get_apiValidationsGetValidationByFormulaToleranceId
        },
        ValidationSets: {
            GetValidationSets: this.rest_get_apiValidationSetsGetValidationSets,
            GetValidationSetByBatchId: this.rest_get_apiValidationSetsGetValidationSetByBatchId,
            UpsertValidationSet: this.rest_post_apiValidationSetsUpsertValidationSet,
            DeleteValidationSet: this.rest_delete_apiValidationSetsDeleteValidationSet,
            GetValidationSet: this.rest_get_apiValidationSetsGetValidationSet
        }
    }


    metricsGraphs = {
        updateData: this.rest_get_metricsGraphsupdateData
    }

}

export function encodeQueryData(data: any): string {
    const ret: string[] = [];
    Object.keys(data).forEach(d => {
        ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d] as string)}`);
    });
    return ret.join('&');
}