import { ArrowDropDownCircleTwoTone, ArrowForwardIosTwoTone, Check } from '@mui/icons-material'
import { Typography } from '@mui/material';

interface BatchStateIconProps {
    "completed": boolean,
    "active": boolean, 
    "error": boolean, 
    "icon": number
}

export function BatchStateIcon(props: BatchStateIconProps ) {

    let color = props.completed ? "success" : "primary";
    let Icon: any = Check;

    if (!props.completed) {
        Icon = ArrowForwardIosTwoTone
    }

    if (props.active) {
        Icon = ArrowDropDownCircleTwoTone
        color = "secondary";
        return <Typography color={color} sx={{ pt: 0.25}}><i className="fas fa-circle-notch fa-lg fa-spin" /></Typography>
    }



    return <Icon color={color} />
    // return <AddTwoTone />
}