import React from "react";
import Paper from "@mui/material/Paper";

//api
import { boumaticApi } from "../../App";
import { Method } from "../../api/interfaces";
import { Await, DataGrid } from 'components';


interface State {
    methodsResponse?: Await<ReturnType<typeof boumaticApi.api.Methods.GetMethods>>
}
interface Props {}

export class MethodsList extends React.Component<Props, State> {
    state: State = {}

    componentDidMount = () => {
        this.getMethods();
    }

    getMethods = async () => {
        const methodsResponse = await boumaticApi.api.Methods.GetMethods()
        this.setState({methodsResponse});
    }

    render() {
        return <Paper elevation={3}>
            {/* <AppBar position="static" color="primary" enableColorOnDark>
                <Toolbar variant="dense" color={"primary"}>
                    <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                        Methods
                    </Typography>
                </Toolbar>
            </AppBar> */}

            {/* START DATA TABLE */}
            <DataGrid<Method>
                title="Methods"
                columns={[
                    {
                        name: "id",
                        title: "ID",
                        width: 50,
                    },
                    {
                        name: "name",
                        title: "NAME",
                        align: "left"
                    },
                    {
                        name: "active",
                        title: "IS ACTIVE",
                        type: "boolean",
                        width: 50,
                    },
                ]}
                rows={this.state.methodsResponse?.data?.sort( (a,b) => (parseInt(a.id) < parseInt(b.id)) ? 1 : -1 )}
                editMode
                showSaveAllButton
                allowAddRow
                allowDelete
                allowEdit
                allowSearch
                onChange={async (props) => {
                    const result = await boumaticApi.api.Methods.UpsertMethod(props.row)
                    return result;
                }}
                onDelete={async (props) => {
                    if (!props.row.id) return;
                    return await boumaticApi.api.Methods.DeleteMethod({id: props.row.id})
                }}               
            />
        </Paper>
    }
}