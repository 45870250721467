import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap, Tooltip } from '@mui/material';



export interface ModalWrapperProps {
  tooltipTitle: string;
  color: "inherit" | "error" | "disabled" | "action" | "primary" | "secondary" | "info" | "success" | "warning" | undefined;
  icon: React.ReactNode,
  theme: any,
  disabled?: boolean,
  dialog?: {
    title: string,
    content?: string,
  },
  /** event when user confirms modal */
  onConfirm: () => void
  /** useful to skip confirm sometimes if its not needed */
  skipConfirm?: boolean
}

export interface ModalWrapperState { }

export class ModalWrapper extends React.Component<ModalWrapperProps, ModalWrapperState> {
  state = {
    open: false
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  modal() {
    if (!this.props.dialog) return null;

    return (

          // <Dialog style={{"backgroundColor" : "defaultThemeData.modalContentBackgroundColour"}}
          <Dialog
              PaperProps={{style:{"backgroundImage" : this.props.theme.palette.background.default, "backgroundColor": this.props.theme.palette.background.default, "color" : this.props.theme.palette.secondary.contrastText} }}
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {this.props.dialog.title}
            </DialogTitle>

            {this.props.dialog.content &&
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.props.dialog.content}
              </DialogContentText>
            </DialogContent>
            }

            <DialogActions>
              <Button color="inherit" onClick={this.handleClose}>
                Cancel
              </Button>
              <Button color="inherit" onClick={() => {
                this.handleClose();
                this.props.onConfirm();
              }} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
    )
  }

  render() {

    let Icon = this.props.icon as OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string; };

    return <>
      {this.state.open && this.modal()}
      <Tooltip placement="top" title={this.props.tooltipTitle}>
        <Icon
            color={this.props.color}
            sx={{
              opacity: 0.2,
              "&:hover": {
                opacity: (this.props.disabled !== true) ? 1 : undefined
              }
            }}
            onClick={async (e) => {
              e.cancelable = true;
              e.stopPropagation();
              // @ts-ignore
              // e.stopImmediatePropagation();
              e.preventDefault();

              if (this.props.disabled !== true) {
                if (this.props.skipConfirm) {
                  this.props.onConfirm();
                  return;
                }

                this.setState({ open: true });
              }

            }} />
      </Tooltip></>
  }
}
