import React from 'react';
import { Image as PDFImage, Text } from '@react-pdf/renderer';

export interface ISizedImageProps {
    src: string
    maxWidth: number
    maxHeight: number
}

export interface ISizedImageState {
    width?: number
    height?: number
}

export class SizedImage extends React.Component<ISizedImageProps> {
    state: ISizedImageState = {
        width: undefined,
        height: undefined
    }

    componentDidMount = async () => {
        this.getImgFromUrl(this.props.src);
    }

    getImgFromUrl = (src: string) => {
        return new Promise<HTMLImageElement>((resolve) => {
            var img = new Image();
            img.src = src;
            img.onload = () => {
                // console.log('got image data..')
                // console.log(img.width)
                this.setState({ width: img.width, height: img.height });
                resolve(img);
            }
        })
    }

    render() {

        if (!this.state.width || !this.state.height) {
            return <Text>Image loading</Text>;
        }

        let imgRatio = this.state.width / this.state.height;

        let displayWidth = this.state.width;
        let displayHeight = this.state.height;

        if (this.props.maxHeight >= this.props.maxWidth) {
            displayHeight = this.props.maxHeight;
            displayWidth = displayHeight / imgRatio;
        } else {
            displayWidth = this.props.maxWidth;
            displayHeight = displayWidth / imgRatio;
        }

        return <PDFImage src={this.props.src}
            style={{
                width: displayWidth,
                height: displayHeight
            }} />
    }
}