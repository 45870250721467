import { Box, Paper } from "@mui/material";
import React, { useRef } from "react";
import { FormulaEdit, FormulaList } from '../chemist'

import { useParams, useNavigate } from "react-router-dom";

export function AdminFormulasRoute() {
  let params = useParams<"id">();
  let navigate = useNavigate();
  const formulaList = useRef<FormulaList>(null);

  return (<Paper sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', p: 2 }}>
    <Paper style={{ flex: 1 }} elevation={3}>
      <FormulaList ref={formulaList} activeid={params.id} />
    </Paper>
    {(params.id !== undefined) && <Box style={{ flex: 2 }}>
      <FormulaEdit
        formulaId={params.id}
        onChange={(formula) => { 
          console.log('FormulaEdit', formula);

          // especially on new formulas we navigate to the new server assigned id
          navigate(`/admin/formulas/${formula.id}`);
          if (formulaList.current) {
            formulaList.current.getData(); // trigger a list update
          }
        }} />
    </Box>}
  </Paper>
  );
}

