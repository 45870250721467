import React from "react";
import { Alert, CircularProgress, Typography } from "@mui/material";
import { boumaticApi } from "../App";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Await, getDecodedToken, isJson, IToken, TokenParse } from 'components';
import packageJson from "../../package.json";

interface IAccountState {
  appSettings?: Await<ReturnType<typeof boumaticApi.api.AppSettings.Get>>,
  token?: IToken,
  account?: ReturnType<typeof getDecodedToken>,

}

interface IAccountProps { }

export class Account extends React.Component<IAccountProps, IAccountState> {
  state: IAccountState = {};

  componentDidMount = async () => {
    this.getData();
  };

  getData = async () => {
    const appSettings = await boumaticApi.api.AppSettings.Get();

    let tokenTry = localStorage.getItem("token")
    let token: IToken | undefined = undefined;

    if (tokenTry && isJson(tokenTry)) {
      token = JSON.parse(tokenTry);
    }

    const account = getDecodedToken();

    this.setState({ appSettings, token, account });
  }

  render() {
    return (

      <Paper sx={{ p: 2, m: 2, maxWidth: 500 }} elevation={0}>
        <Typography variant="h5" sx={{ mb: 5 }}>
          ACCOUNT
        </Typography>

        <Box sx={{ mt: 3 }}>
          <Typography variant="caption" sx={{ opacity: 0.6 }}>
            USERNAME
          </Typography>

          <Typography variant="h6" id="account_username">
            <TokenParse component={(token) => token.preferred_username} />
          </Typography>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="caption" sx={{ opacity: 0.6 }}>
            SCOPE
          </Typography>
          <TokenParse component={(token) => token.scope.map(scope => <Typography key={scope} className="account_scope_entry" variant="body2">{scope}</Typography>)} />
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="caption" sx={{ opacity: 0.6 }}>
            ROLES
          </Typography>
          <TokenParse component={(token) => {
            if (!token) return <Typography>error no token</Typography>
            if (!token.role) return <Typography>error no role data</Typography>
            
            if (Array.isArray(token.role)) return token.role.map(role => <Typography
              className="account_role_entry"
              key={role} variant="body2">{role}</Typography>)
            if (typeof token.role === 'string') return <Typography className="account_role_entry">{token.role}</Typography>

            return <Typography>error {JSON.stringify(token.role)}</Typography>
          }} />
        </Box>

        <Typography variant="h5" sx={{ mt: 10, mb: 5 }}>
          APP SETTINGS
        </Typography>

        {!this.state.appSettings && <CircularProgress />}
        {this.state.appSettings?.isSuccessful === false && <Alert severity="error">Error loading App Settings. {this.state.appSettings?.exceptionMessage}</Alert>}

        {this.state.appSettings?.data && <>
          <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="caption" sx={{ opacity: 0.6, textTransform: 'uppercase' }}>
              Authentication Server
            </Typography>

            <Box>
              <Button id="authentication_server" sx={{ p: 0, m: 0 }} variant="text" color="inherit" onClick={async () => {
                window.location.replace(this.state.appSettings?.data?.authenticationServer || '');
              }}>{this.state.appSettings?.data?.authenticationServer}</Button>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography variant="caption" sx={{ opacity: 0.6, textTransform: 'uppercase' }}>
              Client ID
            </Typography>

            <Typography id="client_id" variant="h6">
              {this.state.appSettings?.data?.clientId}
            </Typography>
          </Box>

          <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="caption" sx={{ opacity: 0.6, textTransform: 'uppercase' }}>
              Client URL
            </Typography>

            <Box>
              <Button id="client_url" sx={{ p: 0, m: 0 }} variant="text" color="inherit" onClick={async () => {
                window.location.replace(this.state.appSettings?.data?.clientUrl || '');
              }}>{this.state.appSettings?.data?.clientUrl}</Button>
            </Box>
          </Box>

          <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="caption" sx={{ opacity: 0.6, textTransform: 'uppercase' }}>
              Web Api Url
            </Typography>

            <Box>
              <Button id="web_api_url" sx={{ p: 0, m: 0 }} variant="text" color="inherit" onClick={async () => {
                window.location.replace(this.state.appSettings?.data?.webApiUrl || '');
              }}>{this.state.appSettings?.data?.webApiUrl}</Button>
            </Box>
          </Box>

          <Button sx={{ mt: 3 }} variant="outlined" color="error" onClick={async () => {
            localStorage.clear();
            window.location.replace(`${this.state.appSettings?.data?.authenticationServer}/auth/connect/endsession?id_token_hint=${this.state.token?.access_token.split(".")[0]}&returnTo=${encodeURIComponent(window.location.origin)}`)
          }}>Logout</Button>

        </>}

        <Box sx={{ mt: 10 }}>
          <Typography variant="caption" sx={{ opacity: 0.25 }} align="center">
            {packageJson.name} {packageJson.version}
          </Typography>
        </Box>

      </Paper>


    );
  }
}
