/** shows the batches queued on a mixing tank. */

import Paper from '@mui/material/Paper';
import React from 'react';

import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { boumaticApi, boumaticApiSignalR, boumaticTheme } from '../../App';
import { Await, DataGrid, ICell } from 'components';

import {
    Batch,
    BatchProcessingStatus,
    BatchState,
    CompatibilityGroups,
    MixingTank
} from '../../api/interfaces';

import Box from '@mui/material/Box';

import {
    CheckTwoTone,
    HourglassFullTwoTone,
    DeleteTwoTone,
    EditTwoTone,
    Assignment,
    EditOffTwoTone,
    LoopTwoTone,
    AddTwoTone,
    AssignmentLateTwoTone,
    AddCircleTwoTone
} from '@mui/icons-material'

import Tooltip from '@mui/material/Tooltip';
import { Button, IconButton } from '@mui/material';
import { NewBatchModal } from '.';
import { ModalWrapper } from 'components';
import { Link } from 'react-router-dom';


interface MixingTankBatchListState {
    // mixingTank?: Await<ReturnType<typeof boumaticApi.api.MixingTanks.GetMixingTank>>
    batches?: Await<ReturnType<typeof boumaticApi.api.Batches.GetBatchByMixingTankId>>
    showNewBatchModal?: boolean
}

interface MixingTankBatchListProps {
    mixingTankId?: string
    tankNo?: number
    activeid?: string
    tank: MixingTank
    onNewBatch: (batch: Batch) => void;
}

export class MixingTankBatchList extends React.Component<
    MixingTankBatchListProps,
    MixingTankBatchListState> {

    state: MixingTankBatchListState = {}

    componentDidMount = () => {
        this.getData();
        boumaticApiSignalR.events.on("batchState", this.handleSignalRBatchStateChange);
    }

    handleSignalRBatchStateChange = (status: BatchProcessingStatus) => {
        console.log('mixingTankBatchList.tsx handleSignalRBatchStateChange')
        this.getData();
    }

    componentWillUnmount = () => {
        boumaticApiSignalR.events.removeListener("batchState", this.handleSignalRBatchStateChange);
    }

    getData = async () => {

        if (!this.props.tank?.id) return;

        // const mixingTank = await boumaticApi.api.MixingTanks
        //     .GetMixingTank({
        //         id: this.props.mixingTankId
        //     });

        // if (!mixingTank.data?.id) return;

        const batches = await boumaticApi.api.Batches.GetBatchByMixingTankId({ mixingTankId: this.props.tank.id })
        // const compatibility = await boumaticApi.api.Configuration.
        this.setState({ batches });
    }



    renderTankState = (props: ICell<Batch>) => {

        let color = undefined;
        let Icon: any = CheckTwoTone;

        if (BatchState[props.value] === "Started") {
            color = "secondary";
            Icon = AddCircleTwoTone;
        }

        if (BatchState[props.value] === "Queued") {
            color = "primary";
            Icon = HourglassFullTwoTone;
        }

        if (BatchState[props.value] === "Completed") {
            color = "success.main";
            Icon = CheckTwoTone;
        }
        if (BatchState[props.value] === "AwaitingQc") {
            color = "secondary";
            Icon = AssignmentLateTwoTone;
        }

        if (BatchState[props.value] === "Mixing") {
            color = "inherit";
            Icon = LoopTwoTone;
            return <Box style={{ color }}>
                <Box color={color} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Tooltip placement="top" title={BatchState[props.value]}>
                        <Typography color="inherit" style={{ textIndent: 0 }}>
                            <i className="far fa-lg fa-spin fa-cog" style={{ paddingTop: 2 }} />
                        </Typography>
                    </Tooltip>
                </Box>
            </Box>
        }

        if (BatchState[props.value] === "Adjusting") {
            color = "secondary";
            Icon = LoopTwoTone;
            return <Box style={{ color }}>
                <Box color={color} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Tooltip placement="top" title={BatchState[props.value]}>
                        <Typography color="inherit" style={{ textIndent: 0 }}>
                            <i className="far fa-lg fa-spin fa-cog" style={{ paddingTop: 2 }} />
                        </Typography>
                    </Tooltip>
                </Box>
            </Box>
        }

        if (BatchState[props.value] === "Evaluated") {
            color = "primary";
            Icon = LoopTwoTone;
            return <Box style={{ color }}>
                <Box color={color} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Tooltip placement="top" title={BatchState[props.value]}>
                        <Typography color="inherit" style={{ textIndent: 0 }}>
                            <i className="far fa-lg fa-spin fa-cog" style={{ paddingTop: 2 }} />
                        </Typography>
                    </Tooltip>
                </Box>
            </Box>
        }

        return <Box style={{ color }}>
            <Typography color={color} sx={{ display: 'flex', flexDirection: 'row' }}>
                <Tooltip placement="top" title={BatchState[props.value]}>
                    <Icon color="inherit" />
                </Tooltip>
            </Typography>
        </Box>
    }



    render() {
        let active = (this.props.tank.active === undefined) ? true : this.props.tank.active;
        let tankNo = (this.props.tankNo !== undefined) ? this.props.tankNo : '';
        let batches = undefined;
        if (this.state.batches?.data === null) batches = [];
        if (this.state.batches?.data) batches = this.state.batches.data.map(b => {
            if (b.batchStateId === BatchState.Mixing) b.queuePlace = -20;
            if (b.batchStateId !== BatchState.Queued) b.queuePlace = -5 + b.queuePlace;
            return b;
        }).sort((a, b) => (a.queuePlace < b.queuePlace) ? -1 : 1);

        if (!this.props.tank) return <Alert>NEED TANK DATA</Alert>

        return <>
            {/* ADD NEW BATCH MODAL */}
            {this.state.showNewBatchModal && <NewBatchModal
                tank={this.props.tank}
                onClose={(batch) => {
                    this.setState({ showNewBatchModal: false });
                    if (batch) { 
                        this.getData(); 
                        this.props.onNewBatch(batch);
                    }
                }} />}

            {/* TANK TABLE */}
            <Paper sx={{ mb: 0, p: 0, opacity: active ? 1 : 0.25 }} elevation={5}>
                <Paper elevation={20}>

                    <Paper 
                    className='mixing_tank_no'
                    elevation={1} sx={{
                        background: 'primary', display: 'flex', flexDirection: 'row',
                        borderBottomLeftRadius: 0, borderBottomRightRadius: 0, p: 0, m: 0
                    }}>
                        <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{ p: 1.25, pl: 2, pr: 0 }}>Tank</Typography>

                        <Typography
                            
                            variant="subtitle2"
                            component="div"
                            sx={{ p: 1.25, pl: 0, fontWeight: 'bold', ml: 2, width: 20 }}
                        >{tankNo}</Typography>

                        <Typography variant="subtitle2"
                            component="div"
                            sx={{ p: 1.25, fontWeight: 'bold', ml: 2 }}
                        >Gallons: {this.props.tank.capacityVolume}</Typography>

                        <Typography variant="subtitle2"
                            component="div"
                            sx={{ p: 1.25, fontWeight: 'bold', ml: 2 }}
                        >Type: {CompatibilityGroups
                            .filter(c => c.id === this.props.tank.compatabilityGroup)[0] ? CompatibilityGroups.filter(c => c.id === this.props.tank.compatabilityGroup)[0].value : 'unknown'}
                        </Typography>

                        <Typography variant="subtitle2"
                            component="div"
                            sx={{ p: 1.25, ml: 2 }}
                        >{!active && "Inactive"}</Typography>

                        <Box sx={{ flex: 1 }} />

                        <Button
                            id="mixing_tank_new_batch"
                            size="small"
                            color="secondary"
                            variant={this.state.showNewBatchModal ? 'contained' : 'text'}
                            onClick={() => {
                                this.setState({ showNewBatchModal: true })
                            }}
                            startIcon={<AddTwoTone />}
                            sx={{ m: 0, pl: 1.25, pr: 2, borderRadius: 0, whiteSpace: 'nowrap' }}
                        >
                            NEW BATCH
                        </Button>

                    </Paper>

                    <Paper elevation={0} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, p: 0, m: 0 }}>
                        <DataGrid<Batch>
                            columns={[{
                                name: 'queuePlace',
                                title: 'QUEUE',
                                width: 30,
                                renderCell: (props) => (props.row.batchStateId === BatchState.Queued) ? <Typography sx={{ ml: 1 }}>{props.value}</Typography> : ''
                            },
                            {
                                name: 'batchStateId',
                                title: "STATE",
                                width: 30,
                                renderCell: this.renderTankState
                            },
                            // {
                            //     name: 'nextPlcStep',
                            //     title: 'nextPLC',
                            //     width: 50,
                            // },
                            { name: 'formulaName', title: "FORMULA", align: "left" },
                            // {
                            //     name: 'weight',
                            //     title: 'Weight',
                            //     width: 130,
                            // },
                            {
                                width: 60,
                                renderCell: (props) => (BatchState[props.row.batchStateId] !== 'Completed')
                                    ? <Tooltip placement="top" title="Edit batch"><EditTwoTone color="secondary" /></Tooltip>
                                    : <Tooltip placement="top" title="Edit disabled on completed"><EditOffTwoTone sx={{ opacity: 0.05 }} /></Tooltip>
                            },
                            {
                                width: 60,
                                renderCell: (props) => <Tooltip placement="top" title="View tickets">
                                    <IconButton component={Link} to={`/batch-instructions/${props.row.id}`}>
                                        <Assignment />
                                    </IconButton>
                                </Tooltip>
                            },
                            {
                                width: 60,
                                renderCell: (props) => <ModalWrapper
                                    dialog={{
                                        title: `Are you sure you want to delete?`,
                                    }}
                                    color={(BatchState[props.row.batchStateId] !== 'Mixing') ? "error" : "disabled"}
                                    disabled={(BatchState[props.row.batchStateId] === 'Mixing')}
                                    icon={DeleteTwoTone}
                                    skipConfirm={false}
                                    theme={boumaticTheme}
                                    onConfirm={async () => {
                                        console.log('deleting batch')
                                        if (props.row.id) {
                                            const result = await boumaticApi.api.Batches.DeleteBatch({ id: props.row.id })
                                            console.log(result);
                                            await this.getData();
                                        }

                                        // console.log(rowExtended);
                                        // let row = rowExtended._datagridrowBefore || _.clone(rowExtended);
                                        // this.props.onDelete({
                                        //     row: row as IExtendedRow<T>,
                                        //     parent: true
                                        // })
                                    }}
                                    tooltipTitle={(BatchState[props.row.batchStateId] !== 'Mixing') ? "Delete batch" : "Delete not allowed"} />
                            }

                            ]}
                            rows={batches}
                            // showHeadings={!!this.state.batches?.data}
                            showHeadings={false}
                            selectedRow={props => props.id === this.props.activeid}
                            pageSize={this.state.batches?.data?.length}
                            to={(props) => `/batchprocess/${props.row.id}/${props.row.mixingTankId}`}
                            emptyMessage="NO BATCHES"
                        />
                    </Paper>
                </Paper>
            </Paper>
        </>
    }

}