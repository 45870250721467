import React from "react";
import { boumaticApi } from "../../App";

import {
    Await,
    DataGrid,
} from 'components';

import { FormulaTolerance } from "../../api/interfaces";
import Box from "@mui/system/Box";
import Paper from "@mui/material/Paper";
import { SxProps, Theme } from "@mui/system";
interface IQualityControlsProps {
    formulaId: string
    sx?: SxProps<Theme>
    editMode?: boolean
}

interface IQualityControlsState {
    qualityControlsResponse?: Await<ReturnType<typeof boumaticApi.api.QualityControls.GetFormulaToleranceByFormulaId>>
    methodsResponse?: Await<ReturnType<typeof boumaticApi.api.Methods.GetMethods>>
}

export class QualityControls extends React.Component<IQualityControlsProps, IQualityControlsState> {
    state: IQualityControlsState = {}
    dataGrid?: DataGrid<FormulaTolerance>
    
    componentDidMount = () => {
        this.getData();
    }

    getData = async () => {

        const [qualityControlsResponse, methodsResponse] = await Promise.all([
            boumaticApi.api.QualityControls.GetFormulaToleranceByFormulaId({ formulaId: this.props.formulaId }),
            boumaticApi.api.Methods.GetMethods(),
        ])

        this.setState({ qualityControlsResponse, methodsResponse });
    }

    render() {
        return <Box id="quality_controls" key={this.props.formulaId} sx={this.props.sx}>
            <Paper elevation={1} sx={{ p: 0, flex: 1 }}>
                <DataGrid<FormulaTolerance>
                    ref={(el) => { if (el) this.dataGrid = el; }}
                    title="Quality Control Checks"
                    addNewButtonText="New QC"
                    columns={[{
                        name: "methodId",
                        title: "ASSAY METHOD",
                        type: "enum",
                        values: this.state.methodsResponse?.data?.sort((a, b) => a.name > b.name ? 1 : -1).map(m => { return { id: m.id, value: m.name }; })
                    },
                    {
                        name: "minimum",
                        title: "MINIMUM",
                    },
                    {
                        name: "maximum",
                        title: "MAXIMUM",
                    },
                    {
                        name: "units",
                        title: "MEASUREMENT UNITS",
                    },
                    ]}
                    allowAddRow={this.props.editMode}
                    // allowEdit
                    editMode={this.props.editMode}
                    allowDelete
                    rows={this.state.qualityControlsResponse?.data}
                    height={300}
                    onChange={async (props) => {
                        props.row.formulaId = this.props.formulaId;
                        const response = await boumaticApi.api.QualityControls.UpsertFormulaTolerance(props.row)
                        if (response.isSuccessful) await this.getData();
                        return response;
                    }}
                    onDelete={async (props) => {
                        console.log('delete quality control...')
                        if (!props.row.id) throw new Error('missing row id');
                        const response = await boumaticApi.api.QualityControls.DeleteFormulaTolerance({ id : props.row.id });
                        if (response.isSuccessful) await this.getData();
                        return response;
                    }}
                />
            </Paper>
        </Box>
    }
}