import React, { Suspense } from "react";

import { Alert, LinearProgress, Typography } from "@mui/material";
import { View, Text } from "@react-pdf/renderer";
import { Formula, FormulaStep, Instruction } from "../../api/interfaces";
import { boumaticApi, boumaticTheme } from "../../App";
import { Await, Report } from 'components'
import { DataGridPDF } from 'components';


export interface IFormulaReportProps {
    formulaId: string
}

export interface IFormulaReportState {
    formulaResponse?: Await<ReturnType<typeof boumaticApi.api.Formulas.GetFormula>>
    chemicalsR?: Await<ReturnType<typeof boumaticApi.api.Chemicals.GetChemicals>>
    stepsR?: Await<ReturnType<typeof boumaticApi.api.FormulaSteps.GetFormulaStepByFormulaId>>
    instructionsR?: Await<ReturnType<typeof boumaticApi.api.Instructions.GetInstructionByFormulaId>>
    productsR?: Await<ReturnType<typeof boumaticApi.api.Products.GetProductByFormulaId>>
    containersR?: Await<ReturnType<typeof boumaticApi.api.Containers.GetContainers>>
    qualityControlsR?: Await<ReturnType<typeof boumaticApi.api.QualityControls.GetFormulaToleranceByFormulaId>>
    methodsR?: Await<ReturnType<typeof boumaticApi.api.Methods.GetMethods>>
}

export class FormulaReport extends React.Component<IFormulaReportProps, IFormulaReportState> {
    state: IFormulaReportState = {}

    componentDidMount = async () => {

        let [formulaResponse, chemicalsR, stepsR, instructionsR, productsR, containersR, qualityControlsR, methodsR] = await Promise.all([
            boumaticApi.api.Formulas.GetFormula({ id: this.props.formulaId }),
            boumaticApi.api.Chemicals.GetChemicals(),
            boumaticApi.api.FormulaSteps.GetFormulaStepByFormulaId({ formulaId: this.props.formulaId }),
            boumaticApi.api.Instructions.GetInstructionByFormulaId({ formulaId: this.props.formulaId }),
            boumaticApi.api.Products.GetProductByFormulaId({ formulaId: this.props.formulaId }),
            boumaticApi.api.Containers.GetContainers(),
            boumaticApi.api.QualityControls.GetFormulaToleranceByFormulaId({ formulaId: this.props.formulaId }),
            boumaticApi.api.Methods.GetMethods()
        ])
        // let formulaResponse = await boumaticApi.api.Formulas.GetFormula({ id: this.props.formulaId });
        this.setState({ formulaResponse, chemicalsR, stepsR, instructionsR, productsR, containersR, qualityControlsR, methodsR });
    }


    render() {

        if (!this.state.formulaResponse) { return <LinearProgress /> }

        if (!this.state.formulaResponse.data) {
            return <Alert severity="error">{this.state.formulaResponse.exceptionMessage}</Alert>
        }




        return <Suspense fallback={<Typography style={{ textDecoration: 'none' }}>loading pdf..</Typography>}>
            <Report
                logoUrl="/logo.png"
                theme={boumaticTheme}
                title={`Formula Report`} // : ${this.props.formulaId}`}
                filename={`boumatic_formulaReport_${this.state.formulaResponse?.data?.name}_${this.state.formulaResponse?.data?.revision}.pdf`}
            // content={[{
            //     title: "Formula"
            // }]}
            >

                <View style={{ paddingTop: 0, paddingBottom: 10 }}>
                    <Text style={{ fontFamily: 'RobotoBold', fontSize: 25 }}>
                        {this.state.formulaResponse?.data?.name}</Text>
                </View>

                <DataGridPDF<Formula>
                    rows={!!this.state.formulaResponse?.data ? [this.state.formulaResponse?.data] : undefined}
                    columns={[
                        {
                            name: "name",
                            title: "NAME",
                            align: 'left',
                            width: '40%'
                        },
                        { name: "skuNumber", title: "SKU", width: '12%' },
                        { name: "specificGravity", title: "SPECIFIC GRAVITY", width: '12%' },
                        { name: "maxVolumePct", title: "MAX VOLUME", width: '12%' },
                        { name: "shelfLife", title: "SHELF LIFE", width: '12%' },
                        { name: "revision", title: "REVISION", width: '12%' },
                    ]}
                />

                <View style={{ paddingTop: 20, paddingBottom: 10 }}>
                    <Text style={{ fontFamily: 'RobotoBold' }}>
                        Recipe Ingredients, Add-Order and Agitation</Text>
                </View>

                <DataGridPDF<FormulaStep>
                    rows={this.state.stepsR?.data}
                    columns={[{
                        name: "chemicalId",
                        title: "CHEMICAL",
                        type: "enum",
                        width: '40%',
                        values: this.state.chemicalsR?.data?.map(c => { return { id: c.id, value: c.name } }),
                    }, {
                        name: "chemicalId",
                        title: "SKU",
                        width: '12%',
                        type: "enum",
                        values: this.state.chemicalsR?.data?.map(c => { return { id: c.id, value: c.skuNumber.toString() } }),
                    },
                    {
                        name: "percentage",
                        title: "PERCENTAGE",
                        width: '12%'
                    },
                    {
                        name: "agitatorRunTime",
                        title: "AGITATOR RUNTIME",
                        width: '12%'
                    },
                    {
                        name: "agitatorFreq",
                        title: "AGITATOR FREQ",
                        width: '12%'
                    },
                    {
                        name: "stepNumber",
                        title: "STEP",
                        width: '12%'
                    }
                    ]}
                />

                <View style={{ paddingTop: 20, paddingBottom: 10 }}>
                    <Text style={{ fontFamily: 'RobotoBold' }}>
                        Special Instructions</Text>
                </View>

                <View style={{ paddingBottom: 10 }}>
                    <Text style={{ fontFamily: 'Roboto', fontSize: 10 }}>
                        {this.state.formulaResponse?.data?.specialInstruction || 'none'}</Text>
                </View>

                <View style={{ paddingTop: 20, paddingBottom: 10 }}>
                    <Text style={{ fontFamily: 'RobotoBold' }}>
                        Formula Instructions</Text>
                </View>

                <DataGridPDF<Instruction>
                    rows={this.state.instructionsR?.data?.map(i => {
                        // remove strange characters:
                        i.procedure = i.procedure?.replace(/[^\w\s]/gi, '');
                        return i;
                    })}
                    columns={[{
                        name: "stepNumber",
                        title: "STEP NO.",
                        width: '12%',
                    }, {
                        name: "procedure",
                        title: "DESCRIPTION",
                        width: '78%',
                    }
                    ]}
                />

            </Report>
        </Suspense>
    }
}