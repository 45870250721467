import React from "react";
import {FormControlLabel, Skeleton, Switch} from "@mui/material";

import { boumaticApi } from '../../App';
import { Await } from 'components';
import { checkAdmin } from "components/utils/oauth/checkAdmin";

export interface IMaintenanceProps {}
export interface IMaintenanceState {
    systemModeResponse?: Await<ReturnType<typeof boumaticApi.api.AppSettings.GetSystemMode>>
}

export class Maintenance extends React.Component<IMaintenanceProps, IMaintenanceState> {
    state: IMaintenanceState = {
        
    }

    componentDidMount = async () => {
        this.getData();
    }

    getData = async () => {
        const systemModeResponse = await boumaticApi.api.AppSettings.GetSystemMode();
        this.setState({ systemModeResponse })
    }

    toggle = async () => {
        let mode = this.state.systemModeResponse?.data;
        if (mode === undefined) { return; }
        mode = (mode === 0) ? 1 : 0;
        const systemModeResponse = await boumaticApi.api.AppSettings.SetSystemMode({ mode });
        this.setState({systemModeResponse});
    }
    
    render() {
        
        if (checkAdmin() === false) return <></>

        if (!this.state.systemModeResponse) return <Skeleton />

        return <FormControlLabel
            control={
                <Switch
                    checked={!!this.state.systemModeResponse.data}
                    value={this.state.systemModeResponse.data}
                    color="secondary"
                    onChange={this.toggle}
                />
            }
            label="Maintenance"
            // style={{ opacity: this.state.systemModeResponse.data ? 1 : 0.5 }}
        />
    }
}