import React from 'react'
import { IColumn } from './interfaces'
import TableCell from "@mui/material/TableCell";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { SearchTwoTone } from '@mui/icons-material';

export interface IColumnFilterProps<T> {
    style?: any
    columns: IColumn<T>[]
    onChange: ( fields: IColumnFilterState<T>["fields"] ) => void
}

export interface IColumnFilterState<T> {
    fields: Partial<T>
}

export class ColumnFilters<T> extends React.Component<IColumnFilterProps<T>, IColumnFilterState<T>> {

    state: IColumnFilterState<T> = {
        fields: {}
    }

    render() {
        return this.props.columns.map((column, index) => {
            let align = column.align || (index === 0 ? "left" : "right");

            return (
                <TableCell
                    // @ts-ignore
                    key={column.name}
                    align={align}
                    style={{ padding: 0, margin: 0 }}
                    width={column.width}
                    color="inherit"
                ><TextField
                        hiddenLabel
                        placeholder={column.title}
                        value={(column.name && this.state.fields[column.name]) ? this.state.fields[column.name] : ""}
                        size="small"
                        onChange={(event) => {
                            if (!column.name) return;
                            let fields = this.state.fields;
                            fields[column.name] = event.target.value as any;
                            this.setState({fields});
                            
                            // emit change data
                            this.props.onChange(fields);
                        }}
                        fullWidth
                        InputProps={{
                            // startAdornment: <InputAdornment position="start"><SearchTwoTone /></InputAdornment>,
                            endAdornment: <InputAdornment position="end"><SearchTwoTone opacity={0.4} /></InputAdornment>,
                            sx: {
                                borderRadius: 0,
                                textAlign: align,
                            }
                        }}
                    />
                </TableCell>
            )
        })
    }
}