import React from "react";
import Typography from "@mui/material/Typography";

import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";

// icons
import {
    RefreshTwoTone as ResetIcon,
    AssignmentTwoTone as GenerateReportIcon,
} from "@mui/icons-material";

//api
import { boumaticApi } from "../../App";
import { AuditTrailSearchArgs, FormulaAuditTrail } from "../../api/interfaces";
import { Await, getDaysFromNow, getFirstDayOfMonth, DataGrid } from 'components';

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/system/Box";
import InputLabel from "@mui/material/InputLabel";
import { FormControl } from "@mui/material";
import { clone } from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";


interface State {
    auditTrailSearchArgs: AuditTrailSearchArgs;
    auditTrailResponse?: Await<
        ReturnType<typeof boumaticApi.api.AuditTrails.ExportFormulaAuditTrail>
    >;
    getFormulasResponse?: Await<
        ReturnType<typeof boumaticApi.api.Formulas.GetFormulas>
    >;
}

const defaultSearchArgs: AuditTrailSearchArgs = {
    startDate: getFirstDayOfMonth(-1).toISOString(),
    endDate: getDaysFromNow(1).toISOString(),
    pageNumber: 1,
    pageSize: 100,
    formulaId: '',
}



export class FormulaAuditTrails extends React.Component<{}, State> {

    state: State = {
        auditTrailSearchArgs: clone(defaultSearchArgs),
        auditTrailResponse: undefined,
    };

    componentDidMount = async () => {
        this.getFormulas();
        this.getAudits();
    };

    getFormulas = async () => {
        const getFormulasResponse = await boumaticApi.api.Formulas.GetFormulas({
            includeInactiveFormulas: true,
        });
        this.setState({ getFormulasResponse });
    };

    getAudits = async () => {
        this.setState({ auditTrailResponse: undefined });
        const auditTrailResponse =
            await boumaticApi.api.AuditTrails.ExportFormulaAuditTrail(
                this.state.auditTrailSearchArgs,
                { onlyUnique: false }
            );
        this.setState({ auditTrailResponse });
    };

    render() {


        return (
            <Paper sx={{ display: "flex", flexGrow: 1, flexDirection: "row", p: 2 }}>
                <Paper style={{ flex: 1 }} elevation={3}>
                    <AppBar position="static" color="primary" enableColorOnDark>
                        <Toolbar variant="dense" color={"primary"}>
                            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                                Formula Audit Trails
                            </Typography>

                            <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                startIcon={<ResetIcon />}
                                sx={{ mr: 2 }}
                                onClick={async () => {
                                    await this.setState({ auditTrailSearchArgs: clone(defaultSearchArgs) });
                                    this.getAudits();
                                }}
                            >
                                Reset
                            </Button>

                            <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                startIcon={<GenerateReportIcon />}
                                sx={{ mr: 2 }}
                                onClick={ async () => {
                                    this.getAudits();
                                }}
                            >
                                Generate Report
                            </Button>
                        </Toolbar>
                    </AppBar>

                    {/* TOP OPTIONS BOX */}
                    <Paper sx={{ m: 1 }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Box sx={{ m: 1, flex: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Start Date"
                                        inputFormat="yyyy/MM/dd"
                                        value={this.state.auditTrailSearchArgs.startDate}
                                        onChange={ async (newStartDate) => {
                                            if (!newStartDate) return;
                                            let auditTrailSearchArgs = this.state.auditTrailSearchArgs;
                                            auditTrailSearchArgs.startDate = new Date(newStartDate).toISOString();
                                            await this.setState({ auditTrailSearchArgs });
                                            this.getAudits();
                                        }}
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>

                            <Box sx={{ m: 1, flex: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="End Date"
                                        inputFormat="yyyy/MM/dd"
                                        value={this.state.auditTrailSearchArgs.endDate}
                                        onChange={async (newEndDate) => {
                                            if (!newEndDate) return;
                                            let auditTrailSearchArgs = this.state.auditTrailSearchArgs;
                                            auditTrailSearchArgs.endDate = new Date(newEndDate).toISOString();
                                            await this.setState({ auditTrailSearchArgs });
                                            this.getAudits();
                                        }}
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>

                            <Box sx={{ m: 1, flex: 1 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Select Formula
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.auditTrailSearchArgs.formulaId}
                                        label="Select Formula"
                                        onChange={async (e) => {
                                            let auditTrailSearchArgs = this.state.auditTrailSearchArgs;
                                            auditTrailSearchArgs.formulaId = e.target.value;
                                            // this.state.auditTrailSearchArgs.formulaId = e.target.value;
                                            await this.setState({ auditTrailSearchArgs });
                                            this.getAudits();
                                        }}
                                        fullWidth
                                    >
                                        {this.state.getFormulasResponse?.data?.map((f) => (
                                            <MenuItem value={f.id} key={f.id}>
                                                {f.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Paper>

                    {/* START AUDIT DATA TABLE */}
                    <DataGrid<FormulaAuditTrail>
                        columns={[
                            {
                                name: "formulaName",
                                title: "FORMULA",
                                align: "left",
                                renderCell: (props) => <Button
                                    variant="text"
                                    color="secondary"
                                    component={Link}
                                    sx={{ pl: 2 }}
                                    to={`/admin/formulas/${props.row.formulaId}`} >{props.value}</Button>
                            },
                            {
                                name: "createdDate",
                                title: "DATE",
                                align: "left",
                                renderCell: (props) => moment(props.value).format('YYYY-MM-DD HH:mm')
                            },                        
                            {
                                name: "description",
                                title: "DESCRIPTION",
                                align: "left"
                            },
                            { 
                                name: "createdBy", title: "BY", align: "left", 
                                renderCell: (props) => props.value ? props.value : JSON.stringify(props.value)    
                            },
                            {
                                name: "propertyName",
                                title: "PROPERTY",
                                align: "left"
                            },
                            {
                                name: "oldValue",
                                title: "OLD VALUE",
                                align: "left"
                            },
                            {
                                name: "newValue",
                                title: "NEW VALUE",
                                align: "left",
                            },

                        ]}
                        rows={this.state.auditTrailResponse?.data}
                    // highlightRow={(props => props.row.id === this.props.activeid)}
                    // selectedRow={(row => row.id === this.props.activeid)}
                    // to={() => "/test"}

                    />
                </Paper>
            </Paper>
        );
    }
}
