
import React from "react";

import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { CoAReportPDF } from "../chemist";

export function CoaReport() {
    let params = useParams<"id">();

    if (!params.id) {
        return <Alert severity="error">Missing formula Id</Alert>
    }

    
    return <CoAReportPDF batchId={params.id} />

}

