
import React from "react";

import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { BatchAuditLogPDF } from "../chemist";


export function BalReport() {
    const {startDate, endDate, referenceId} = useParams();

    if (!startDate) {
        return <Alert severity="error">Missing start date</Alert>
    }

    if (!endDate) {
        return <Alert severity="error">Missing end date</Alert>
    }

    if (!referenceId) {
        return <Alert severity="error">Missing reference ID</Alert>
    }

    
    return <BatchAuditLogPDF startDate={startDate} endDate={endDate} referenceId={referenceId}/>

}

