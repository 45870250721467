import React, { Suspense } from 'react'
import moment from 'moment';
import { LinearProgress, Typography } from '@mui/material';
import { View } from '@react-pdf/renderer';
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";

import { Report, Await } from 'components';
import { DataGridPDF, TitledEntry } from 'components/';

import { boumaticApi, boumaticTheme } from '../../App';
import { BatchReportRawMaterial } from '../../api/interfaces';


// ROUTE
export function BatchTicketPDFRoute(props: any) {
    let params = useParams<"id">();

    if (!params.id) {
        return <Alert severity="error">Missing formula Id</Alert>
    }

    return <BatchTicketPDF id={params.id} />
}

// COMPONENT


interface BatchTicketPDFState {
    batchR?: Await<ReturnType<typeof boumaticApi.api.Batches.GetBatch>>
    formulaR?: Await<ReturnType<typeof boumaticApi.api.Formulas.GetFormula>>
    instructionsR?: Await<ReturnType<typeof boumaticApi.api.Instructions.GetInstructionByFormulaId>>
    batchReportR?: Await<ReturnType<typeof boumaticApi.api.Reports.GetBatchTicketReport>>
}
export class BatchTicketPDF extends React.Component<{ id: string }, BatchTicketPDFState>{
    state: BatchTicketPDFState = {}

    componentDidMount = async () => {
        const batchReportR = await boumaticApi.api.Reports.GetBatchTicketReport({ batchId: this.props.id });
        this.setState({ batchReportR });
        // const batchR = await boumaticApi.api.Batches.GetBatch({ id: this.props.id });
        // this.setState({ batchR });
        // if (!batchR?.data) return;
        // const formulaR = await boumaticApi.api.Formulas.GetFormula({ id: batchR.data.formulaId as string });
        // this.setState({ formulaR });
        // if (!formulaR?.data) return;
        // const instructionsR = await boumaticApi.api.Instructions.GetInstructionByFormulaId({ formulaId: formulaR.data.id as string });
        // this.setState({ instructionsR });
    }

    render() {


        if (this.state.batchReportR && !this.state.batchReportR.data && this.state.batchReportR.exceptionMessage) {
            return <Alert severity="error">BATCHREPORT {this.state.batchReportR.exceptionMessage}</Alert>
        }

        if (!this.state.batchReportR) { return <LinearProgress /> }

        let batchReport = this.state.batchReportR.data;
        if (!batchReport) return <Alert severity="error">BATCHREPORT {this.state.batchReportR.exceptionMessage}</Alert>

        return <Suspense fallback={<Typography style={{ textDecoration: 'none' }}>loading pdf..</Typography>}>
            <Report
                logoUrl="/logo.png"
                title={`BATCH TICKET`} // : ${this.props.formulaId}`}
                filename={`batchTicket.pdf`}
                theme={boumaticTheme}

            >

                <View style={{ paddingTop: 10, width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <View style={{ width: '60%', paddingRight: 40 }}>
                        <TitledEntry title="Formula Name:" value={batchReport.formulaName} />
                        <TitledEntry title="Manufacture Date:" value={moment(batchReport.manufacturingDate).format('MMM D, YYYY, HH:mm')} />
                        <TitledEntry title="Formula #:" value={batchReport.formulaSku} />
                        <TitledEntry title="Expiration Date:" value={batchReport.hasExpiryDate
                            ? moment(batchReport.expiryDate).format('MMM D, YYYY, HH:mm') : '-'} />

                        <TitledEntry title="Shelf Life:" value={batchReport.shelfLife} />
                        <TitledEntry title="Theoritical Yield:" value={batchReport.theoreticalYield} />
                        <TitledEntry title="Bulk Density, #/gal:" value={batchReport.bulkDensity} />
                        <TitledEntry title="Specific Gravity:" value={batchReport.specificGravity} />
                        <TitledEntry title="Start Date:" blank style={{ paddingTop: 20 }} />
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <TitledEntry title="PLC Display Verified By:" blank />
                            <TitledEntry title="Date:" blank style={{ width: '40%' }} />
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <TitledEntry title="Formula Checked By:" blank />
                            <TitledEntry title="Date:" blank style={{ width: '40%' }} />
                        </View>
                    </View>

                    <View style={{ flex: 1, paddingRight: 10 }}>
                        <TitledEntry title="Revision:" value={batchReport.revision} />
                        <TitledEntry title="Current Date:" value={moment(batchReport.currentDate).format('MMM D, YYYY, HH:mm')} />
                        <TitledEntry title="Page:" value={batchReport.page} />
                        <TitledEntry title="Lot Number:" value={batchReport.lotNumber} />
                        <TitledEntry title="Tank Number:" value={batchReport.tankId} />
                        <TitledEntry title="Batch Master:" blank style={{ paddingTop: 85 }} />
                        <TitledEntry title="Completed Date:" blank />
                    </View>
                </View>

                <DataGridPDF<BatchReportRawMaterial>
                    style={{ paddingTop: 30 }}
                    rows={batchReport.rawMaterial}
                    columns={[{
                        name: 'productSku',
                        title: 'SKU',
                        width: '9%',
                    }, {
                        name: 'formulaStepsPercentage',
                        title: 'FORMULA %',
                        width: '10%',
                        renderCell: (props) => props.value.toFixed(2),
                        align: 'right'
                    }, {
                        name: "chemicalName",
                        title: 'RAW MATERIAL',
                        width: '22%',
                    }, {
                        name: 'theoreticalQuantity',
                        title: 'THEORITICAL QUANTITY',
                        width: '13%',
                        align: 'right',
                        renderCell: (props) => props.value.toFixed(2),
                    }, {
                        name: 'actualQuantity',
                        title: 'ACTUAL QUANTITY (LBS.)',
                        width: '13%',
                        align: 'right',
                        renderCell: (props) => props.value.toFixed(2),
                    }, {
                        title: 'LOT NUMBERS',
                        width: '13%',
                    }, {
                        name: 'wgd',
                        title: 'WGD',
                        width: '9%',
                    }, {
                        name: 'ckd',
                        title: 'CKD',
                        width: '9%',
                    }]}
                />

                {/* SINGLE ROW TABLE FOR TOTALS */}
                <DataGridPDF<any>
                    showHeadings={false}
                    style={{ paddingTop: 0 }}
                    rows={[{}]}
                    columns={[{
                        title: 'SKU',
                        width: '9%',
                        renderCell: (props) => 'TOTAL:'
                    }, {
                        title: 'FORMULA %',
                        width: '10%',
                        renderCell: (props) => {
                            let total = 0;
                            if (batchReport) batchReport.rawMaterial.forEach(r => { total += r.formulaStepsPercentage; });
                            return total.toFixed(2);
                        },
                        align: 'right'
                    }, {
                        title: 'RAW MATERIAL',
                        width: '22%',
                    }, {
                        title: 'THEORITICAL QUANTITY',
                        width: '13%',
                        renderCell: (props) => {
                            let total = 0;
                            if (batchReport) batchReport.rawMaterial.forEach(r => { total += r.theoreticalQuantity; });
                            return total.toFixed(2);
                        },
                        align: 'right'
                    }, {
                        title: 'ACTUAL QUANTITY (LBS.)',
                        width: '13%',
                        renderCell: (props) => {
                            let total = 0;
                            if (batchReport) batchReport.rawMaterial.forEach(r => { total += r.actualQuantity; });
                            return total.toFixed(2);
                        },
                        align: 'right'
                    }, {
                        width: '31%',
                        renderCell: (props) => '',
                    }]}
                />

                <TitledEntry title="Filling Instructions:" value={batchReport.fillInstructs || '-'} />

                <TitledEntry title="Reported" value={moment(batchReport.currentDate).format('MMM D, YYYY, HH:mm:ss A')} />
            </Report>
        </Suspense>
    }
}