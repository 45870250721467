import React from "react";
import Paper from "@mui/material/Paper";

import { boumaticApi } from "../../App";
import { Container } from "../../api/interfaces";
import { Await, DataGrid } from 'components';

interface State {
    containersResponse?: Await<ReturnType<typeof boumaticApi.api.Containers.GetContainers>>
}
interface Props { }

export class ContainersList extends React.Component<Props, State> {
    state: State = {}

    componentDidMount = () => {
        this.getContainers();
    }

    getContainers = async () => {
        const containersResponse = await boumaticApi.api.Containers.GetContainers()
        this.setState({ containersResponse });
    }

    render() {
        return <Paper elevation={3}>
            {/* <AppBar position="static" color="primary" enableColorOnDark>
                <Toolbar variant="dense" color={"primary"}>
                    <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                        Containers
                    </Typography>
                </Toolbar>
            </AppBar> */}

            {/* START DATA TABLE */}
            <DataGrid<Container>
                title="Containers"
                columns={[
                    {
                        name: "skuNumber",
                        title: "SKU NO.",
                        type: "number",
                        width: 150,
                    },
                    {
                        name: "name",
                        title: "NAME",
                        align: "left",
                        type: "string"
                    },
                    {
                        name: "capacityVolume",
                        title: "CAPACITY",
                        type: "number",
                        width: 50,
                    },
                    {
                        name: "active",
                        title: "IS ACTIVE",
                        type: "boolean",
                        renderCell: (props) => props.value.toString(),
                        width: 50,
                    },
                ]}
                rows={this.state.containersResponse?.data}
                allowAddRow
                allowDelete
                editMode
                allowEdit
                allowSearch
                showSaveAllButton
                onChange={async (props) => {
                    const result = await boumaticApi.api.Containers.UpsertContainer(props.row)
                    return result;
                }}
                onDelete={async (props) => {
                    if (!props.row.id) return;
                    return await boumaticApi.api.Containers.DeleteContainer({id: props.row.id})
                }}
            />
        </Paper>
    }
}