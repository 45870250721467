import React from 'react'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material'
import { AssignmentTurnedInTwoTone } from '@mui/icons-material'

import { boumaticApi } from '../../App'

import {
    Batch,
    BatchState, BatchStateStepNumber,
    CertificateOfAnalysis,
    CertificateOfAnalysisRequestByFormulaId,
    TestDescription
} from '../../api/interfaces'

import { Await, DataGrid } from 'components'

interface CertificateOfAnalysisProps {
    formulaId?: string
    lotNumber?: string
    batch: Batch
    isEditable?: boolean
}
interface CertificateOfAnalysisState {
    certificate?: Await<ReturnType<typeof boumaticApi.api.CertificateOfAnalysis.GetCertificateOfAnalysisByFormulaId>>
    newCertificate: CertificateOfAnalysis
    showCOAModal: boolean
}



export class CertificateOfAnalysisDisplay extends React.Component<
    CertificateOfAnalysisProps,
    CertificateOfAnalysisState> {

    state: CertificateOfAnalysisState = {
        showCOAModal: false,

        newCertificate: {
            "lotNumber": this.props.batch.lotNumber,
            "mixingTankId": this.props.batch.mixingTankId,
            "formulaId": "",
            "formulaName": "",
            "userId": "",
            "firstName": "",
            "lastSurname": "",
            "testingDate": "",
            "manufactureDate": "",
            "testDescriptions": [],
            "comments": null,
            "id": ""
        }

    }

    lastQuery?: CertificateOfAnalysisRequestByFormulaId;
    iSubmitCOAEnabled?: boolean;


    componentDidMount = () => { this.getData(); }
    // componentDidUpdate = () => { this.getData(); }

    getData = async () => {

        if (!this.props.formulaId || !this.props.lotNumber) return;

        if ((this.props.formulaId !== this.lastQuery?.formulaId)
            || (this.props.lotNumber !== this.lastQuery?.lotNumber)
        ) {

            this.lastQuery = {
                formulaId: this.props.formulaId,
                lotNumber: this.props.lotNumber
            }

            const certificate = await boumaticApi
                .api
                .CertificateOfAnalysis
                .GetCertificateOfAnalysisByFormulaId(this.lastQuery);

            if (certificate?.data) certificate.data.lotNumber = this.props.lotNumber;

            this.setState({ certificate });
            if (certificate.data) {
                let newCertificate = certificate.data;
                newCertificate.mixingTankId = this.props.batch.mixingTankId
                this.setState({ newCertificate });
            }
        }
    }

    renderCOAModal() {
        console.log(this.props.batch.lotNumber);
        return <>
            <Dialog
                open={this.state.showCOAModal}
                onClose={() => { this.setState({ showCOAModal: false }); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Certificate of Analysis
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Certificate of Analysis submitted successfully. Press OK to download report, CLOSE to close.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button type="button" color="inherit" onClick={async () => {
                        window.location.replace('/coa-report/' + this.props.batch.lotNumber);
                    }} autoFocus>
                        OK
                    </Button>

                    <Button color="inherit" onClick={() => { this.setState({ showCOAModal: false }); }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    }

    evaluateBatch = async () => {

        // convert test measuredValues strings to numbers before sending to backend
        let newCertificate = this.state.newCertificate;
        if (newCertificate.testDescriptions) newCertificate.testDescriptions = newCertificate.testDescriptions.map(t => {
            t.measuredValue = parseFloat(`${t.measuredValue}`);
            return t;
        });

        if (
            this.state.newCertificate.testDescriptions?.filter(
                (x) => { return x.isOverridden }).length === 0
        ) {
            const result = await this.updateBatchStatus(this.props.batch, BatchState.Completed)
            if (result.isSuccessful) {
                const result = await boumaticApi.api.CertificateOfAnalysis.UpsertCertificateOfAnalysis(this.state.newCertificate);
                if (result.isSuccessful) {
                    this.setState({ showCOAModal: true });
                }
            }

        }
    }
    shouldButtonEnable = () => {
        if (this.state.newCertificate.testDescriptions) {
            this.iSubmitCOAEnabled = this.state.newCertificate.testDescriptions.filter(
                (x: TestDescription) => !x.isOk
            ).length > 0
        }
    }

    updateBatchStatus = async (batch: Batch, status: BatchState) => {
        return boumaticApi.api.Batches.UpsertBatch({
            id: batch.id,
            weight: batch.weight,
            formulaId: batch.formulaId,
            // productPartNumber: batch.formulaId,
            lotNumber: batch.lotNumber,
            batchVolume: batch.batchVolume,
            batchState: status,
            // cacheDeletedDateUtc: null,
            isEditing: false,
            queuePlace: batch.queuePlace,
            nextPlcStep: batch.nextPlcStep,
            formulaName: batch.formulaName,
            mixingTankId: batch.mixingTankId,
            fillInstructs: batch.fillInstructs,
            //   mixingTankName: batch.mixingTankName,
            formulaMultiplier: batch.formulaMultiplier,
            comments: batch.comments,
            timeStamp: batch.timeStamp,
            batchStateId: 0,
            // mixingTankNo: 0
        })
    }

    validateMeasurements = (r: TestDescription): TestDescription => {
        r.isOk = true;

        // we get it as string from the grid..
        // @ts-ignore
        let measuredValue = parseFloat(r.measuredValue);
        // @ts-ignore
        if (isNaN(measuredValue)) {
            r.isOk = false;
            return r;
        }


        if (measuredValue < r.minimum) r.isOk = false;
        if (measuredValue > r.maximum) r.isOk = false;
        this.shouldButtonEnable();
        return r;
    }

    COAButton() {
        if (parseInt(BatchStateStepNumber[this.props.batch.batchState]) > BatchState.AwaitingQc) {
            return (<>
                <Button
                    sx={{ m: 1 }}
                    size="small"
                    variant="contained"
                    onClick={async () => {
                        window.location.replace('/coa-report/' + this.props.batch.lotNumber);
                    }}
                    startIcon={<AssignmentTurnedInTwoTone />}
                    color="secondary">COA Report</Button>
            </>
            )

        } else {
            return (<>
                <Button
                    // disabled={this.iSubmitCOAEnabled || this.props.isEditable === false}
                    sx={{ m: 1 }}
                    size="small"
                    variant="contained"
                    onClick={async () => {
                        await this.evaluateBatch();
                    }}
                    startIcon={<AssignmentTurnedInTwoTone />}
                    color="secondary">Submit Quality Control</Button>
            </>
            )
        }

    }

    render() {
        return <Paper sx={{ m: 1 }} elevation={5}>
            {this.renderCOAModal()}
            <Paper elevation={1} sx={{ border: "0.1px solid", borderColor: "secondary.main", }}>
                <Paper elevation={10} sx={{
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <Typography color="secondary.main" variant="h6" sx={{ p: 1, pl: 2 }}>Certificate of Analysis</Typography>
                    <Box sx={{ flex: 1 }} />
                    {this.COAButton()}
                </Paper>
                <Box>
                    <DataGrid<CertificateOfAnalysis>
                        columns={[
                            { title: "FORMULA NAME", name: "formulaName" },
                            { title: "LOT", name: "lotNumber" },
                            { title: "TESTING DATE", name: "testingDate" },
                            { title: "MANUFACTURE DATE", name: "manufactureDate" }
                        ]}
                        rows={(this.state.certificate?.data !== undefined) ? [this.state.certificate.data] : undefined}
                    />

                    <Paper elevation={10} sx={{ mt: 2 }}>
                        <DataGrid<TestDescription>
                            columns={[
                                {
                                    title: "OK?",
                                    name: "isOk",
                                    renderCell: (props) => props.value
                                        ? <Typography color="success.main" sx={{ padding: 1 }}>PASS</Typography>
                                        : <Typography color="error.main" sx={{ padding: 1 }}>FAIL</Typography>
                                },
                                { title: "METHOD", name: "method" },
                                { title: "MINIMUM", name: "minimum" },
                                { title: "MEASURED", name: "measuredValue", editable: this.props.isEditable },
                                { title: "MAXIMUM", name: "maximum" },
                                { title: "MEASUREMENT UNITS", name: "units" }
                            ]}
                            rows={this.state.newCertificate.testDescriptions?.map(r => this.validateMeasurements(r))}
                            onEdit={async (props) => {
                                if (!props.rows) return;
                                if (!this.state.newCertificate) return;

                                // UPDATE PASS/FAIL
                                this.state.newCertificate.testDescriptions = props.rows.map(r => this.validateMeasurements(r));

                                console.log(this.state.newCertificate)
                                this.setState({});
                            }}
                        />
                    </Paper>
                </Box>

                {/* <pre>{JSON.stringify(this.state.newCertificate, null, 2)}</pre> */}
            </Paper>
        </Paper>
    }
}