import React from "react";
import {clone} from "lodash";
import moment from "moment";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import {Await, DataGrid, getFirstDayOfMonth} from 'components';

import {boumaticApi} from "../../App";
import {AuditTrailResponse, PagedRequest} from "../../api/interfaces";



interface State {
    searchArgs: PagedRequest;

    getAuditTrailsResponse?: Await<ReturnType<
        typeof boumaticApi.api.AuditTrails.GetAuditTrails>>;


    formulaId?: string
    startDate?: string
    endDate?: string
    referenceId?: string
}

interface AuditTrailProps {
    id?: string
}

const defaultSearchArgs: PagedRequest = {
    PageNumber: 1,
    PageSize: 999
}

export class AuditTrailLogList extends React.Component<AuditTrailProps> {
    state: State = {
        searchArgs: clone(defaultSearchArgs),
        getAuditTrailsResponse: undefined,
        formulaId: undefined,
        startDate: getFirstDayOfMonth(-12).toISOString(),
        endDate: new Date().toISOString(),
    };

    componentDidMount = async () => {
        //let params = useParams<"id">();
        this.getAuditTrails();
    };


    getAuditTrails = async () => {
        const getAuditTrailsResponse = await boumaticApi.api.AuditTrails.GetAuditTrails({
            // OrderByQuery: "createdDate DESC",
            PageNumber: defaultSearchArgs.PageNumber,
            // SelectQuery: "",
            WhereQuery:  `audit => audit.ReferenceId.Equals("${this.props.id}")`,
            PageSize: defaultSearchArgs.PageSize,
        });

        this.setState({ getAuditTrailsResponse });
    };



    render() {


        return (
            <Paper sx={{ display: "flex", flexGrow: 1, flexDirection: "row", p: 2 }}>
                <Paper style={{ flex: 1 }} elevation={3}>
                    <AppBar position="static" color="primary" enableColorOnDark>
                        <Toolbar variant="dense" color={"primary"}>
                            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                                Audit Trail Log
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    {/* TOP OPTIONS BOX */}

                    {/* START AUDIT DATA TABLE */}
                    <DataGrid<AuditTrailResponse>
                        columns={[
                            {
                                name: "actionDate",
                                title: "DATE",
                                renderCell: (props) => moment(props.value).format('YYYY-MM-DD HH:mm')
                            },
                            {
                                name: "action",
                                title: "ACTION",
                            },
                            {
                                name: "actionedBy",
                                title: "ACTIONED BY",
                            },
                            {
                                name: "propertyName",
                                title: "ITEM CHANGED",
                            },
                            {
                                name: "oldValue",
                                title: "PREV. VALUE",
                            },
                            {
                                name: "newValue",
                                title: "NEW VALUE",
                            }
                        ]}
                        rows={this.state.getAuditTrailsResponse?.data}
                        // highlightRow={(props => props.row.id === this.props.activeid)}
                        // selectedRow={(row => row.id === this.props.activeid)}
                        //to={() => "/test"}
                        pageSize={100}
                        height={600}
                      
                    />
                    
                </Paper>
            </Paper>
        );
    }
}
