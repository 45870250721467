import {Alert, LinearProgress, Typography} from "@mui/material";
import {Text, View} from "@react-pdf/renderer";
import React, {Suspense} from "react";
import {AuditTrail} from "../../api";
import {boumaticApi, boumaticTheme} from "../../App";
import {Await, Report, DataGridPDF} from 'components'
import Moment from 'moment';


export interface IBalReportProps {
    startDate: string;
    endDate: string;
    referenceId: string;
}

export interface BatchDisplayAuditLog {
    weight?: number;
    lotNumber?: string;
    batchState?: string;
    tank: string
}

export interface IBalReportPDFState {
    balResponse?: Await<ReturnType<typeof boumaticApi.api.AuditTrails.GetBatchAuditReport>>

}

export class BatchAuditLogPDF extends React.Component<IBalReportProps, IBalReportPDFState> {
    state: IBalReportPDFState = {}


    componentDidMount = async () => {



        let [balResponse
        ] = await Promise.all([
            boumaticApi.api.AuditTrails.GetBatchAuditReport({ startDate: this.props.startDate, endDate: this.props.endDate, referenceId: this.props.referenceId })

        ])
        this.setState({balResponse
        });
    }


    render() {
        if (!this.state.balResponse) { return <LinearProgress /> }



        let displayAuditLog: BatchDisplayAuditLog = {batchState: this.state.balResponse.data?.batchAuditLog.batchState,
            weight: this.state.balResponse.data?.batchAuditLog.weight,
            lotNumber: this.state.balResponse.data?.batchAuditLog.lotNumber,
            tank: 'Tank'}

        let auditTrails: AuditTrail[] | undefined  = this.state.balResponse.data?.auditTrails;

        if (auditTrails){
            for (let i in auditTrails )
                auditTrails[i].actionDate = Moment(auditTrails[i].actionDate).format('MM-DD-YYYY HH:mm:ss')
        }

        if (!this.state.balResponse.data) {
            return <Alert severity="error">{this.state.balResponse.exceptionMessage}</Alert>
        }




        return <Suspense fallback={<Typography style={{ textDecoration: 'none' }}>loading pdf..</Typography>}>
            <Report
                logoUrl="/logo.png"
                title={`BATCH AUDIT LOG`} // : ${this.props.formulaId}`}
                filename={`coareport.pdf`}
                theme={boumaticTheme}

                // content={[{
                //     title: "Formula"
                // }]}
            >

                <View style={{ paddingTop: 10 }}>
                    <Text style={{ fontFamily: 'RobotoBold', fontSize: 25 }}>
                        {this.state.balResponse.data.batchAuditLog.formulaName}</Text>
                </View>
                <div style={{paddingTop: 20}}>

                    <DataGridPDF<BatchDisplayAuditLog>
                        rows={!!this.state.balResponse?.data.batchAuditLog ? [displayAuditLog] : undefined}
                        columns={[
                            {
                                name: 'tank',
                                title: "TANK",
                                align: 'left',
                                width: '25%'
                            },
                            { name: "lotNumber", title: "LOT NUMBER", width: '25%' },
                            { name: "batchState", title: "STATE", width: '25%' },
                            { name: "weight", title: "WEIGHT", width: '25%' }
                        ]}
                    />
                </div>

                <hr/>

                <div style={{marginTop: 30}}>

                    <DataGridPDF<AuditTrail>
                        rows={this.state.balResponse?.data.auditTrails}
                        columns={[
                            {
                                name: "lotNumber",
                                title: "LOT NO",
                                width: '10%',
                                type: "enum",
                            },
                            {
                                name: "actionDate",
                                title: "DATE",
                                width: '13%',
                                type: "enum",
                            },
                            {
                                name: "description",
                                title: "ACTION",
                                width: '18%'
                            },
                            {
                                name: "actionedBy",
                                title: "ACTIONED BY",
                                width: '20%'
                            },
                            {
                                name: "propertyName",
                                title: "ITEM CHANGED",
                                width: '15%'
                            },
                            {
                                name: "oldValue",
                                title: "PREV. VALUE",
                                width: '12%'
                            },
                            {
                                name: "newValue",
                                title: "NEW VALUE",
                                width: '12%'
                            }
                        ]}
                    />

                </div>


            </Report>
        </Suspense>
    }
}