import React from "react";
import Box from "@mui/system/Box";
import Paper from "@mui/material/Paper";
import { SxProps, Theme } from "@mui/system";

import { boumaticApi } from "../../App";

import {
    Await,
    DataGrid,
} from 'components';

import { Instruction } from "../../api/interfaces";


interface Props {
    formulaId: string
    sx?: SxProps<Theme>
    editMode?: boolean
}

interface State {
    instructionResponse?: Await<ReturnType<typeof boumaticApi.api.Instructions.GetInstructionByFormulaId>>
}

export class FormulaInstructions extends React.Component<Props> {
    state: State = {}
    dataGrid?: DataGrid<Instruction>

    componentDidMount = async () => {
        this.getData();
    }

    getData = async () => {
        if (this.props.formulaId) {
            const instructionResponse = await boumaticApi.api.Instructions.GetInstructionByFormulaId({ formulaId: this.props.formulaId });
            this.setState({ instructionResponse });
        }
    }

    render() {
        return <Box id="formula_instructions" key={this.props.formulaId} sx={this.props.sx}>
            <Paper elevation={1} sx={{ p: 0, flex: 1 }}>
                <DataGrid<Instruction>
                    ref={(el) => { if (el) this.dataGrid = el; }}
                    title="Formula Instructions"
                    addNewButtonText="New Instruction"
                    allowAddRow={this.props.editMode}
                    // allowEdit
                    editMode={this.props.editMode}
                    allowDelete
                    allowImport={this.props.editMode}
                    sortBy="stepNumber"
                    columns={[{
                        name: "stepNumber",
                        title: "STEP NO.",
                        width: 50,
                    },
                    {
                        name: "procedure",
                        title: "DESCRIPTION",
                        align: "left",
                    },
                    ]}
                    rows={this.state.instructionResponse?.data?.map(i => {
                        // remove strange characters:
                        i.procedure = i.procedure?.replace(/[^\w\s]/gi, '');
                        return i;
                    })}
                    onNewRow={async (props) => {
                        // calculate next step number
                        let newStepNumber = 1; // default;
                        props.rows?.forEach(r => {
                            if (r.stepNumber >= newStepNumber) {
                                newStepNumber = r.stepNumber + 1;
                            }
                        })

                        let newInstruction: Instruction = {
                            id: "",
                            stepNumber: newStepNumber,
                            formulaId: this.props.formulaId,
                            procedure: props.options?.line,            
                        }

                        return newInstruction;
                    }}
                    onChange={async (props) => {
                        const response = await boumaticApi.api.Instructions.UpsertInstruction(props.row);
                        return response;
                    }}
                    onDelete={async (props) => {
                        const response = await boumaticApi.api.Instructions.DeleteInstruction(props.row);

                        return response;
                    }}
                // height={300}
                />
            </Paper>
        </Box>
    }
}