import React from "react";
import { boumaticApi } from "../../App";

import {
    Await,
    DataGrid,
} from 'components';


import { Product } from "../../api/interfaces";
import Box from "@mui/system/Box";
import Paper from "@mui/material/Paper";
import { SxProps, Theme } from "@mui/system";

export interface IProductsListProps {
    formulaId: string
    sx?: SxProps<Theme>
    editMode?: boolean
}

export interface IProductsListState {
    productsResponse?: Await<ReturnType<typeof boumaticApi.api.Products.GetProductByFormulaId>>
    containersResponse?: Await<ReturnType<typeof boumaticApi.api.Containers.GetContainers>>
}

export class ProductsList extends React.Component<IProductsListProps> {
    state: IProductsListState = {}
    dataGrid?: DataGrid<Product>;

    componentDidMount = async () => {
        this.getData();
    }

    getData = async () => {
        const [productsResponse, containersResponse] = await Promise.all([
            boumaticApi.api.Products.GetProductByFormulaId({ formulaId: this.props.formulaId }),
            boumaticApi.api.Containers.GetContainers()
        ]);
        this.setState({ productsResponse, containersResponse });
    }

    render() {
        return <Box id="formula_products_list" key={this.props.formulaId} sx={this.props.sx}>
            <Paper elevation={1} sx={{ p: 0 }}>
                <DataGrid<Product>
                    ref={(el) => { if (el) this.dataGrid = el; }}
                    title="Products"
                    key={this.props.formulaId}
                    addNewButtonText="New Product"
                    allowAddRow={this.props.editMode}
                    allowDelete
                    // allowEdit
                    editMode={this.props.editMode}
                    columns={[{
                        name: "skuNumber",
                        title: "SKU NO.",
                        width: 50
                    },
                    {
                        name: "containerId",
                        title: "Container",
                        type: "enum",
                        values: this.state.containersResponse?.data?.map(c => { return { id: c.id || '', value: c.name } })
                        // renderCell: (props) => <ContainerName id={props.row.containerId} />
                    }
                    ]}
                    rows={this.state.productsResponse?.data}
                    onChange={async (props) => {
                        props.row.formulaId = this.props.formulaId;
                        const result = await boumaticApi.api.Products.UpsertProduct(props.row);
                        this.getData();
                        return result;
                    }}
                    onDelete={async (props) => {
                        if (!props.row.id) throw new Error('missing id');
                        const result = await boumaticApi.api.Products.DeleteProduct({ id : props.row.id });
                        this.getData();
                        return result;
                    }}
                    height={300}
                />
            </Paper>
        </Box>
    }
}