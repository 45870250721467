import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Alert, LinearProgress, Typography } from "@mui/material";
import { Text, View } from "@react-pdf/renderer";

import { Containers } from "../../api";
import { boumaticApi, boumaticTheme } from "../../App";
import { Await, Report, TitledEntry, DataGridPDF } from 'components'

export interface IFillingReportProps {
    formulaId: string;
}

export interface IFillingReportPDFState {
    fillingResponse?: Await<ReturnType<typeof boumaticApi.api.Reports.GetFillTicketReport>>

}

// ROUTE
export function FillingReportPDFRoute(props: any) {
    let params = useParams<"formulaId">();

    if (!params.formulaId) {
        return <Alert severity="error">Missing formula Id</Alert>
    }

    return <FillingReportPDF formulaId={params.formulaId} />
}


export class FillingReportPDF extends React.Component<IFillingReportProps, IFillingReportPDFState> {
    state: IFillingReportPDFState = {}


    componentDidMount = async () => {



        let [fillingResponse
        ] = await Promise.all([
            boumaticApi.api.Reports.GetFillTicketReport({ formulaId: this.props.formulaId })

        ])
        this.setState({
            fillingResponse
        });
    }


    render() {
        if (!this.state.fillingResponse) { return <LinearProgress /> }

        if (!this.state.fillingResponse.data) {
            return <Alert severity="error">{this.state.fillingResponse.exceptionMessage}</Alert>
        }



        return <Suspense fallback={<Typography style={{ textDecoration: 'none' }}>loading pdf..</Typography>}>
            <Report
                logoUrl="/logo.png"
                title={`FILL TICKET`} // : ${this.props.formulaId}`}
                filename={`coareport.pdf`}
                theme={boumaticTheme}

            // content={[{
            //     title: "Formula"
            // }]}
            >

                {/*  <View style={{ paddingTop: 10 }}>
                    <Text style={{ fontFamily: 'RobotoBold', fontSize: 25 }}>
                        {this.state.fillingResponse.data.tankId}</Text>
                </View>*/}
                <div style={{ paddingTop: 20 }}>

                    {/*<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10}}>
                        <div>
                            <View style={{display: "flex", flexDirection: "row", marginBottom: 5}}>
                                <Text style={{ fontFamily: 'RobotoBold', fontSize: 12 }}>
                                    Created Date: </Text>
                                <Text style={{ fontFamily: 'Roboto', fontSize: 12 }}>{Moment(this.state.fillingResponse?.data.createdDate).format('MM-DD-YYYY HH:mm:ss')}</Text>
                            </View>
                            <View style={{display: "flex", flexDirection: "row", marginBottom: 5}}>
                                <Text style={{ fontFamily: 'RobotoBold', fontSize: 12 }}>
                                    Product Formula: </Text>
                                <Text style={{ fontFamily: 'Roboto', fontSize: 12 }}>{this.state.fillingResponse?.data.formulaName}</Text>

                            </View>
                            <View style={{display: "flex", flexDirection: "row", marginBottom: 5}}>
                                <Text style={{ fontFamily: 'RobotoBold', fontSize: 12 }}>
                                    Assigned to Tank: </Text>
                                <Text style={{ fontFamily: 'Roboto', fontSize: 12 }}>{this.state.fillingResponse?.data.tankId}</Text>

                            </View>
                            <View style={{display: "flex", flexDirection: "row", marginBottom: 5}}>
                                <Text style={{ fontFamily: 'RobotoBold', fontSize: 12 }}>
                                    Batch Lot: </Text>
                                <Text style={{ fontFamily: 'Roboto', fontSize: 12 }}>{this.state.fillingResponse?.data.batchLotNumber}</Text>

                            </View>
                        </div>
                        <div>
                            <View style={{display: "flex", flexDirection: "row", marginBottom: 5}}>
                                <Text style={{ fontFamily: 'RobotoBold', fontSize: 12 }}>
                                    Expiration Date: </Text>
                                <Text style={{ fontFamily: 'Roboto', fontSize: 12 }}>{ Moment(this.state.fillingResponse?.data.expiryDate).format('MM-DD-YYYY HH:mm:ss')}</Text>

                            </View>
                            <View style={{display: "flex", flexDirection: "row", marginBottom: 5}}>
                                <Text style={{ fontFamily: 'RobotoBold', fontSize: 12 }}>
                                    Formula SKU: { this.state.fillingResponse?.data.formulaSku}</Text>
                                <Text style={{ fontFamily: 'Roboto', fontSize: 12 }}>{ this.state.fillingResponse?.data.formulaSku}</Text>

                            </View>
                            <View style={{display: "flex", flexDirection: "row", marginBottom: 5}}>

                                <Text style={{ fontFamily: 'RobotoBold', fontSize: 12 }}>
                                    Batch Volume (gals): </Text>
                                <Text style={{ fontFamily: 'Roboto', fontSize: 12 }}>{ this.state.fillingResponse?.data.batchVolume}</Text>

                            </View>
                            <View style={{display: "flex", flexDirection: "row", marginBottom: 5}}>

                                <Text style={{ fontFamily: 'RobotoBold', fontSize: 12 }}>
                                    Formula specific gravity: </Text>
                                <Text style={{ fontFamily: 'Roboto', fontSize: 12 }}>{ this.state.fillingResponse?.data.formulaSpecificGravity}</Text>

                            </View>
                        </div>


                    </div>*/}
                    <View style={{ paddingTop: 10, width: '100%', display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '60%', paddingRight: 40 }}>
                            <TitledEntry title="Created Date:" value={this.state.fillingResponse?.data.createdDate
                                ? moment(this.state.fillingResponse?.data.createdDate).format('MMM D, YYYY, HH:mm') : '-'} />
                            <TitledEntry title="Product Formula:" value={this.state.fillingResponse?.data.formulaName} />
                            <TitledEntry title="Assigned to Tank:" value={this.state.fillingResponse?.data.tankId} />
                            <TitledEntry title="Batch Lot:" value={this.state.fillingResponse?.data.batchLotNumber} />


                        </View>

                        <View style={{ flex: 1, paddingRight: 10 }}>
                            <TitledEntry title="Expiration Date:" value={this.state.fillingResponse?.data.expiryDate
                                ? moment(this.state.fillingResponse?.data.expiryDate).format('MMM D, YYYY, HH:mm') : '-'} />
                            <TitledEntry title="Formula SKU:" value={this.state.fillingResponse?.data.formulaSku} />
                            <TitledEntry title="Batch Volume (gals):" value={this.state.fillingResponse?.data.batchVolume} />
                            <TitledEntry title="Formula specific gravity:" value={this.state.fillingResponse?.data.formulaSpecificGravity} />
                        </View>
                    </View>



                </div>

                <hr />

                <div style={{ marginTop: 30 }}>

                    <DataGridPDF<Containers>
                        rows={this.state.fillingResponse?.data.containers}
                        columns={[
                            {
                                name: "containerSize",
                                title: "CONTAINER SIZE",
                                width: '20%',
                                type: "enum",
                            },
                            {
                                name: "productSku",
                                title: "PRODUCT SKU",
                                width: '15%',
                                type: "enum",
                            },
                            {
                                name: "quantity",
                                title: "QTY (EA)",
                                width: '10%'
                            },
                            {
                                name: "actualQuantity",
                                title: "ACTUAL QTY (EA)",
                                width: '25%'
                            },
                            {
                                name: "workOrder",
                                title: "W/O#",
                                width: '30%'
                            }
                        ]}
                    />

                </div>
                <div style={{}}>

                    <View style={{ marginTop: 10 }}>
                        <Text style={{ fontFamily: 'Roboto', fontSize: 12 }}>
                            Special instructions:</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <TitledEntry title="WIP Amount (lbs):" blank />
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <TitledEntry title="Total Yield:" blank />
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text style={{ fontFamily: 'RobotoBold', fontSize: 12 }}>
                            Yield = (# gallons filled X specific gravity X 8.33)/(lbs. made - lbs. WIP)</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <TitledEntry title="Initial when filling complete:" blank />
                        <TitledEntry title="Date:" blank style={{ width: '40%' }} />
                    </View>
                    <div style={{ border: "1px black solid", padding: 20, marginTop: 30, textAlign: "center" }}>

                        <View>
                            <Text style={{ fontFamily: 'RobotoBold', fontSize: 12 }}>
                                Quality Control Approvals</Text>

                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                            <TitledEntry title="Label approval:" blank />
                            <TitledEntry title="Date:" blank style={{ width: '40%' }} />
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                            <TitledEntry title="Batch release for packaging, released By:" blank />
                            <TitledEntry title="Date:" blank style={{ width: '40%' }} />
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                            <TitledEntry title="Finished product release, released By:" blank />
                            <TitledEntry title="Date:" blank style={{ width: '40%' }} />
                        </View>

                    </div>

                </div>


                <Text style={{ fontFamily: 'Roboto', fontSize: 12, marginTop: 5 }}>
                    Reported: {moment(this.state.fillingResponse?.data.createdDate).format('MM-DD-YYYY HH:mm:ss')}</Text>

            </Report>
        </Suspense>
    }
}