import React from 'react';
import Button from '@mui/material/Button';
import { Alert, AppBar, LinearProgress, Modal, Paper, Toolbar, Typography } from '@mui/material';
import { boumaticApi } from '../../App';
import { Await, DataGrid } from 'components';
import {
    Batch,
    CompatibilityGroups,
    MixingTank,
    Product,
    Container,
    BatchState,
    ProductQuantity,
    FormulaState
} from '../../api';
import Box from '@mui/material/Box'
import { CheckTwoTone, CloseTwoTone, EditTwoTone } from '@mui/icons-material';
import { TextArea } from 'components';
import { Link } from 'react-router-dom'

interface NewBatchModalState {
    formulasR?: Await<ReturnType<typeof boumaticApi.api.Formulas.GetFormulas>>
    mixingTanksR?: Await<ReturnType<typeof boumaticApi.api.MixingTanks.GetMixingTanks>>
    // productsR?: CloudServiceActionResult<ProductsGridEntry[]> // Await<ReturnType<typeof boumaticApi.api.Products.GetProductByFormulaId>>
    containersR?: Await<ReturnType<typeof boumaticApi.api.Containers.GetContainers>>
    newBatch: NewBatchForm
    tank?: MixingTank
    totalConsumed: number

    /** volume not yet allocated to a container. We display error alerts if this gets below zero. */
    volumeNotAllocated: number

    /** if we add a new batch this will provide the result and id if successful */
    newBatchAddedResponse?: Await<ReturnType<typeof boumaticApi.api.Batches.UpsertBatch>>

    busyAddingBatch: boolean
    busyUpsertProductQuantities: boolean

    quantityRows?: QuantityRows[];

    upsertProductQuantitiesResponse?: Await<ReturnType<typeof boumaticApi.api.ProductQuantity.UpsertProductQuantities>>
}
interface NewBatchModalProps {
    tank: MixingTank
    onClose: (batch?: Batch) => void
}


interface NewBatchForm extends Partial<Batch> {
    batchVolume: number
    volumeNotAllocated: number;
    specialInstructions?: string
    totalConsumed?: number
    notes?: string
}

interface QuantityRows extends ProductQuantity, Product { }

export class NewBatchModal extends React.Component<NewBatchModalProps, NewBatchModalState> {
    state: NewBatchModalState = {
        newBatch: {
            id: "",
            formulaId: "",
            batchVolume: Math.round(this.props.tank.capacityVolume * 0.95),
            volumeNotAllocated: 0,
            mixingTankNo: this.props.tank.tankNo,
            mixingTankId: this.props.tank.id,
            batchState: BatchState.Started
        },
        totalConsumed: 0,
        volumeNotAllocated: 0,
        busyAddingBatch: false,
        busyUpsertProductQuantities: false
    }

    componentDidMount = () => {
        this.getData();
    }

    getData = async () => {
        const [formulasR, mixingTanksR, containersR] = await Promise.all([
            boumaticApi.api.Formulas.GetFormulas({ includeInactiveFormulas: false }),
            boumaticApi.api.MixingTanks.GetMixingTanks(),
            boumaticApi.api.Containers.GetContainers()
        ]);

        this.setState({ formulasR, mixingTanksR, containersR })
    }

    getProducts = async () => {
        this.setState({ quantityRows: undefined });

        if (!this.state.newBatch.formulaId) return;
        const productsR = await boumaticApi.api.Products.GetProductByFormulaId({ formulaId: this.state.newBatch.formulaId });


        if (productsR?.data) {
            let quantityRows: QuantityRows[] = productsR?.data.sort((a, b) => a.skuNumber - b.skuNumber)
                .filter(this.filterProducts).map((i) => {
                    let newQuantityRow: QuantityRows = {
                        quantity: 0,
                        productId: i.id,
                        formulaId: i.formulaId,
                        containerId: i.containerId,
                        queuePlace: i.queuePlace,
                        skuNumber: i.skuNumber,
                        active: i.active
                    }

                    return newQuantityRow;
                })

            this.setState({ quantityRows });
        }

    }

    handleClose = () => {
        this.props.onClose();
    }

    /** removed products with a nonexisting container */
    filterProducts = (product: Product) => {
        let a = this.state.containersR?.data?.filter(c => c.id === product.containerId);
        if (!a || a.length === 0) return false;
        return true;
    }

    getContainer = (id: string): Container => {
        // get this container data
        let a = this.state.containersR?.data?.filter(c => c.id === id);

        if (!a || a.length < 1) return {
            active: false,
            capacityVolume: 0,
            id,
            name: `error not found ${id}`,
            skuNumber: 0
        };

        let container = a[0];
        return container
    }

    getTankCompatibilityGroup = () => {
        return CompatibilityGroups.filter(c => c.id === this.props.tank.compatabilityGroup)[0].value;
    }

    showBatchUpsertProgress = () => {
        if (this.state.busyAddingBatch) return <>
            <Alert severity="info">Please wait adding new batch...</Alert>
            <LinearProgress color="secondary" />
        </>

        if (this.state.newBatchAddedResponse?.isSuccessful === true) return <>
            <Alert severity="success">{this.state.newBatchAddedResponse?.message} id: {this.state.newBatchAddedResponse?.data?.id}</Alert>
        </>

        if (this.state.newBatchAddedResponse?.isSuccessful === false) return <>
            <Alert severity="error">ERROR: {this.state.newBatchAddedResponse?.exceptionMessage}</Alert>
        </>
    }

    renderModalForm = () => {

        if (!this.state.containersR?.data) return <LinearProgress />

        return <Box sx={{ pb: 0.01 }}>
            {this.showBatchUpsertProgress() || <>
                <Paper elevation={0} sx={{ p: 1, m: 1 }}>
                    <DataGrid<Partial<NewBatchForm>>
                        rows={[this.state.newBatch]}
                        title="FORMULA SELECTION"
                        columns={[
                            {
                                name: 'formulaId',
                                title: 'FORMULA',
                                type: "enum",
                                placeholder: "Select Formula",
                                values: this.state.formulasR?.data?.filter(f => { if ((f.state !== undefined) && (f.state === FormulaState.Approved)) return true; })
                                    .filter(f => {
                                        // VIRTUAL TANK ALLOWS ALL FORMULAS
                                        const compatibilityGroupString = CompatibilityGroups.filter(c => c.id === this.props.tank.compatabilityGroup)[0].value;
                                        if (compatibilityGroupString === 'Virtual') return true;

                                        // OR FILTER BY COMPATIBILITY
                                        return f.compatabilityGroup === this.props.tank.compatabilityGroup
                                    }).map(f => ({ id: f.id, value: f.name })),
                                onRowChange: async (props) => {
                                    // if a different formula was selected update the products list.
                                    if (props.row.formulaId !== this.state.newBatch.formulaId) {

                                        // get formula
                                        const formula = this.state.formulasR?.data?.filter(f => f.id === props.row.formulaId)[0]

                                        let newBatch = this.state.newBatch;
                                        newBatch.formulaId = props.row.formulaId;
                                        newBatch.specialInstructions = formula?.specialInstruction as string;
                                        this.setState({ newBatch });

                                        this.getProducts();
                                    }
                                },
                                editable: true
                            },
                            {
                                name: 'mixingTankNo',
                                title: 'MIXING TANK',
                                type: "enum",
                                values: this.state.mixingTanksR?.data?.map(t => ({ id: t.tankNo, value: `Tank ${t.tankNo} (${t.capacityVolume})` })),
                            },
                            {
                                name: 'batchVolume',
                                title: 'VOLUME IN GALLONS',
                                width: 50,
                                editable: true,
                            },
                            {
                                name: 'volumeNotAllocated',
                                title: 'VOLUME NOT ALLOCATED',
                                width: 50,
                                renderCell: (props) => {
                                    if (props.row.batchVolume === undefined) return 'error'


                                    let totalConsumedVolume = 0;

                                    this.state.quantityRows?.forEach(r => {
                                        if (!r.containerId) return 0;
                                        let container = this.getContainer(r.containerId);
                                        let consumed = container.capacityVolume * (r.quantity || 0);
                                        totalConsumedVolume += consumed;
                                    })

                                    let volumeNotAllocated = props.row.batchVolume - totalConsumedVolume;

                                    // update if changed.
                                    if (this.state.volumeNotAllocated !== volumeNotAllocated) this.setState({ volumeNotAllocated, totalConsumed: totalConsumedVolume });

                                    return <Typography color={this.state.volumeNotAllocated < 0 ? 'error' : 'inherit'}>
                                        {volumeNotAllocated}
                                    </Typography>
                                }
                            }
                        ]}
                        allowDelete={false}
                        sx={{ mb: 2 }}
                    // onEdit={async (props) => {
                    //     let newBatch = props.row;
                    //     delete newBatch._datagridrowId;
                    //     this.setState({ newBatch: newBatch as NewBatchForm });
                    // }}
                    />
                </Paper>

                {this.state.newBatch.formulaId && <Box sx={{ ml: 1 }}>
                    <Button color="primary"
                        variant="text"
                        startIcon={<EditTwoTone />}
                        component={Link}
                        to={`/admin/formulas/${this.state.newBatch.formulaId}`}
                    >EDIT FORMULA</Button>
                </Box>}
            </>
            }

            {this.state.volumeNotAllocated < 0 && <Alert severity='error'>
                Allocated more container volume than batch volume to be mixed. Increase Batch volume or decrease containers to be filled.</Alert>}

            {this.state.busyUpsertProductQuantities && <Alert severity='info'>Busy updating container allocations...</Alert>}

            {this.state.upsertProductQuantitiesResponse?.isSuccessful === true && <Alert
                severity='success'>{this.state.upsertProductQuantitiesResponse?.message}</Alert>}

            {this.state.upsertProductQuantitiesResponse?.isSuccessful === false && <Alert
                severity='error'>{this.state.upsertProductQuantitiesResponse?.exceptionMessage}</Alert>}

            <Paper elevation={0} sx={{ p: 1, m: 1 }}>
                {!this.state.quantityRows && !this.state.newBatch.formulaId && <Typography sx={{ opacity: 0.25 }}>Select formula to continue.</Typography>}
                {!this.state.quantityRows && this.state.newBatch.formulaId && <LinearProgress />}

                {this.state.quantityRows && <DataGrid<QuantityRows>
                    id="data_grid_fill_instructions"
                    title="FILL INSTRUCTIONS / PRODUCT QUANTITY"
                    rows={this.state.quantityRows}
                    allowEdit
                    columns={[
                        {
                            name: 'containerId',
                            title: 'CONTAINER',
                            renderCell: (props) => {
                                if (!props.value) return props.value;
                                // get this container data
                                let a = this.state.containersR?.data?.filter(c => c.id === props.row.containerId);
                                if (!a || a.length < 1) return 'error'
                                let container = a[0];

                                return `${container.name} (${container.skuNumber})`
                            }
                        },
                        {
                            name: 'skuNumber',
                            title: 'SKU',
                        },
                        {
                            title: 'UNIT VOLUME',
                            renderCell: (props) => {
                                // get this container data
                                // let a = this.state.containersR?.data?.filter(c => c.id === props.row.containerId);
                                // if (!a || a.length < 1) return 'error'

                                if (!props.row.containerId) return 'error';
                                let container = this.getContainer(props.row.containerId)
                                return container.capacityVolume;
                            }
                        },
                        {
                            name: 'quantity',
                            title: 'NO. OF UNITS',
                            type: 'number',
                            editable: true,
                            minimum: 0
                        },
                        {
                            name: 'quantity',
                            title: 'CONSUMED',
                            renderCell: (props) => {
                                if (!props.row.containerId) return 'error';
                                let container = this.getContainer(props.row.containerId);
                                let consumed = container.capacityVolume * (props.row.quantity || 0);
                                return consumed
                            }
                        }
                    ]}
                    onEdit={async (props) => {
                        if (!props || !props.rows) return;

                        let totalConsumed = 0;
                        // @ts-ignore
                        props.rows = props.rows.map(r => {
                            if (!r.quantity) return r;
                            if (!r.containerId) return r;

                            let container = this.getContainer(r.containerId);
                            let consumed = container.capacityVolume * (props.row.quantity || 0);

                            totalConsumed += consumed;
                            return r;
                        })

                        let newBatch = this.state.newBatch;
                        newBatch.totalConsumed = totalConsumed;
                        newBatch.volumeNotAllocated = newBatch.batchVolume - totalConsumed;

                        this.setState({ newBatch, totalConsumed, quantityRows: props.rows as QuantityRows[] });
                    }}


                />}
            </Paper>

            <Paper elevation={0} sx={{ p: 1, pt: 0, m: 1 }}>
                <TextArea
                    editMode={true}
                    title="Special Instructions"
                    value={this.state.newBatch.fillInstructs || ""}
                    onChange={(val) => {
                        let newBatch = this.state.newBatch;
                        newBatch.fillInstructs = val;
                        this.setState({ newBatch })
                    }}
                />
            </Paper>

            <Paper elevation={0} sx={{ p: 1, pt: 0, m: 1 }}>
                <TextArea
                    editMode={true}
                    title="Comments"
                    value={this.state.newBatch.comments || ""}
                    onChange={(val) => {
                        let newBatch = this.state.newBatch;
                        newBatch.comments = val;
                        this.setState({ newBatch })
                    }}
                />
            </Paper>
        </Box>
    }

    addNewBatch = async () => {
        this.setState({ busyAddingBatch: true })
        console.log('trying to add the new batch.')

        const newBatchAddedResponse = await boumaticApi.api.Batches.UpsertBatch(this.state.newBatch as Batch);
        this.setState({ newBatchAddedResponse, busyAddingBatch: false });

        if (!this.state.quantityRows) throw Error('missing state.quantityRows');

        let productQuantities: ProductQuantity[] = this.state.quantityRows.map(r => {
            return {
                id: '',
                quantity: r.quantity,
                batchId: newBatchAddedResponse.data?.id,
                productId: r.productId
            };
        })

        this.setState({ busyUpsertProductQuantities: true }); // shows progress indicator
        const upsertProductQuantitiesResponse = await boumaticApi.api.ProductQuantity.UpsertProductQuantities(productQuantities);
        console.log({ upsertProductQuantitiesResponse });
        this.setState({
            busyUpsertProductQuantities: false,
            upsertProductQuantitiesResponse
        });

        if (upsertProductQuantitiesResponse.isSuccessful) {
            this.props.onClose(newBatchAddedResponse.data);
        }


        // if (result.isSuccessful && result.data?.id) {
        //     this.props.onClose(result.data); // success
        // } else {
        //     // TODO
        //     // display error
        // }
    }

    render() {

        let ready = (this.state.mixingTanksR?.data !== undefined)

        return <Modal
            open={true}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Paper
                id="add_new_batch_modal"
                sx={{ ml: 2, p: 1, pb: 0.25, pt: 0, margin: '50px auto', width: 1000 }} elevation={10}>
                <Paper sx={{ mb: 1 }} elevation={1}>
                    <AppBar position="static" color="primary" enableColorOnDark>
                        <Toolbar variant="dense" color={"primary"}>
                            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                                Add New Batch to Tank {this.props.tank.tankNo} - {this.getTankCompatibilityGroup()} - max volume: {this.props.tank.capacityVolume}
                            </Typography>

                            <Box sx={{ flex: 1 }} />

                            <Button color="inherit" variant="text" startIcon={<CloseTwoTone />} onClick={this.handleClose}>
                                Cancel
                            </Button>

                            {ready && <Button
                                id="button_add_new_batch_ok"
                                color="secondary"
                                variant="contained"
                                sx={{ ml: 2 }}
                                startIcon={<CheckTwoTone />}
                                onClick={async () => {
                                    await this.addNewBatch();
                                }} autoFocus>
                                OK
                            </Button>}

                        </Toolbar>
                    </AppBar>


                    {!ready ? <LinearProgress /> : this.renderModalForm()}



                </Paper>
            </Paper>
        </Modal>
    }
}
