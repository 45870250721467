import { Box, Paper } from "@mui/material";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import { MixingTankList } from '../chemist';

export function BatchViewRoute() {
  let params = useParams<"batchId">();
  let navigate = useNavigate();

  return (<Paper elevation={0} sx={{
    display: 'flex',
    flexDirection: 'row',
    p: 2,
    m: 0,
  }}>
    <Box style={{ flex: 2 }}>
      <MixingTankList batchId={params.batchId} 
      onNewBatch={(batch) => {
        // navigate to new batch when created
        navigate(`/batchprocess/${batch.id}/${batch.mixingTankId}`)
      }}/>
    </Box>

    {(params.batchId !== undefined) && <Box style={{ flex: 4 }}>
      {/* <FormulaEdit formulaId={params.id} /> */}
      <Outlet />
    </Box>}
  </Paper>
  );
}
