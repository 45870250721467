import React from "react";
import Box from "@mui/system/Box";
import Paper from "@mui/material/Paper";
import { SxProps, Theme } from "@mui/system";
import {Snackbar} from "@mui/material";
import Alert from "@mui/material/Alert";

import { boumaticApi } from "../../App";

import {
    Await,
    DataGrid} from 'components';

import { FormulaStep } from "../../api/interfaces";


interface IFormulaStepsList {
    formulaId: string
    sx?: SxProps<Theme>
    editMode?: boolean
}

interface IProductsState {
    formulaStepsResponse?: Await<ReturnType<typeof boumaticApi.api.FormulaSteps.GetFormulaStepByFormulaId>>
    chemicalsResponse?: Await<ReturnType<typeof boumaticApi.api.Chemicals.GetChemicals>>
}

export class FormulaStepsList extends React.Component<IFormulaStepsList> {
    state: IProductsState = {}
    lastId?:string;
    dataGrid?: DataGrid<FormulaStep>;

    componentDidMount = async () => {
        this.getData();
    }

    componentDidUpdate = async () => {
        if (this.props.formulaId !== this.lastId) { this.getData(); }
    }

    showFormulaStepsErrorAlert = () => {
        let hasIncorrectPercentage = false;
        /*this.dataGrid?.state.rows?.forEach((i) => {
            //console.log(i);
            sumPercentage += i.percentage;
        })*/
       let percentage = this.dataGrid?.state.rows?.reduce((n, {percentage}) => parseFloat("" + n) + parseFloat(""+ percentage), 0)
        console.log(percentage);

        if (percentage !== 1) {
            //this.setState({hasIncorrectPercentage: true})
            hasIncorrectPercentage = true;
        }
        return <>
            <Snackbar
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={hasIncorrectPercentage && this.props.editMode}
                onClose={() => this.setState({ success: false})}
            >
                <Alert severity="error">{`This formula's total Chemical Percentage is ${percentage}. Total should be 1.00`}</Alert>
            </Snackbar>
        </>

    }

    getData = async () => {
        // clear before so we get progress bars..
        this.setState({ formulaStepsResponse : undefined });

        this.lastId = this.props.formulaId;
        let [chemicalsResponse, formulaStepsResponse] = await Promise.all([
            boumaticApi.api.Chemicals.GetChemicals(),
            boumaticApi.api.FormulaSteps.GetFormulaStepByFormulaId({ formulaId: this.props.formulaId })
        ]);

        this.setState({ chemicalsResponse, formulaStepsResponse });
    }

    render() {
        return <Box id="formula_steps_list" key={this.props.formulaId} sx={this.props.sx}>
            {this.showFormulaStepsErrorAlert()}

            <Paper elevation={1} sx={{ p: 0 }}>
                <DataGrid<FormulaStep>
                    ref={(el) => { if (el) this.dataGrid = el; }}
                    title="Recipe Ingredients, Add-Order and Agitation"
                    key={this.props.formulaId}
                    addNewButtonText="New Step"
                    allowAddRow={this.props.editMode}
                    allowDelete
                    // allowEdit
                    editMode={this.props.editMode}
                    allowSearch={false}
                    sortBy="stepNumber"
                    sortDirection="ASC"
                    columns={[{
                        name: "stepNumber",
                        title: "STEP",
                        width: 50
                    },
                    {
                        name: "chemicalId",
                        title: "CHEMICAL",
                        type: "enum",
                        values: this.state.chemicalsResponse?.data?.sort((a,b)=>{ return (a.name > b.name) ? 1 : -1}).map(c => { return { id: c.id, value: c.name } }),
                        // renderCell: (props) => <GetChemical id={props.row.chemicalId} />,
                        align: "left"
                    },
                    {
                        name: "percentage",
                        title: "PCT",
                        width: 50,
                    },
                    {
                        name: "agitatorFreq",
                        title: "AGITATE FREQ",
                        width: 50,
                    },
                    {
                        name: "agitatorRunTime",
                        title: "AGITATE TIME",
                        width: 50,
                    }
                    ]}
                    rows={this.state.formulaStepsResponse?.data}
                    onNewRow={async (props) => {
                        
                        // calculate next step number
                        let newStepNumber = 1; // default
                        props.rows?.forEach(r => {
                            if (r.stepNumber >= newStepNumber) {
                                newStepNumber = r.stepNumber + 1;
                            }
                        });

                        let newStep: Partial<FormulaStep> = {
                            stepNumber: newStepNumber,
                            active: true,
                            agitatorFreq: 0,
                            agitatorRunTime: 0,
                            percentage: 0,
                            chemicalId: "",
                            formulaId: this.props.formulaId
                        }

                        return newStep as FormulaStep
                    }}
                    onChange={async (props) => {
                        if (props.row.id === undefined) {props.row.id = "";}
                        // @ts-ignore
                        props.row.percentage = parseFloat(props.row.percentage as string);
                        const result = await boumaticApi.api.FormulaSteps.UpsertFormulaStep(props.row);
                        this.getData();
                        return result;
                    }}
                    onDelete={async (props) => {
                        if (!props.row.id) return;
                        return await boumaticApi.api.FormulaSteps.DeleteFormulaStep({id: props.row.id})
                    }}
                    height={300}
                />

            </Paper>
        </Box>
    }
}