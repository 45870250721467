import Typography from "@mui/material/Typography";
import { IMenuItemLink } from "./interfaces";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

import Tooltip from "@mui/material/Tooltip";
import { Link, useLocation } from "react-router-dom";


export const MenuTitle = (props: { title: string }) => (
  <Typography
    variant="overline"
    display="block"
    sx={{ pt: 2, width: 75, m: 0, opacity: 0.5, textAlign: "center" }}
  >
    {props.title}
  </Typography>
);

export const MenuLink = (props: IMenuItemLink) => {
  const loc = useLocation();

  // `${loc.pathname} ${props.to}` || 

  let active = (loc.pathname === props.to);
  
  try {
    if (props.to && (props.exactPath === false)) {
      active = (loc.pathname.split("/")[1] === props.to.split("/")[1]);
    }    
  } catch (_e) {}
  
  if (props.href) {
    return (
      <ListItem id={`navigation_menu_entry_${props.title?.split(' ').join('_').toLowerCase()}`} className="navigation_menu_entry" key={props.title} button component="a" href={props.href}>
        <Tooltip
          disableHoverListener={props.disableHoverListener}
          title={props.title || ""}
          placement="right"
        >
          <ListItemIcon sx={{ pl: 1 }}>{props.icon}</ListItemIcon>
        </Tooltip>
        <ListItemText primary={props.title} />
      </ListItem>
    );
  }

  return (
    <ListItem
      id={`navigation_menu_entry_${props.title?.split(' ').join('_').toLowerCase()}`}
      className="navigation_menu_entry"
      // @ts-ignore   
      button
      // @ts-ignore 
      component={Link}
      color="red"
      to={props.to}
      // disabled={active}
      disablePadding>
      <ListItemButton selected={active} >
        <Tooltip
          disableHoverListener={props.disableHoverListener}
          title={props.title || "" }
          placement="right"
        >
          <ListItemIcon sx={{ pl: 1 }} >{props.icon}</ListItemIcon>
        </Tooltip>
        <ListItemText primary={props.title}/>
      </ListItemButton>
    </ListItem>)
}