import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Outlet } from "react-router-dom"
import React from "react";

export const Placeholder = (props: { title: string, children?: any }) => {
    return <Paper sx={{ display: "flex", flexGrow: 1, flexDirection: "row", p: 2 }}>
        <Paper style={{ flex: 1 }} elevation={3}>
            <AppBar position="static" color="primary" enableColorOnDark>
                <Toolbar variant="dense" color={"primary"}>
                    <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                        {props.title}
                    </Typography>
                </Toolbar>
            </AppBar>

            <Outlet />
        </Paper>
    </Paper>
}