import { Alert, LinearProgress, Typography } from "@mui/material";
import { Text, View } from "@react-pdf/renderer";
import React, { Suspense } from "react";
import { CertificateOfAnalysis, TestDescription } from "../../api";
import { boumaticApi, boumaticTheme } from "../../App";
import { Report, DataGridPDF, Await } from 'components';

import Moment from 'moment';


export interface ICoAReportProps {
    batchId: string;
}

export interface ICoAReportPDFState {
    coaResponseByBatchNo?: Await<ReturnType<typeof boumaticApi.api.CertificateOfAnalysis.GetCertificateOfAnalysisByBatchNo>>
    coaResponseById?: Await<ReturnType<typeof boumaticApi.api.CertificateOfAnalysis.GetCertificateOfAnalysis>>
}

export class CoAReportPDF extends React.Component<ICoAReportProps, ICoAReportPDFState> {
    state: ICoAReportPDFState = {}

    componentDidMount = async () => {

        let coaResponseByBatchNo = await boumaticApi.api.CertificateOfAnalysis.GetCertificateOfAnalysisByBatchNo({ lotNumber: this.props.batchId })

        this.setState({ coaResponseByBatchNo });

        if (this.state.coaResponseByBatchNo?.data) {
            let data = this.state.coaResponseByBatchNo?.data as unknown as CertificateOfAnalysis[];
            console.log(data);
            for (let i of data) {
                if (i.id) {
                    let [coaResponseById] = await Promise.all([boumaticApi.api.CertificateOfAnalysis.GetCertificateOfAnalysis({ id: i.id })])
                    this.setState({ coaResponseById });
                }
            }
        }
    }

    render() {

        if (!this.state.coaResponseByBatchNo) { return <LinearProgress /> }

        if (!this.state.coaResponseByBatchNo.data) {
            return <Alert severity="error">{this.state.coaResponseByBatchNo.exceptionMessage}</Alert>
        }

        if (this.state.coaResponseByBatchNo.isSuccessful && JSON.stringify(this.state.coaResponseByBatchNo.data) === '[]') {
            return <pre>
                {'boumaticApi.api.CertificateOfAnalysis.GetCertificateOfAnalysisByBatchNo({lotNumber: this.props.batchId })\n\n'}
                {JSON.stringify(this.state.coaResponseByBatchNo)}
            </pre>
        }

        if (!this.state.coaResponseById) { return <LinearProgress /> }

        if (!this.state.coaResponseById.data) {
            return <Alert severity="error">{this.state.coaResponseById.exceptionMessage}</Alert>
        }



        return <Suspense fallback={<Typography style={{ textDecoration: 'none' }}>loading pdf..</Typography>}>
            <Report
                logoUrl="/logo.png"
                title={`CERTIFICATE OF ANALYSIS`} // : ${this.props.formulaId}`}
                filename={`coareport.pdf`}
                theme={boumaticTheme}

            // content={[{
            //     title: "Formula"
            // }]}
            >

                <View style={{ paddingTop: 10 }}>
                    <Text style={{ fontFamily: 'RobotoBold', fontSize: 25 }}>
                        {this.state.coaResponseById?.data?.formulaName}</Text>
                </View>
                <div style={{ paddingTop: 20 }}>

                    <DataGridPDF<CertificateOfAnalysis>
                        rows={!!this.state.coaResponseById?.data ? [this.state.coaResponseById?.data] : undefined}
                        columns={[
                            {
                                name: "formulaName",
                                title: "FORMULA",
                                align: 'left',
                                width: '25%'
                            },
                            { name: "lotNumber", title: "LOT NUMBER", width: '25%' },
                            { name: "testingDate", title: "TESTING DATE", width: '25%' },
                            { name: "manufactureDate", title: "MANUFACTURE DATE", width: '25%' }
                        ]}
                    />
                </div>

                <hr />

                <div style={{ marginTop: 30 }}>

                    <DataGridPDF<TestDescription>
                        rows={this.state.coaResponseById?.data.testDescriptions}
                        columns={[{
                            name: "method",
                            title: "TEST DESCRIPTION",
                            type: "enum",
                            width: '25%',

                        }, {
                            name: "minimum",
                            title: "MINIMUM",
                            width: '20%',
                            type: "enum",
                        },
                        {
                            name: "maximum",
                            title: "MAXIMUM",
                            width: '20%'
                        },
                        {
                            name: "measuredValue",
                            title: "Analysis",
                            width: '20%'
                        },
                        {
                            name: "units",
                            title: "UNITS",
                            width: '15%'
                        }
                        ]}
                    />

                </div>
                <div style={{ position: "fixed", margin: "auto", marginBottom: 20 }}>

                    <View>
                        <Text style={{ fontFamily: 'Roboto', fontSize: 12 }}>
                            This Certificate of Analysis has been produced electronically and is valid without a signature.</Text>
                    </View>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10 }}>
                        <View>
                            <Text style={{ fontFamily: 'Roboto', fontSize: 12 }}>
                                Approved by: {this.state.coaResponseById?.data.firstName} {this.state.coaResponseById?.data.lastSurname}</Text>
                        </View>
                        <View >
                            <Text style={{ fontFamily: 'Roboto', fontSize: 12 }}>
                                Date: {Moment(this.state.coaResponseById?.data.manufactureDate).format('MM-DD-YYYY HH:mm:ss')}</Text>
                        </View>
                    </div>
                </div>



            </Report>
        </Suspense>
    }
}